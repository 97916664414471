<template>
  <v-autocomplete
    :value="value"
    :error-messages="valueErrors"
    :filter="filterObject"
    :items="question.answers"
    :label="question.label"
    :multiple="question.multiple"
    color="input-color"
    background-color="input-background"
    item-color="input-color"
    filled
    flat
    item-text="label"
    item-value="value"
    @change="onChange"
  ></v-autocomplete>
</template>

<script>
import { removeAccent } from '@/utils/object.util'
import { validationMixin } from 'vuelidate'
import { requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'InputDropdown',
  props: {
    question: {
      type: Object,
      required: true,
    },
    value: {
      type: [Array, String],
      required: false,
      default: null,
    },
    validating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mixins: [validationMixin],
  computed: {
    valueErrors() {
      const errors = []
      if (!this.validating) return errors
      !this.$v.value.required && errors.push(this.$t('error.required'))
      return errors
    },
  },
  methods: {
    onChange(e) {
      this.$emit('change', { value: e, id: this.question.id })
    },
    filterObject(item, queryText, itemText) {
      return removeAccent(itemText).toLocaleLowerCase().indexOf(removeAccent(queryText).toLocaleLowerCase()) > -1
    },
  },
  validations() {
    return {
      value: {
        required: requiredIf(() => {
          return this.question.required
        }),
      },
    }
  },
}
</script>
