<template>
  <div class="input-review">
    <template v-for="(field, idx) in question.fields">
      <template v-if="field.type === 'rating'">
        <v-rating
          v-if="field.type === 'rating'"
          class="tw--mx-2"
          :background-color="field.settings.bg"
          :color="field.settings.color"
          :empty-icon="field.settings.emptyIcon"
          :full-icon="field.settings.fullIcon"
          hover
          length="5"
          size="48"
          :value="review.rating"
          :key="`review-${idx}`"
          @input="onInput($event, 'rating')"
        ></v-rating>
        <div
          class="v-messages theme--light error--text"
          role="alert"
          :key="`review-error${idx}`"
          v-if="ratingErrors.length"
        >
          <div class="v-messages__wrapper">
            <div class="v-messages__message">{{ ratingErrors[0] }}</div>
          </div>
        </div>
      </template>
      <v-text-field
        v-if="field.type === 'title'"
        :class="[idx === 1 ? 'tw-mt-4' : 'tw-mt-6']"
        :value="review.title"
        color="input-color"
        background-color="input-background"
        filled
        flat
        :error-messages="titleErrors"
        :label="field.label"
        :hint="field.min ? $t('label.min', { count: field.min }) : null"
        :persistent-hint="field.min ? true : false"
        :counter="field.limitation || field.min ? true : false"
        :minlength="field.min ? field.min : null"
        :maxlength="field.limitation ? field.limitation : null"
        :key="`review-${idx}`"
        @input="onInput($event, 'title')"
      ></v-text-field>
      <v-textarea
        v-if="field.type === 'comment'"
        :class="[idx === 1 ? 'tw-mt-4' : 'tw-mt-2']"
        :value="review.comment"
        color="input-color"
        background-color="input-background"
        filled
        flat
        :error-messages="commentErrors"
        auto-grow
        no-resize
        :label="field.label"
        :hint="field.min ? $t('label.min', { count: field.min }) : null"
        :persistent-hint="field.min ? true : false"
        :counter="field.limitation || field.min ? true : false"
        :minlength="field.min ? field.min : null"
        :maxlength="field.limitation ? field.limitation : null"
        :key="`review-${idx}`"
        @input="onInput($event, 'comment')"
      ></v-textarea>
    </template>
  </div>
</template>

<script>
import { empty } from '@/utils/object.util'
import { validationMixin } from 'vuelidate'
import { requiredIf, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'InputReview',
  props: {
    question: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: false,
      default: null,
    },
    validating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mixins: [validationMixin],
  created() {
    this.review = Object.assign({}, this.review, this.value)
  },
  data: () => ({
    review: null,
  }),
  computed: {
    titleErrors() {
      const errors = []
      if (!this.validating) return errors
      !this.$v.review.title.minLength &&
        errors.push(
          this.$t('error.minLength', {
            count: this.$v.review.title.$params.minLength.min,
          })
        )
      !this.$v.review.title.required && errors.push(this.$t('error.required'))
      return errors
    },
    commentErrors() {
      const errors = []
      if (!this.validating) return errors
      !this.$v.review.comment.minLength &&
        errors.push(
          this.$t('error.minLength', {
            count: this.$v.review.comment.$params.minLength.min,
          })
        )
      !this.$v.review.comment.required && errors.push(this.$t('error.required'))
      return errors
    },
    ratingErrors() {
      const errors = []
      if (!this.validating) return errors
      !this.$v.review.rating.required && errors.push(this.$t('error.rating'))
      return errors
    },
  },
  methods: {
    onInput(e, key) {
      this.review = {
        ...this.review,
        [key]: e,
      }

      const requiredFields = this.question.fields.filter(field => field.required)
      const minLengthFields = this.question.fields.filter(field => field.min)
      if (
        !requiredFields.some(field => empty(this.review[field.type])) &&
        !minLengthFields.some(field => !empty(this.review[field.type]) && this.review[field.type].length < field.min)
      ) {
        this.$emit('change', { value: this.review, id: this.question.id })
      } else {
        this.$emit('change', { value: null, id: this.question.id })
      }
    },
  },
  validations() {
    const fieldTitle = this.question.fields.find(field => field.type === 'title')
    const fieldComment = this.question.fields.find(field => field.type === 'comment')
    const fieldRating = this.question.fields.find(field => field.type === 'rating')
    return {
      review: {
        title: {
          required: requiredIf(() => {
            return fieldTitle.required
          }),
          minLength: minLength(fieldTitle.min ? fieldTitle.min : 0),
        },
        comment: {
          required: requiredIf(() => {
            return fieldComment.required
          }),
          minLength: minLength(fieldComment.min ? fieldComment.min : 0),
        },
        rating: {
          required: requiredIf(() => {
            return fieldRating.required
          }),
        },
      },
    }
  },
}
</script>
