<template>
  <v-autocomplete
    v-if="field.key === 'country'"
    :value="value || userCountry"
    :filter="filterObject"
    :items="countries"
    :label="field.label"
    color="input-color"
    background-color="input-background"
    item-color="input-color"
    filled
    flat
    item-text="name"
    item-value="code"
    auto-select-first
    :error-messages="valueErrors"
    @change="onChange"
  ></v-autocomplete>
</template>

<script>
import { removeAccent } from '@/utils/object.util'
import { validationMixin } from 'vuelidate'
import { requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'IdentityCountry',
  props: {
    field: {
      type: Object,
      required: true,
    },
    countries: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
    userCountry: {
      type: String,
      required: false,
      default: '',
    },
    validating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mixins: [validationMixin],
  mounted() {
    if (this.userCountry) {
      this.$emit('change', this.userCountry)
    }
  },
  computed: {
    valueErrors() {
      const errors = []
      if (!this.validating) return errors
      !this.$v.value.required && errors.push(this.$t('error.required'))
      return errors
    },
  },
  methods: {
    onChange(e) {
      this.$emit('change', e)
    },
    filterObject(item, queryText, itemText) {
      return removeAccent(itemText).toLocaleLowerCase().indexOf(removeAccent(queryText).toLocaleLowerCase()) > -1
    },
  },
  validations() {
    return {
      value: {
        required: requiredIf(() => {
          return this.field.required
        }),
      },
    }
  },
}
</script>
