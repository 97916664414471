<template>
  <v-radio-group :value="value" :error-messages="valueErrors" @change="onChange" class="tw-pt-0 tw-mt-2">
    <v-radio
      color="input-color"
      :label="answer.label"
      :value="answer.value"
      :dark="isDark"
      :class="[k === question.answers.length - 1 ? 'tw-mb-0' : 'tw-mb-2']"
      v-for="(answer, k) in question.answers"
      :key="`answer-${k}`"
    ></v-radio>
  </v-radio-group>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'InputRadio',
  props: {
    question: {
      type: Object,
      required: true,
    },
    isDark: {
      type: Boolean,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
    validating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mixins: [validationMixin],
  mounted() {
    this.radios
  },
  computed: {
    valueErrors() {
      const errors = []
      if (!this.validating) return errors
      !this.$v.value.required && errors.push(this.$t('error.required'))
      return errors
    },
  },
  methods: {
    onChange(e) {
      this.$emit('change', { value: e, id: this.question.id })
    },
  },
  validations() {
    return {
      value: {
        required: requiredIf(() => {
          return this.question.required
        }),
      },
    }
  },
}
</script>
