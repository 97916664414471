export const countries = Object.freeze({
  'ar-sa': [
    {
      name: 'آروبا',
      code: 'AW',
    },
    {
      name: 'أذربيجان',
      code: 'AZ',
    },
    {
      name: 'أسبانيا',
      code: 'ES',
    },
    {
      name: 'أستونيا',
      code: 'EE',
    },
    {
      name: 'ألبانيا',
      code: 'AL',
    },
    {
      name: 'أنتيجوا وبربودا',
      code: 'AG',
    },
    {
      name: 'أنجولا',
      code: 'AO',
    },
    {
      name: 'أنغويلا',
      code: 'AI',
    },
    {
      name: 'أورجواي',
      code: 'UY',
    },
    {
      name: 'أوزبكستان',
      code: 'UZ',
    },
    {
      name: 'أولان',
      code: 'AX',
    },
    {
      name: 'أيرلاندا',
      code: 'IE',
    },
    {
      name: 'أيسلندا',
      code: 'IS',
    },
    {
      name: 'إرتريا',
      code: 'ER',
    },
    {
      name: 'اثيوبيا',
      code: 'ET',
    },
    {
      name: 'ارمينيا',
      code: 'AM',
    },
    {
      name: 'استراليا',
      code: 'AU',
    },
    {
      name: 'افغانستان',
      code: 'AF',
    },
    {
      name: 'الأردن',
      code: 'JO',
    },
    {
      name: 'الإقليم البريطاني في المحيط الهندي',
      code: 'IO',
    },
    {
      name: 'الارجنتين',
      code: 'AR',
    },
    {
      name: 'الاكوادور',
      code: 'EC',
    },
    {
      name: 'الامارات العربية المتحدة',
      code: 'AE',
    },
    {
      name: 'الباهاما',
      code: 'BS',
    },
    {
      name: 'البرازيل',
      code: 'BR',
    },
    {
      name: 'البوسنة و الهرسك',
      code: 'BA',
    },
    {
      name: 'الجابون',
      code: 'GA',
    },
    {
      name: 'الجبل الأسود',
      code: 'ME',
    },
    {
      name: 'الجزائر',
      code: 'DZ',
    },
    {
      name: 'الجمهورية اليمنية',
      code: 'YE',
    },
    {
      name: 'الدنمارك',
      code: 'DK',
    },
    {
      name: 'الرأس الأخضر',
      code: 'CV',
    },
    {
      name: 'السلفادور',
      code: 'SV',
    },
    {
      name: 'الصحراء الغربية',
      code: 'EH',
    },
    {
      name: 'العراق',
      code: 'IQ',
    },
    {
      name: 'الفاتيكان',
      code: 'VA',
    },
    {
      name: 'الفيلبين',
      code: 'PH',
    },
    {
      name: 'القطب الجنوبي',
      code: 'AQ',
    },
    {
      name: 'الكونغو - برازافيل',
      code: 'CG',
    },
    {
      name: 'الكونغو - كينشاسا',
      code: 'CD',
    },
    {
      name: 'المانيا',
      code: 'DE',
    },
    {
      name: 'المجر',
      code: 'HU',
    },
    {
      name: 'المقاطعات الجنوبية الفرنسية',
      code: 'TF',
    },
    {
      name: 'المكسيك',
      code: 'MX',
    },
    {
      name: 'المملكة العربية السعودية',
      code: 'SA',
    },
    {
      name: 'المملكة المتحدة',
      code: 'GB',
    },
    {
      name: 'المملكة المغربية',
      code: 'MA',
    },
    {
      name: 'النمسا',
      code: 'AT',
    },
    {
      name: 'الهند',
      code: 'IN',
    },
    {
      name: 'الولايات المتحدة الأمريكية',
      code: 'US',
    },
    {
      name: 'اليابان',
      code: 'JP',
    },
    {
      name: 'اندورا',
      code: 'AD',
    },
    {
      name: 'اندونيسيا',
      code: 'ID',
    },
    {
      name: 'اوغندا',
      code: 'UG',
    },
    {
      name: 'اوكرانيا',
      code: 'UA',
    },
    {
      name: 'ايران',
      code: 'IR',
    },
    {
      name: 'ايطاليا',
      code: 'IT',
    },
    {
      name: 'بابوا غينيا الجديدة',
      code: 'PG',
    },
    {
      name: 'باراجواي',
      code: 'PY',
    },
    {
      name: 'باكستان',
      code: 'PK',
    },
    {
      name: 'بالاو',
      code: 'PW',
    },
    {
      name: 'بربادوس',
      code: 'BB',
    },
    {
      name: 'برتغال',
      code: 'PT',
    },
    {
      name: 'برمودا',
      code: 'BM',
    },
    {
      name: 'بروني',
      code: 'BN',
    },
    {
      name: 'بلجيكا',
      code: 'BE',
    },
    {
      name: 'بلغاريا',
      code: 'BG',
    },
    {
      name: 'بليز',
      code: 'BZ',
    },
    {
      name: 'بنغلاديش',
      code: 'BD',
    },
    {
      name: 'بنما',
      code: 'PA',
    },
    {
      name: 'بنين',
      code: 'BJ',
    },
    {
      name: 'بوتان',
      code: 'BT',
    },
    {
      name: 'بوتسوانا',
      code: 'BW',
    },
    {
      name: 'بورتوريكو',
      code: 'PR',
    },
    {
      name: 'بوركينا فاسو',
      code: 'BF',
    },
    {
      name: 'بوروندي',
      code: 'BI',
    },
    {
      name: 'بولندا',
      code: 'PL',
    },
    {
      name: 'بوليفيا',
      code: 'BO',
    },
    {
      name: 'بولينيزيا الفرنسية',
      code: 'PF',
    },
    {
      name: 'بونير وسينت أوستاتيوس وسابا',
      code: 'BQ',
    },
    {
      name: 'بيرو',
      code: 'PE',
    },
    {
      name: 'تايلند',
      code: 'TH',
    },
    {
      name: 'تايوان',
      code: 'TW',
    },
    {
      name: 'تركمانستان',
      code: 'TM',
    },
    {
      name: 'تركيا',
      code: 'TR',
    },
    {
      name: 'ترينيداد وتوباغو',
      code: 'TT',
    },
    {
      name: 'تشاد',
      code: 'TD',
    },
    {
      name: 'تنزانيا',
      code: 'TZ',
    },
    {
      name: 'توجو',
      code: 'TG',
    },
    {
      name: 'توفالو',
      code: 'TV',
    },
    {
      name: 'توكيلو',
      code: 'TK',
    },
    {
      name: 'تونس',
      code: 'TN',
    },
    {
      name: 'تونغا',
      code: 'TO',
    },
    {
      name: 'تيمور الشرقية',
      code: 'TL',
    },
    {
      name: 'جامايكا',
      code: 'JM',
    },
    {
      name: 'جبل طارق',
      code: 'GI',
    },
    {
      name: 'جزر الترك وجايكوس',
      code: 'TC',
    },
    {
      name: 'جزر الكايمن',
      code: 'KY',
    },
    {
      name: 'جزر المارشال',
      code: 'MH',
    },
    {
      name: 'جزر الملديف',
      code: 'MV',
    },
    {
      name: 'جزر الولايات المتحدة البعيدة الصغيرة',
      code: 'UM',
    },
    {
      name: 'جزر بيتكيرن',
      code: 'PN',
    },
    {
      name: 'جزر سليمان',
      code: 'SB',
    },
    {
      name: 'جزر فارو',
      code: 'FO',
    },
    {
      name: 'جزر فرجين الأمريكية',
      code: 'VI',
    },
    {
      name: 'جزر فرجين البريطانية',
      code: 'VG',
    },
    {
      name: 'جزر فوكلاند',
      code: 'FK',
    },
    {
      name: 'جزر كوك',
      code: 'CK',
    },
    {
      name: 'جزر كوكوس',
      code: 'CC',
    },
    {
      name: 'جزر ماريانا الشمالية',
      code: 'MP',
    },
    {
      name: 'جزر والس وفوتونا',
      code: 'WF',
    },
    {
      name: 'جزيرة الكريسماس',
      code: 'CX',
    },
    {
      name: 'جزيرة بوفيه',
      code: 'BV',
    },
    {
      name: 'جزيرة مان',
      code: 'IM',
    },
    {
      name: 'جزيرة نورفوك',
      code: 'NF',
    },
    {
      name: 'جزيرة هيرد وجزر ماكدونالد',
      code: 'HM',
    },
    {
      name: 'جمهورية افريقيا الوسطى',
      code: 'CF',
    },
    {
      name: 'جمهورية التشيك',
      code: 'CZ',
    },
    {
      name: 'جمهورية الدومينيك',
      code: 'DO',
    },
    {
      name: 'جمهورية السودان',
      code: 'SD',
    },
    {
      name: 'جمهورية الصومال الفيدرالية',
      code: 'SO',
    },
    {
      name: 'جمهورية جزرالقمر المتحدة',
      code: 'KM',
    },
    {
      name: 'جمهورية جنوب افريقيا',
      code: 'ZA',
    },
    {
      name: 'جنوب السودان',
      code: 'SS',
    },
    {
      name: 'جواتيمالا',
      code: 'GT',
    },
    {
      name: 'جوادلوب',
      code: 'GP',
    },
    {
      name: 'جورجيا',
      code: 'GE',
    },
    {
      name: 'جورجيا الجنوبية وجزر ساندويتش الجنوبية',
      code: 'GS',
    },
    {
      name: 'جيبوتي',
      code: 'DJ',
    },
    {
      name: 'جيرسي',
      code: 'JE',
    },
    {
      name: 'دولة إسرائيل',
      code: 'IL',
    },
    {
      name: 'دومينيكا',
      code: 'DM',
    },
    {
      name: 'دَوْلَة اَلْكُوَيْت',
      code: 'KW',
    },
    {
      name: 'رواندا',
      code: 'RW',
    },
    {
      name: 'روسيا',
      code: 'RU',
    },
    {
      name: 'روسيا البيضاء',
      code: 'BY',
    },
    {
      name: 'رومانيا',
      code: 'RO',
    },
    {
      name: 'روينيون',
      code: 'RE',
    },
    {
      name: 'زامبيا',
      code: 'ZM',
    },
    {
      name: 'زيمبابوي',
      code: 'ZW',
    },
    {
      name: 'ساحل العاج',
      code: 'CI',
    },
    {
      name: 'ساموا',
      code: 'WS',
    },
    {
      name: 'ساموا الأمريكية',
      code: 'AS',
    },
    {
      name: 'سان بارتليمي',
      code: 'BL',
    },
    {
      name: 'سان مارينو',
      code: 'SM',
    },
    {
      name: 'سانت بيير وميكولون',
      code: 'PM',
    },
    {
      name: 'سانت فنسنت وغرنادين',
      code: 'VC',
    },
    {
      name: 'سانت كيتس ونيفيس',
      code: 'KN',
    },
    {
      name: 'سانت لوسيا',
      code: 'LC',
    },
    {
      name: 'سانت مارتين',
      code: 'MF',
    },
    {
      name: 'سانت هيلنا',
      code: 'SH',
    },
    {
      name: 'ساو تومي وبرينسيبي',
      code: 'ST',
    },
    {
      name: 'سريلانكا',
      code: 'LK',
    },
    {
      name: 'سفالبارد وجان مايان',
      code: 'SJ',
    },
    {
      name: 'سلوفاكيا',
      code: 'SK',
    },
    {
      name: 'سلوفينيا',
      code: 'SI',
    },
    {
      name: 'سنت مارتن',
      code: 'SX',
    },
    {
      name: 'سنغافورة',
      code: 'SG',
    },
    {
      name: 'سنغال',
      code: 'SN',
    },
    {
      name: 'سوازيلند',
      code: 'SZ',
    },
    {
      name: 'سوريا',
      code: 'SY',
    },
    {
      name: 'سورينام',
      code: 'SR',
    },
    {
      name: 'سويد',
      code: 'SE',
    },
    {
      name: 'سويسرا',
      code: 'CH',
    },
    {
      name: 'سيراليون',
      code: 'SL',
    },
    {
      name: 'سيشيل',
      code: 'SC',
    },
    {
      name: 'شمال مقدونيا',
      code: 'MK',
    },
    {
      name: 'شيلي',
      code: 'CL',
    },
    {
      name: 'صربيا',
      code: 'RS',
    },
    {
      name: 'صين',
      code: 'CN',
    },
    {
      name: 'طاجكستان',
      code: 'TJ',
    },
    {
      name: 'عُمان',
      code: 'OM',
    },
    {
      name: 'غامبيا',
      code: 'GM',
    },
    {
      name: 'غانا',
      code: 'GH',
    },
    {
      name: 'غرينادا',
      code: 'GD',
    },
    {
      name: 'غرينلاند',
      code: 'GL',
    },
    {
      name: 'غوام',
      code: 'GU',
    },
    {
      name: 'غويانا الفرنسية',
      code: 'GF',
    },
    {
      name: 'غيانا',
      code: 'GY',
    },
    {
      name: 'غيرنزي',
      code: 'GG',
    },
    {
      name: 'غينيا',
      code: 'GN',
    },
    {
      name: 'غينيا الاستوائية',
      code: 'GQ',
    },
    {
      name: 'غينيا بيساو',
      code: 'GW',
    },
    {
      name: 'فانواتو',
      code: 'VU',
    },
    {
      name: 'فرنسا',
      code: 'FR',
    },
    {
      name: 'فلسطين',
      code: 'PS',
    },
    {
      name: 'فنزويلا',
      code: 'VE',
    },
    {
      name: 'فنلندا',
      code: 'FI',
    },
    {
      name: 'فيتنام',
      code: 'VN',
    },
    {
      name: 'فيجي',
      code: 'FJ',
    },
    {
      name: 'قبرص',
      code: 'CY',
    },
    {
      name: 'قطر',
      code: 'QA',
    },
    {
      name: 'قيرغيزستان',
      code: 'KG',
    },
    {
      name: 'كازاخستان',
      code: 'KZ',
    },
    {
      name: 'كاليدونيا الجديدة',
      code: 'NC',
    },
    {
      name: 'كاميرون',
      code: 'CM',
    },
    {
      name: 'كرواتيا',
      code: 'HR',
    },
    {
      name: 'كمبوديا',
      code: 'KH',
    },
    {
      name: 'كندا',
      code: 'CA',
    },
    {
      name: 'كوبا',
      code: 'CU',
    },
    {
      name: 'كوراساو',
      code: 'CW',
    },
    {
      name: 'كوريا الجنوبية',
      code: 'KR',
    },
    {
      name: 'كوريا الشمالية',
      code: 'KP',
    },
    {
      name: 'كوستاريكا',
      code: 'CR',
    },
    {
      name: 'كوسوفو',
      code: 'XK',
    },
    {
      name: 'كولومبيا',
      code: 'CO',
    },
    {
      name: 'كيريباس',
      code: 'KI',
    },
    {
      name: 'كينيا',
      code: 'KE',
    },
    {
      name: 'لاتفيا',
      code: 'LV',
    },
    {
      name: 'لاوس',
      code: 'LA',
    },
    {
      name: 'لبنان',
      code: 'LB',
    },
    {
      name: 'لوكسمبورج',
      code: 'LU',
    },
    {
      name: 'ليبيا',
      code: 'LY',
    },
    {
      name: 'ليبيريا',
      code: 'LR',
    },
    {
      name: 'ليتوانيا',
      code: 'LT',
    },
    {
      name: 'ليختنشتاين',
      code: 'LI',
    },
    {
      name: 'ليسوتو',
      code: 'LS',
    },
    {
      name: 'مارتينيك',
      code: 'MQ',
    },
    {
      name: 'ماكاو',
      code: 'MO',
    },
    {
      name: 'مالطا',
      code: 'MT',
    },
    {
      name: 'مالي',
      code: 'ML',
    },
    {
      name: 'ماليزيا',
      code: 'MY',
    },
    {
      name: 'مايوت',
      code: 'YT',
    },
    {
      name: 'مدغشقر',
      code: 'MG',
    },
    {
      name: 'مصر',
      code: 'EG',
    },
    {
      name: 'ملاوي',
      code: 'MW',
    },
    {
      name: 'مملكة البحرين',
      code: 'BH',
    },
    {
      name: 'منغوليا',
      code: 'MN',
    },
    {
      name: 'موريتانيا',
      code: 'MR',
    },
    {
      name: 'موريشيوس',
      code: 'MU',
    },
    {
      name: 'موزمبيق',
      code: 'MZ',
    },
    {
      name: 'مولدوفا',
      code: 'MD',
    },
    {
      name: 'موناكو',
      code: 'MC',
    },
    {
      name: 'مونتسرات',
      code: 'MS',
    },
    {
      name: 'ميانمار -بورما',
      code: 'MM',
    },
    {
      name: 'ميكرونيزيا',
      code: 'FM',
    },
    {
      name: 'ناميبيا',
      code: 'NA',
    },
    {
      name: 'نرويج',
      code: 'NO',
    },
    {
      name: 'نورو',
      code: 'NR',
    },
    {
      name: 'نيبال',
      code: 'NP',
    },
    {
      name: 'نيجر',
      code: 'NE',
    },
    {
      name: 'نيجيريا',
      code: 'NG',
    },
    {
      name: 'نيكاراغوا',
      code: 'NI',
    },
    {
      name: 'نيوزيلاندا',
      code: 'NZ',
    },
    {
      name: 'نيوي',
      code: 'NU',
    },
    {
      name: 'هايتى',
      code: 'HT',
    },
    {
      name: 'هندوراس',
      code: 'HN',
    },
    {
      name: 'هولندا',
      code: 'NL',
    },
    {
      name: 'هونغ كونغ',
      code: 'HK',
    },
    {
      name: 'يونان',
      code: 'GR',
    },
  ],
  'de-de': [
    {
      name: 'Afghanistan',
      code: 'AF',
    },
    {
      name: 'Alandinseln',
      code: 'AX',
    },
    {
      name: 'Albanien',
      code: 'AL',
    },
    {
      name: 'Algerien',
      code: 'DZ',
    },
    {
      name: 'Amerikanisch-Ozeanien',
      code: 'UM',
    },
    {
      name: 'Amerikanisch-Samoa',
      code: 'AS',
    },
    {
      name: 'Amerikanische Jungferninseln',
      code: 'VI',
    },
    {
      name: 'Andorra',
      code: 'AD',
    },
    {
      name: 'Angola',
      code: 'AO',
    },
    {
      name: 'Anguilla',
      code: 'AI',
    },
    {
      name: 'Antarktis',
      code: 'AQ',
    },
    {
      name: 'Antigua und Barbuda',
      code: 'AG',
    },
    {
      name: 'Argentinien',
      code: 'AR',
    },
    {
      name: 'Armenien',
      code: 'AM',
    },
    {
      name: 'Aruba',
      code: 'AW',
    },
    {
      name: 'Aserbaidschan',
      code: 'AZ',
    },
    {
      name: 'Australien',
      code: 'AU',
    },
    {
      name: 'Bahamas',
      code: 'BS',
    },
    {
      name: 'Bahrain',
      code: 'BH',
    },
    {
      name: 'Bangladesch',
      code: 'BD',
    },
    {
      name: 'Barbados',
      code: 'BB',
    },
    {
      name: 'Belgien',
      code: 'BE',
    },
    {
      name: 'Belize',
      code: 'BZ',
    },
    {
      name: 'Benin',
      code: 'BJ',
    },
    {
      name: 'Bermuda',
      code: 'BM',
    },
    {
      name: 'Bhutan',
      code: 'BT',
    },
    {
      name: 'Birma (Myanmar)',
      code: 'MM',
    },
    {
      name: 'Bolivien',
      code: 'BO',
    },
    {
      name: 'Bonaire',
      code: 'BQ',
    },
    {
      name: 'Bosnien und Herzegowina',
      code: 'BA',
    },
    {
      name: 'Botswana',
      code: 'BW',
    },
    {
      name: 'Bouvetinsel',
      code: 'BV',
    },
    {
      name: 'Brasilien',
      code: 'BR',
    },
    {
      name: 'Britische Jungferninseln',
      code: 'VG',
    },
    {
      name: 'Britisches Territorium im Indischen Ozean',
      code: 'IO',
    },
    {
      name: 'Brunei',
      code: 'BN',
    },
    {
      name: 'Bulgarien',
      code: 'BG',
    },
    {
      name: 'Bundesrepublik Deutschland',
      code: 'DE',
    },
    {
      name: 'Burkina Faso',
      code: 'BF',
    },
    {
      name: 'Burundi',
      code: 'BI',
    },
    {
      name: 'Chile',
      code: 'CL',
    },
    {
      name: 'China',
      code: 'CN',
    },
    {
      name: 'Cookinseln',
      code: 'CK',
    },
    {
      name: 'Costa Rica',
      code: 'CR',
    },
    {
      name: 'Curaçao',
      code: 'CW',
    },
    {
      name: 'Dominica',
      code: 'DM',
    },
    {
      name: 'Dominikanische Republik',
      code: 'DO',
    },
    {
      name: 'Dschibuti',
      code: 'DJ',
    },
    {
      name: 'Dänemark',
      code: 'DK',
    },
    {
      name: 'Ecuador',
      code: 'EC',
    },
    {
      name: 'El Salvador',
      code: 'SV',
    },
    {
      name: 'Elfenbeinküste',
      code: 'CI',
    },
    {
      name: 'Eritrea',
      code: 'ER',
    },
    {
      name: 'Estland',
      code: 'EE',
    },
    {
      name: 'Falklandinseln',
      code: 'FK',
    },
    {
      name: 'Fidschi',
      code: 'FJ',
    },
    {
      name: 'Finnland',
      code: 'FI',
    },
    {
      name: 'Frankreich',
      code: 'FR',
    },
    {
      name: 'Französisch-Guayana',
      code: 'GF',
    },
    {
      name: 'Französisch-Polynesien',
      code: 'PF',
    },
    {
      name: 'Französische Süd- und Antarktisgebiete',
      code: 'TF',
    },
    {
      name: 'Färöer-Inseln',
      code: 'FO',
    },
    {
      name: 'Gabun',
      code: 'GA',
    },
    {
      name: 'Gambia',
      code: 'GM',
    },
    {
      name: 'Georgien',
      code: 'GE',
    },
    {
      name: 'Ghana',
      code: 'GH',
    },
    {
      name: 'Gibraltar',
      code: 'GI',
    },
    {
      name: 'Grenada',
      code: 'GD',
    },
    {
      name: 'Griechenland',
      code: 'GR',
    },
    {
      name: 'Grönland',
      code: 'GL',
    },
    {
      name: 'Guadeloupe',
      code: 'GP',
    },
    {
      name: 'Guam',
      code: 'GU',
    },
    {
      name: 'Guatemala',
      code: 'GT',
    },
    {
      name: 'Guernsey',
      code: 'GG',
    },
    {
      name: 'Guinea',
      code: 'GN',
    },
    {
      name: 'Guinea-Bissau',
      code: 'GW',
    },
    {
      name: 'Guyana',
      code: 'GY',
    },
    {
      name: 'Haiti',
      code: 'HT',
    },
    {
      name: 'Heard- und McDonald-Inseln',
      code: 'HM',
    },
    {
      name: 'Honduras',
      code: 'HN',
    },
    {
      name: 'Hongkong',
      code: 'HK',
    },
    {
      name: 'Indien',
      code: 'IN',
    },
    {
      name: 'Indonesien',
      code: 'ID',
    },
    {
      name: 'Insel Man',
      code: 'IM',
    },
    {
      name: 'Irak',
      code: 'IQ',
    },
    {
      name: 'Iran (Islamische Republik)',
      code: 'IR',
    },
    {
      name: 'Irland',
      code: 'IE',
    },
    {
      name: 'Island',
      code: 'IS',
    },
    {
      name: 'Israel',
      code: 'IL',
    },
    {
      name: 'Italien',
      code: 'IT',
    },
    {
      name: 'Jamaika',
      code: 'JM',
    },
    {
      name: 'Japan',
      code: 'JP',
    },
    {
      name: 'Jemen',
      code: 'YE',
    },
    {
      name: 'Jersey',
      code: 'JE',
    },
    {
      name: 'Jordanien',
      code: 'JO',
    },
    {
      name: 'Kaimaninseln',
      code: 'KY',
    },
    {
      name: 'Kambodscha',
      code: 'KH',
    },
    {
      name: 'Kamerun',
      code: 'CM',
    },
    {
      name: 'Kanada',
      code: 'CA',
    },
    {
      name: 'Kapverden',
      code: 'CV',
    },
    {
      name: 'Kasachstan',
      code: 'KZ',
    },
    {
      name: 'Katar',
      code: 'QA',
    },
    {
      name: 'Kenia',
      code: 'KE',
    },
    {
      name: 'Kirgistan',
      code: 'KG',
    },
    {
      name: 'Kiribati',
      code: 'KI',
    },
    {
      name: 'Kokosinseln',
      code: 'CC',
    },
    {
      name: 'Kolumbien',
      code: 'CO',
    },
    {
      name: 'Komoren',
      code: 'KM',
    },
    {
      name: 'Kongo',
      code: 'CD',
    },
    {
      name: 'Kongo (Republik Kongo)',
      code: 'CG',
    },
    {
      name: 'Kosovo',
      code: 'XK',
    },
    {
      name: 'Kroatien',
      code: 'HR',
    },
    {
      name: 'Kuba',
      code: 'CU',
    },
    {
      name: 'Kuwait',
      code: 'KW',
    },
    {
      name: 'Laos',
      code: 'LA',
    },
    {
      name: 'Lesotho',
      code: 'LS',
    },
    {
      name: 'Lettland',
      code: 'LV',
    },
    {
      name: 'Libanon',
      code: 'LB',
    },
    {
      name: 'Liberia',
      code: 'LR',
    },
    {
      name: 'Libysch-Arabische Dschamahirija',
      code: 'LY',
    },
    {
      name: 'Liechtenstein',
      code: 'LI',
    },
    {
      name: 'Litauen',
      code: 'LT',
    },
    {
      name: 'Luxemburg',
      code: 'LU',
    },
    {
      name: 'Macau',
      code: 'MO',
    },
    {
      name: 'Madagaskar',
      code: 'MG',
    },
    {
      name: 'Malawi',
      code: 'MW',
    },
    {
      name: 'Malaysia',
      code: 'MY',
    },
    {
      name: 'Malediven',
      code: 'MV',
    },
    {
      name: 'Mali',
      code: 'ML',
    },
    {
      name: 'Malta',
      code: 'MT',
    },
    {
      name: 'Marokko',
      code: 'MA',
    },
    {
      name: 'Marshall-Inseln',
      code: 'MH',
    },
    {
      name: 'Martinique',
      code: 'MQ',
    },
    {
      name: 'Mauretanien',
      code: 'MR',
    },
    {
      name: 'Mauritius',
      code: 'MU',
    },
    {
      name: 'Mayotte',
      code: 'YT',
    },
    {
      name: 'Mexiko',
      code: 'MX',
    },
    {
      name: 'Mikronesien',
      code: 'FM',
    },
    {
      name: 'Moldau (Republik Moldau)',
      code: 'MD',
    },
    {
      name: 'Monaco',
      code: 'MC',
    },
    {
      name: 'Mongolei',
      code: 'MN',
    },
    {
      name: 'Montenegro',
      code: 'ME',
    },
    {
      name: 'Montserrat',
      code: 'MS',
    },
    {
      name: 'Mosambik',
      code: 'MZ',
    },
    {
      name: 'Namibia',
      code: 'NA',
    },
    {
      name: 'Nauru',
      code: 'NR',
    },
    {
      name: 'Nepal',
      code: 'NP',
    },
    {
      name: 'Neukaledonien',
      code: 'NC',
    },
    {
      name: 'Neuseeland',
      code: 'NZ',
    },
    {
      name: 'Niederlande',
      code: 'NL',
    },
    {
      name: 'Niger',
      code: 'NE',
    },
    {
      name: 'Nigeria',
      code: 'NG',
    },
    {
      name: 'Nikaragua',
      code: 'NI',
    },
    {
      name: 'Niue',
      code: 'NU',
    },
    {
      name: 'Nordkorea',
      code: 'KP',
    },
    {
      name: 'Nordmazedonien',
      code: 'MK',
    },
    {
      name: 'Norfolkinsel',
      code: 'NF',
    },
    {
      name: 'Norwegen',
      code: 'NO',
    },
    {
      name: 'Nördliche Marianen',
      code: 'MP',
    },
    {
      name: 'Oman',
      code: 'OM',
    },
    {
      name: 'Pakistan',
      code: 'PK',
    },
    {
      name: 'Palau',
      code: 'PW',
    },
    {
      name: 'Palästinensische Autonomiegebiete',
      code: 'PS',
    },
    {
      name: 'Panama',
      code: 'PA',
    },
    {
      name: 'Papua-Neuguinea',
      code: 'PG',
    },
    {
      name: 'Paraguay',
      code: 'PY',
    },
    {
      name: 'Peru',
      code: 'PE',
    },
    {
      name: 'Philippinen',
      code: 'PH',
    },
    {
      name: 'Pitcairn',
      code: 'PN',
    },
    {
      name: 'Polen',
      code: 'PL',
    },
    {
      name: 'Portugal',
      code: 'PT',
    },
    {
      name: 'Puerto Rico',
      code: 'PR',
    },
    {
      name: 'Republik Korea',
      code: 'KR',
    },
    {
      name: 'Republik Südafrika',
      code: 'ZA',
    },
    {
      name: 'Ruanda',
      code: 'RW',
    },
    {
      name: 'Rumänien',
      code: 'RO',
    },
    {
      name: 'Russland',
      code: 'RU',
    },
    {
      name: 'Réunion',
      code: 'RE',
    },
    {
      name: 'Saint Kitts und Nevis',
      code: 'KN',
    },
    {
      name: 'Sambia',
      code: 'ZM',
    },
    {
      name: 'Samoa',
      code: 'WS',
    },
    {
      name: 'San Marino',
      code: 'SM',
    },
    {
      name: 'Sao Tomé und Principe',
      code: 'ST',
    },
    {
      name: 'Saudi-Arabien',
      code: 'SA',
    },
    {
      name: 'Schweden',
      code: 'SE',
    },
    {
      name: 'Schweiz',
      code: 'CH',
    },
    {
      name: 'Senegal',
      code: 'SN',
    },
    {
      name: 'Serbien',
      code: 'RS',
    },
    {
      name: 'Seychellen',
      code: 'SC',
    },
    {
      name: 'Sierra Leone',
      code: 'SL',
    },
    {
      name: 'Simbabwe',
      code: 'ZW',
    },
    {
      name: 'Singapur',
      code: 'SG',
    },
    {
      name: 'Sint Maarten',
      code: 'SX',
    },
    {
      name: 'Slowakei (Slowakische Republik)',
      code: 'SK',
    },
    {
      name: 'Slowenien',
      code: 'SI',
    },
    {
      name: 'Solomon-Inseln',
      code: 'SB',
    },
    {
      name: 'Somalia',
      code: 'SO',
    },
    {
      name: 'Spanien',
      code: 'ES',
    },
    {
      name: 'Sri Lanka',
      code: 'LK',
    },
    {
      name: 'St. Barthélemy',
      code: 'BL',
    },
    {
      name: 'St. Helena',
      code: 'SH',
    },
    {
      name: 'St. Lucia',
      code: 'LC',
    },
    {
      name: 'St. Martin',
      code: 'MF',
    },
    {
      name: 'St. Pierre und Miquelon',
      code: 'PM',
    },
    {
      name: 'St. Vincent und die Grenadinen',
      code: 'VC',
    },
    {
      name: 'Staat der Vatikanstadt',
      code: 'VA',
    },
    {
      name: 'Sudan',
      code: 'SD',
    },
    {
      name: 'Suriname',
      code: 'SR',
    },
    {
      name: 'Svalbard und Jan Mayen',
      code: 'SJ',
    },
    {
      name: 'Swasiland',
      code: 'SZ',
    },
    {
      name: 'Syrien',
      code: 'SY',
    },
    {
      name: 'Südgeorgien und die Südlichen Sandwichinseln',
      code: 'GS',
    },
    {
      name: 'Südsudan',
      code: 'SS',
    },
    {
      name: 'Tadschikistan',
      code: 'TJ',
    },
    {
      name: 'Taiwan',
      code: 'TW',
    },
    {
      name: 'Tansania',
      code: 'TZ',
    },
    {
      name: 'Thailand',
      code: 'TH',
    },
    {
      name: 'Timor-Leste',
      code: 'TL',
    },
    {
      name: 'Togo',
      code: 'TG',
    },
    {
      name: 'Tokelau',
      code: 'TK',
    },
    {
      name: 'Tonga',
      code: 'TO',
    },
    {
      name: 'Trinidad und Tobago',
      code: 'TT',
    },
    {
      name: 'Tschad',
      code: 'TD',
    },
    {
      name: 'Tschechien',
      code: 'CZ',
    },
    {
      name: 'Tunesien',
      code: 'TN',
    },
    {
      name: 'Turkmenistan',
      code: 'TM',
    },
    {
      name: 'Turks- und Caicosinseln',
      code: 'TC',
    },
    {
      name: 'Tuwalu',
      code: 'TV',
    },
    {
      name: 'Türkei',
      code: 'TR',
    },
    {
      name: 'USA',
      code: 'US',
    },
    {
      name: 'Uganda',
      code: 'UG',
    },
    {
      name: 'Ukraine',
      code: 'UA',
    },
    {
      name: 'Ungarn',
      code: 'HU',
    },
    {
      name: 'Uruguay',
      code: 'UY',
    },
    {
      name: 'Usbekistan',
      code: 'UZ',
    },
    {
      name: 'Vanuatu',
      code: 'VU',
    },
    {
      name: 'Venezuela',
      code: 'VE',
    },
    {
      name: 'Vereinigte Arabische Emirate',
      code: 'AE',
    },
    {
      name: 'Vereinigtes Königreich',
      code: 'GB',
    },
    {
      name: 'Vietnam',
      code: 'VN',
    },
    {
      name: 'Wallis und Futuna',
      code: 'WF',
    },
    {
      name: 'Weihnachtsinsel',
      code: 'CX',
    },
    {
      name: 'Weißrussland',
      code: 'BY',
    },
    {
      name: 'Westsahara',
      code: 'EH',
    },
    {
      name: 'Zentralafrikanische Republik',
      code: 'CF',
    },
    {
      name: 'Zypern',
      code: 'CY',
    },
    {
      name: 'Ägypten',
      code: 'EG',
    },
    {
      name: 'Äquatorialguinea',
      code: 'GQ',
    },
    {
      name: 'Äthiopien',
      code: 'ET',
    },
    {
      name: 'Österreich',
      code: 'AT',
    },
  ],
  'en-gb': [
    {
      name: 'Afghanistan',
      code: 'AF',
    },
    {
      name: 'Albania',
      code: 'AL',
    },
    {
      name: 'Algeria',
      code: 'DZ',
    },
    {
      name: 'American Samoa',
      code: 'AS',
    },
    {
      name: 'Andorra',
      code: 'AD',
    },
    {
      name: 'Angola',
      code: 'AO',
    },
    {
      name: 'Anguilla',
      code: 'AI',
    },
    {
      name: 'Antarctica',
      code: 'AQ',
    },
    {
      name: 'Antigua and Barbuda',
      code: 'AG',
    },
    {
      name: 'Argentina',
      code: 'AR',
    },
    {
      name: 'Armenia',
      code: 'AM',
    },
    {
      name: 'Aruba',
      code: 'AW',
    },
    {
      name: 'Australia',
      code: 'AU',
    },
    {
      name: 'Austria',
      code: 'AT',
    },
    {
      name: 'Azerbaijan',
      code: 'AZ',
    },
    {
      name: 'Bahamas',
      code: 'BS',
    },
    {
      name: 'Bahrain',
      code: 'BH',
    },
    {
      name: 'Bangladesh',
      code: 'BD',
    },
    {
      name: 'Barbados',
      code: 'BB',
    },
    {
      name: 'Belarus',
      code: 'BY',
    },
    {
      name: 'Belgium',
      code: 'BE',
    },
    {
      name: 'Belize',
      code: 'BZ',
    },
    {
      name: 'Benin',
      code: 'BJ',
    },
    {
      name: 'Bermuda',
      code: 'BM',
    },
    {
      name: 'Bhutan',
      code: 'BT',
    },
    {
      name: 'Bolivia',
      code: 'BO',
    },
    {
      name: 'Bonaire, Saint Eustatius, and Saba',
      code: 'BQ',
    },
    {
      name: 'Bosnia and Herzegovina',
      code: 'BA',
    },
    {
      name: 'Botswana',
      code: 'BW',
    },
    {
      name: 'Bouvet Island',
      code: 'BV',
    },
    {
      name: 'Brazil',
      code: 'BR',
    },
    {
      name: 'British Indian Ocean Territory',
      code: 'IO',
    },
    {
      name: 'British Virgin Islands',
      code: 'VG',
    },
    {
      name: 'Brunei',
      code: 'BN',
    },
    {
      name: 'Bulgaria',
      code: 'BG',
    },
    {
      name: 'Burkina Faso',
      code: 'BF',
    },
    {
      name: 'Burundi',
      code: 'BI',
    },
    {
      name: 'Cabo Verde',
      code: 'CV',
    },
    {
      name: 'Cambodia',
      code: 'KH',
    },
    {
      name: 'Cameroon',
      code: 'CM',
    },
    {
      name: 'Canada',
      code: 'CA',
    },
    {
      name: 'Cayman Islands',
      code: 'KY',
    },
    {
      name: 'Central African Republic',
      code: 'CF',
    },
    {
      name: 'Chad',
      code: 'TD',
    },
    {
      name: 'Chile',
      code: 'CL',
    },
    {
      name: 'China',
      code: 'CN',
    },
    {
      name: 'Christmas Island',
      code: 'CX',
    },
    {
      name: 'Cocos [Keeling] Islands',
      code: 'CC',
    },
    {
      name: 'Colombia',
      code: 'CO',
    },
    {
      name: 'Comoros',
      code: 'KM',
    },
    {
      name: 'Congo',
      code: 'CD',
    },
    {
      name: 'Congo',
      code: 'CG',
    },
    {
      name: 'Cook Islands',
      code: 'CK',
    },
    {
      name: 'Costa Rica',
      code: 'CR',
    },
    {
      name: 'Croatia',
      code: 'HR',
    },
    {
      name: 'Cuba',
      code: 'CU',
    },
    {
      name: 'Curaçao',
      code: 'CW',
    },
    {
      name: 'Cyprus',
      code: 'CY',
    },
    {
      name: 'Czechia',
      code: 'CZ',
    },
    {
      name: 'Denmark',
      code: 'DK',
    },
    {
      name: 'Djibouti',
      code: 'DJ',
    },
    {
      name: 'Dominica',
      code: 'DM',
    },
    {
      name: 'Dominican Republic',
      code: 'DO',
    },
    {
      name: 'East Timor',
      code: 'TL',
    },
    {
      name: 'Ecuador',
      code: 'EC',
    },
    {
      name: 'Egypt',
      code: 'EG',
    },
    {
      name: 'El Salvador',
      code: 'SV',
    },
    {
      name: 'Equatorial Guinea',
      code: 'GQ',
    },
    {
      name: 'Eritrea',
      code: 'ER',
    },
    {
      name: 'Estonia',
      code: 'EE',
    },
    {
      name: 'Eswatini',
      code: 'SZ',
    },
    {
      name: 'Ethiopia',
      code: 'ET',
    },
    {
      name: 'Falkland Islands',
      code: 'FK',
    },
    {
      name: 'Faroe Islands',
      code: 'FO',
    },
    {
      name: 'Fiji',
      code: 'FJ',
    },
    {
      name: 'Finland',
      code: 'FI',
    },
    {
      name: 'France',
      code: 'FR',
    },
    {
      name: 'French Guiana',
      code: 'GF',
    },
    {
      name: 'French Polynesia',
      code: 'PF',
    },
    {
      name: 'French Southern Territories',
      code: 'TF',
    },
    {
      name: 'Gabon',
      code: 'GA',
    },
    {
      name: 'Gambia',
      code: 'GM',
    },
    {
      name: 'Georgia',
      code: 'GE',
    },
    {
      name: 'Germany',
      code: 'DE',
    },
    {
      name: 'Ghana',
      code: 'GH',
    },
    {
      name: 'Gibraltar',
      code: 'GI',
    },
    {
      name: 'Great Britain',
      code: 'GB',
    },
    {
      name: 'Greece',
      code: 'GR',
    },
    {
      name: 'Greenland',
      code: 'GL',
    },
    {
      name: 'Grenada',
      code: 'GD',
    },
    {
      name: 'Guadeloupe',
      code: 'GP',
    },
    {
      name: 'Guam',
      code: 'GU',
    },
    {
      name: 'Guatemala',
      code: 'GT',
    },
    {
      name: 'Guernsey',
      code: 'GG',
    },
    {
      name: 'Guinea',
      code: 'GN',
    },
    {
      name: 'Guinea-Bissau',
      code: 'GW',
    },
    {
      name: 'Guyana',
      code: 'GY',
    },
    {
      name: 'Haiti',
      code: 'HT',
    },
    {
      name: 'Hashemite Kingdom of Jordan',
      code: 'JO',
    },
    {
      name: 'Heard Island and McDonald Islands',
      code: 'HM',
    },
    {
      name: 'Honduras',
      code: 'HN',
    },
    {
      name: 'Hong Kong',
      code: 'HK',
    },
    {
      name: 'Hungary',
      code: 'HU',
    },
    {
      name: 'Iceland',
      code: 'IS',
    },
    {
      name: 'India',
      code: 'IN',
    },
    {
      name: 'Indonesia',
      code: 'ID',
    },
    {
      name: 'Iran',
      code: 'IR',
    },
    {
      name: 'Iraq',
      code: 'IQ',
    },
    {
      name: 'Ireland',
      code: 'IE',
    },
    {
      name: 'Isle of Man',
      code: 'IM',
    },
    {
      name: 'Israel',
      code: 'IL',
    },
    {
      name: 'Italy',
      code: 'IT',
    },
    {
      name: 'Ivory Coast',
      code: 'CI',
    },
    {
      name: 'Jamaica',
      code: 'JM',
    },
    {
      name: 'Japan',
      code: 'JP',
    },
    {
      name: 'Jersey',
      code: 'JE',
    },
    {
      name: 'Kazakhstan',
      code: 'KZ',
    },
    {
      name: 'Kenya',
      code: 'KE',
    },
    {
      name: 'Kiribati',
      code: 'KI',
    },
    {
      name: 'Kosovo',
      code: 'XK',
    },
    {
      name: 'Kuwait',
      code: 'KW',
    },
    {
      name: 'Kyrgyzstan',
      code: 'KG',
    },
    {
      name: 'Laos',
      code: 'LA',
    },
    {
      name: 'Latvia',
      code: 'LV',
    },
    {
      name: 'Lebanon',
      code: 'LB',
    },
    {
      name: 'Lesotho',
      code: 'LS',
    },
    {
      name: 'Liberia',
      code: 'LR',
    },
    {
      name: 'Libya',
      code: 'LY',
    },
    {
      name: 'Liechtenstein',
      code: 'LI',
    },
    {
      name: 'Lithuania',
      code: 'LT',
    },
    {
      name: 'Luxembourg',
      code: 'LU',
    },
    {
      name: 'Macao',
      code: 'MO',
    },
    {
      name: 'Madagascar',
      code: 'MG',
    },
    {
      name: 'Malawi',
      code: 'MW',
    },
    {
      name: 'Malaysia',
      code: 'MY',
    },
    {
      name: 'Maldives',
      code: 'MV',
    },
    {
      name: 'Mali',
      code: 'ML',
    },
    {
      name: 'Malta',
      code: 'MT',
    },
    {
      name: 'Marshall Islands',
      code: 'MH',
    },
    {
      name: 'Martinique',
      code: 'MQ',
    },
    {
      name: 'Mauritania',
      code: 'MR',
    },
    {
      name: 'Mauritius',
      code: 'MU',
    },
    {
      name: 'Mayotte',
      code: 'YT',
    },
    {
      name: 'Mexico',
      code: 'MX',
    },
    {
      name: 'Micronesia',
      code: 'FM',
    },
    {
      name: 'Moldova',
      code: 'MD',
    },
    {
      name: 'Monaco',
      code: 'MC',
    },
    {
      name: 'Mongolia',
      code: 'MN',
    },
    {
      name: 'Montenegro',
      code: 'ME',
    },
    {
      name: 'Montserrat',
      code: 'MS',
    },
    {
      name: 'Morocco',
      code: 'MA',
    },
    {
      name: 'Mozambique',
      code: 'MZ',
    },
    {
      name: 'Myanmar',
      code: 'MM',
    },
    {
      name: 'Namibia',
      code: 'NA',
    },
    {
      name: 'Nauru',
      code: 'NR',
    },
    {
      name: 'Nepal',
      code: 'NP',
    },
    {
      name: 'Netherlands',
      code: 'NL',
    },
    {
      name: 'New Caledonia',
      code: 'NC',
    },
    {
      name: 'New Zealand',
      code: 'NZ',
    },
    {
      name: 'Nicaragua',
      code: 'NI',
    },
    {
      name: 'Niger',
      code: 'NE',
    },
    {
      name: 'Nigeria',
      code: 'NG',
    },
    {
      name: 'Niue',
      code: 'NU',
    },
    {
      name: 'Norfolk Island',
      code: 'NF',
    },
    {
      name: 'North Korea',
      code: 'KP',
    },
    {
      name: 'North Macedonia',
      code: 'MK',
    },
    {
      name: 'Northern Mariana Islands',
      code: 'MP',
    },
    {
      name: 'Norway',
      code: 'NO',
    },
    {
      name: 'Oman',
      code: 'OM',
    },
    {
      name: 'Pakistan',
      code: 'PK',
    },
    {
      name: 'Palau',
      code: 'PW',
    },
    {
      name: 'Palestine',
      code: 'PS',
    },
    {
      name: 'Panama',
      code: 'PA',
    },
    {
      name: 'Papua New Guinea',
      code: 'PG',
    },
    {
      name: 'Paraguay',
      code: 'PY',
    },
    {
      name: 'Peru',
      code: 'PE',
    },
    {
      name: 'Philippines',
      code: 'PH',
    },
    {
      name: 'Pitcairn Islands',
      code: 'PN',
    },
    {
      name: 'Poland',
      code: 'PL',
    },
    {
      name: 'Portugal',
      code: 'PT',
    },
    {
      name: 'Puerto Rico',
      code: 'PR',
    },
    {
      name: 'Qatar',
      code: 'QA',
    },
    {
      name: 'Republic of Korea',
      code: 'KR',
    },
    {
      name: 'Republic of South Sudan',
      code: 'SS',
    },
    {
      name: 'Romania',
      code: 'RO',
    },
    {
      name: 'Russia',
      code: 'RU',
    },
    {
      name: 'Rwanda',
      code: 'RW',
    },
    {
      name: 'Réunion',
      code: 'RE',
    },
    {
      name: 'Saint Barthélemy',
      code: 'BL',
    },
    {
      name: 'Saint Helena',
      code: 'SH',
    },
    {
      name: 'Saint Lucia',
      code: 'LC',
    },
    {
      name: 'Saint Martin',
      code: 'MF',
    },
    {
      name: 'Saint Pierre and Miquelon',
      code: 'PM',
    },
    {
      name: 'Samoa',
      code: 'WS',
    },
    {
      name: 'San Marino',
      code: 'SM',
    },
    {
      name: 'Saudi Arabia',
      code: 'SA',
    },
    {
      name: 'Senegal',
      code: 'SN',
    },
    {
      name: 'Serbia',
      code: 'RS',
    },
    {
      name: 'Seychelles',
      code: 'SC',
    },
    {
      name: 'Sierra Leone',
      code: 'SL',
    },
    {
      name: 'Singapore',
      code: 'SG',
    },
    {
      name: 'Sint Maarten',
      code: 'SX',
    },
    {
      name: 'Slovakia',
      code: 'SK',
    },
    {
      name: 'Slovenia',
      code: 'SI',
    },
    {
      name: 'Solomon Islands',
      code: 'SB',
    },
    {
      name: 'Somalia',
      code: 'SO',
    },
    {
      name: 'South Africa',
      code: 'ZA',
    },
    {
      name: 'South Georgia and South Sandwich Islands',
      code: 'GS',
    },
    {
      name: 'Spain',
      code: 'ES',
    },
    {
      name: 'Sri Lanka',
      code: 'LK',
    },
    {
      name: 'St Kitts and Nevis',
      code: 'KN',
    },
    {
      name: 'St Vincent and Grenadines',
      code: 'VC',
    },
    {
      name: 'Sudan',
      code: 'SD',
    },
    {
      name: 'Suriname',
      code: 'SR',
    },
    {
      name: 'Svalbard and Jan Mayen',
      code: 'SJ',
    },
    {
      name: 'Sweden',
      code: 'SE',
    },
    {
      name: 'Switzerland',
      code: 'CH',
    },
    {
      name: 'Syria',
      code: 'SY',
    },
    {
      name: 'São Tomé and Príncipe',
      code: 'ST',
    },
    {
      name: 'Taiwan',
      code: 'TW',
    },
    {
      name: 'Tajikistan',
      code: 'TJ',
    },
    {
      name: 'Tanzania',
      code: 'TZ',
    },
    {
      name: 'Thailand',
      code: 'TH',
    },
    {
      name: 'Togo',
      code: 'TG',
    },
    {
      name: 'Tokelau',
      code: 'TK',
    },
    {
      name: 'Tonga',
      code: 'TO',
    },
    {
      name: 'Trinidad and Tobago',
      code: 'TT',
    },
    {
      name: 'Tunisia',
      code: 'TN',
    },
    {
      name: 'Turkey',
      code: 'TR',
    },
    {
      name: 'Turkmenistan',
      code: 'TM',
    },
    {
      name: 'Turks and Caicos Islands',
      code: 'TC',
    },
    {
      name: 'Tuvalu',
      code: 'TV',
    },
    {
      name: 'U.S. Minor Outlying Islands',
      code: 'UM',
    },
    {
      name: 'U.S. Virgin Islands',
      code: 'VI',
    },
    {
      name: 'Uganda',
      code: 'UG',
    },
    {
      name: 'Ukraine',
      code: 'UA',
    },
    {
      name: 'United Arab Emirates',
      code: 'AE',
    },
    {
      name: 'United States',
      code: 'US',
    },
    {
      name: 'Uruguay',
      code: 'UY',
    },
    {
      name: 'Uzbekistan',
      code: 'UZ',
    },
    {
      name: 'Vanuatu',
      code: 'VU',
    },
    {
      name: 'Vatican City',
      code: 'VA',
    },
    {
      name: 'Venezuela',
      code: 'VE',
    },
    {
      name: 'Vietnam',
      code: 'VN',
    },
    {
      name: 'Wallis and Futuna',
      code: 'WF',
    },
    {
      name: 'Western Sahara',
      code: 'EH',
    },
    {
      name: 'Yemen',
      code: 'YE',
    },
    {
      name: 'Zambia',
      code: 'ZM',
    },
    {
      name: 'Zimbabwe',
      code: 'ZW',
    },
    {
      name: 'Åland',
      code: 'AX',
    },
  ],
  'en-us': [
    {
      name: 'Afghanistan',
      code: 'AF',
    },
    {
      name: 'Albania',
      code: 'AL',
    },
    {
      name: 'Algeria',
      code: 'DZ',
    },
    {
      name: 'American Samoa',
      code: 'AS',
    },
    {
      name: 'Andorra',
      code: 'AD',
    },
    {
      name: 'Angola',
      code: 'AO',
    },
    {
      name: 'Anguilla',
      code: 'AI',
    },
    {
      name: 'Antarctica',
      code: 'AQ',
    },
    {
      name: 'Antigua and Barbuda',
      code: 'AG',
    },
    {
      name: 'Argentina',
      code: 'AR',
    },
    {
      name: 'Armenia',
      code: 'AM',
    },
    {
      name: 'Aruba',
      code: 'AW',
    },
    {
      name: 'Australia',
      code: 'AU',
    },
    {
      name: 'Austria',
      code: 'AT',
    },
    {
      name: 'Azerbaijan',
      code: 'AZ',
    },
    {
      name: 'Bahamas',
      code: 'BS',
    },
    {
      name: 'Bahrain',
      code: 'BH',
    },
    {
      name: 'Bangladesh',
      code: 'BD',
    },
    {
      name: 'Barbados',
      code: 'BB',
    },
    {
      name: 'Belarus',
      code: 'BY',
    },
    {
      name: 'Belgium',
      code: 'BE',
    },
    {
      name: 'Belize',
      code: 'BZ',
    },
    {
      name: 'Benin',
      code: 'BJ',
    },
    {
      name: 'Bermuda',
      code: 'BM',
    },
    {
      name: 'Bhutan',
      code: 'BT',
    },
    {
      name: 'Bolivia',
      code: 'BO',
    },
    {
      name: 'Bonaire, Saint Eustatius, and Saba',
      code: 'BQ',
    },
    {
      name: 'Bosnia and Herzegovina',
      code: 'BA',
    },
    {
      name: 'Botswana',
      code: 'BW',
    },
    {
      name: 'Bouvet Island',
      code: 'BV',
    },
    {
      name: 'Brazil',
      code: 'BR',
    },
    {
      name: 'British Indian Ocean Territory',
      code: 'IO',
    },
    {
      name: 'British Virgin Islands',
      code: 'VG',
    },
    {
      name: 'Brunei',
      code: 'BN',
    },
    {
      name: 'Bulgaria',
      code: 'BG',
    },
    {
      name: 'Burkina Faso',
      code: 'BF',
    },
    {
      name: 'Burundi',
      code: 'BI',
    },
    {
      name: 'Cabo Verde',
      code: 'CV',
    },
    {
      name: 'Cambodia',
      code: 'KH',
    },
    {
      name: 'Cameroon',
      code: 'CM',
    },
    {
      name: 'Canada',
      code: 'CA',
    },
    {
      name: 'Cayman Islands',
      code: 'KY',
    },
    {
      name: 'Central African Republic',
      code: 'CF',
    },
    {
      name: 'Chad',
      code: 'TD',
    },
    {
      name: 'Chile',
      code: 'CL',
    },
    {
      name: 'China',
      code: 'CN',
    },
    {
      name: 'Christmas Island',
      code: 'CX',
    },
    {
      name: 'Cocos [Keeling] Islands',
      code: 'CC',
    },
    {
      name: 'Colombia',
      code: 'CO',
    },
    {
      name: 'Comoros',
      code: 'KM',
    },
    {
      name: 'Congo',
      code: 'CD',
    },
    {
      name: 'Congo',
      code: 'CG',
    },
    {
      name: 'Cook Islands',
      code: 'CK',
    },
    {
      name: 'Costa Rica',
      code: 'CR',
    },
    {
      name: 'Croatia',
      code: 'HR',
    },
    {
      name: 'Cuba',
      code: 'CU',
    },
    {
      name: 'Curaçao',
      code: 'CW',
    },
    {
      name: 'Cyprus',
      code: 'CY',
    },
    {
      name: 'Czechia',
      code: 'CZ',
    },
    {
      name: 'Denmark',
      code: 'DK',
    },
    {
      name: 'Djibouti',
      code: 'DJ',
    },
    {
      name: 'Dominica',
      code: 'DM',
    },
    {
      name: 'Dominican Republic',
      code: 'DO',
    },
    {
      name: 'East Timor',
      code: 'TL',
    },
    {
      name: 'Ecuador',
      code: 'EC',
    },
    {
      name: 'Egypt',
      code: 'EG',
    },
    {
      name: 'El Salvador',
      code: 'SV',
    },
    {
      name: 'Equatorial Guinea',
      code: 'GQ',
    },
    {
      name: 'Eritrea',
      code: 'ER',
    },
    {
      name: 'Estonia',
      code: 'EE',
    },
    {
      name: 'Eswatini',
      code: 'SZ',
    },
    {
      name: 'Ethiopia',
      code: 'ET',
    },
    {
      name: 'Falkland Islands',
      code: 'FK',
    },
    {
      name: 'Faroe Islands',
      code: 'FO',
    },
    {
      name: 'Fiji',
      code: 'FJ',
    },
    {
      name: 'Finland',
      code: 'FI',
    },
    {
      name: 'France',
      code: 'FR',
    },
    {
      name: 'French Guiana',
      code: 'GF',
    },
    {
      name: 'French Polynesia',
      code: 'PF',
    },
    {
      name: 'French Southern Territories',
      code: 'TF',
    },
    {
      name: 'Gabon',
      code: 'GA',
    },
    {
      name: 'Gambia',
      code: 'GM',
    },
    {
      name: 'Georgia',
      code: 'GE',
    },
    {
      name: 'Germany',
      code: 'DE',
    },
    {
      name: 'Ghana',
      code: 'GH',
    },
    {
      name: 'Gibraltar',
      code: 'GI',
    },
    {
      name: 'Great Britain',
      code: 'GB',
    },
    {
      name: 'Greece',
      code: 'GR',
    },
    {
      name: 'Greenland',
      code: 'GL',
    },
    {
      name: 'Grenada',
      code: 'GD',
    },
    {
      name: 'Guadeloupe',
      code: 'GP',
    },
    {
      name: 'Guam',
      code: 'GU',
    },
    {
      name: 'Guatemala',
      code: 'GT',
    },
    {
      name: 'Guernsey',
      code: 'GG',
    },
    {
      name: 'Guinea',
      code: 'GN',
    },
    {
      name: 'Guinea-Bissau',
      code: 'GW',
    },
    {
      name: 'Guyana',
      code: 'GY',
    },
    {
      name: 'Haiti',
      code: 'HT',
    },
    {
      name: 'Hashemite Kingdom of Jordan',
      code: 'JO',
    },
    {
      name: 'Heard Island and McDonald Islands',
      code: 'HM',
    },
    {
      name: 'Honduras',
      code: 'HN',
    },
    {
      name: 'Hong Kong',
      code: 'HK',
    },
    {
      name: 'Hungary',
      code: 'HU',
    },
    {
      name: 'Iceland',
      code: 'IS',
    },
    {
      name: 'India',
      code: 'IN',
    },
    {
      name: 'Indonesia',
      code: 'ID',
    },
    {
      name: 'Iran',
      code: 'IR',
    },
    {
      name: 'Iraq',
      code: 'IQ',
    },
    {
      name: 'Ireland',
      code: 'IE',
    },
    {
      name: 'Isle of Man',
      code: 'IM',
    },
    {
      name: 'Israel',
      code: 'IL',
    },
    {
      name: 'Italy',
      code: 'IT',
    },
    {
      name: 'Ivory Coast',
      code: 'CI',
    },
    {
      name: 'Jamaica',
      code: 'JM',
    },
    {
      name: 'Japan',
      code: 'JP',
    },
    {
      name: 'Jersey',
      code: 'JE',
    },
    {
      name: 'Kazakhstan',
      code: 'KZ',
    },
    {
      name: 'Kenya',
      code: 'KE',
    },
    {
      name: 'Kiribati',
      code: 'KI',
    },
    {
      name: 'Kosovo',
      code: 'XK',
    },
    {
      name: 'Kuwait',
      code: 'KW',
    },
    {
      name: 'Kyrgyzstan',
      code: 'KG',
    },
    {
      name: 'Laos',
      code: 'LA',
    },
    {
      name: 'Latvia',
      code: 'LV',
    },
    {
      name: 'Lebanon',
      code: 'LB',
    },
    {
      name: 'Lesotho',
      code: 'LS',
    },
    {
      name: 'Liberia',
      code: 'LR',
    },
    {
      name: 'Libya',
      code: 'LY',
    },
    {
      name: 'Liechtenstein',
      code: 'LI',
    },
    {
      name: 'Lithuania',
      code: 'LT',
    },
    {
      name: 'Luxembourg',
      code: 'LU',
    },
    {
      name: 'Macao',
      code: 'MO',
    },
    {
      name: 'Madagascar',
      code: 'MG',
    },
    {
      name: 'Malawi',
      code: 'MW',
    },
    {
      name: 'Malaysia',
      code: 'MY',
    },
    {
      name: 'Maldives',
      code: 'MV',
    },
    {
      name: 'Mali',
      code: 'ML',
    },
    {
      name: 'Malta',
      code: 'MT',
    },
    {
      name: 'Marshall Islands',
      code: 'MH',
    },
    {
      name: 'Martinique',
      code: 'MQ',
    },
    {
      name: 'Mauritania',
      code: 'MR',
    },
    {
      name: 'Mauritius',
      code: 'MU',
    },
    {
      name: 'Mayotte',
      code: 'YT',
    },
    {
      name: 'Mexico',
      code: 'MX',
    },
    {
      name: 'Micronesia',
      code: 'FM',
    },
    {
      name: 'Moldova',
      code: 'MD',
    },
    {
      name: 'Monaco',
      code: 'MC',
    },
    {
      name: 'Mongolia',
      code: 'MN',
    },
    {
      name: 'Montenegro',
      code: 'ME',
    },
    {
      name: 'Montserrat',
      code: 'MS',
    },
    {
      name: 'Morocco',
      code: 'MA',
    },
    {
      name: 'Mozambique',
      code: 'MZ',
    },
    {
      name: 'Myanmar',
      code: 'MM',
    },
    {
      name: 'Namibia',
      code: 'NA',
    },
    {
      name: 'Nauru',
      code: 'NR',
    },
    {
      name: 'Nepal',
      code: 'NP',
    },
    {
      name: 'Netherlands',
      code: 'NL',
    },
    {
      name: 'New Caledonia',
      code: 'NC',
    },
    {
      name: 'New Zealand',
      code: 'NZ',
    },
    {
      name: 'Nicaragua',
      code: 'NI',
    },
    {
      name: 'Niger',
      code: 'NE',
    },
    {
      name: 'Nigeria',
      code: 'NG',
    },
    {
      name: 'Niue',
      code: 'NU',
    },
    {
      name: 'Norfolk Island',
      code: 'NF',
    },
    {
      name: 'North Korea',
      code: 'KP',
    },
    {
      name: 'North Macedonia',
      code: 'MK',
    },
    {
      name: 'Northern Mariana Islands',
      code: 'MP',
    },
    {
      name: 'Norway',
      code: 'NO',
    },
    {
      name: 'Oman',
      code: 'OM',
    },
    {
      name: 'Pakistan',
      code: 'PK',
    },
    {
      name: 'Palau',
      code: 'PW',
    },
    {
      name: 'Palestine',
      code: 'PS',
    },
    {
      name: 'Panama',
      code: 'PA',
    },
    {
      name: 'Papua New Guinea',
      code: 'PG',
    },
    {
      name: 'Paraguay',
      code: 'PY',
    },
    {
      name: 'Peru',
      code: 'PE',
    },
    {
      name: 'Philippines',
      code: 'PH',
    },
    {
      name: 'Pitcairn Islands',
      code: 'PN',
    },
    {
      name: 'Poland',
      code: 'PL',
    },
    {
      name: 'Portugal',
      code: 'PT',
    },
    {
      name: 'Puerto Rico',
      code: 'PR',
    },
    {
      name: 'Qatar',
      code: 'QA',
    },
    {
      name: 'Republic of Korea',
      code: 'KR',
    },
    {
      name: 'Republic of South Sudan',
      code: 'SS',
    },
    {
      name: 'Romania',
      code: 'RO',
    },
    {
      name: 'Russia',
      code: 'RU',
    },
    {
      name: 'Rwanda',
      code: 'RW',
    },
    {
      name: 'Réunion',
      code: 'RE',
    },
    {
      name: 'Saint Barthélemy',
      code: 'BL',
    },
    {
      name: 'Saint Helena',
      code: 'SH',
    },
    {
      name: 'Saint Lucia',
      code: 'LC',
    },
    {
      name: 'Saint Martin',
      code: 'MF',
    },
    {
      name: 'Saint Pierre and Miquelon',
      code: 'PM',
    },
    {
      name: 'Samoa',
      code: 'WS',
    },
    {
      name: 'San Marino',
      code: 'SM',
    },
    {
      name: 'Saudi Arabia',
      code: 'SA',
    },
    {
      name: 'Senegal',
      code: 'SN',
    },
    {
      name: 'Serbia',
      code: 'RS',
    },
    {
      name: 'Seychelles',
      code: 'SC',
    },
    {
      name: 'Sierra Leone',
      code: 'SL',
    },
    {
      name: 'Singapore',
      code: 'SG',
    },
    {
      name: 'Sint Maarten',
      code: 'SX',
    },
    {
      name: 'Slovakia',
      code: 'SK',
    },
    {
      name: 'Slovenia',
      code: 'SI',
    },
    {
      name: 'Solomon Islands',
      code: 'SB',
    },
    {
      name: 'Somalia',
      code: 'SO',
    },
    {
      name: 'South Africa',
      code: 'ZA',
    },
    {
      name: 'South Georgia and South Sandwich Islands',
      code: 'GS',
    },
    {
      name: 'Spain',
      code: 'ES',
    },
    {
      name: 'Sri Lanka',
      code: 'LK',
    },
    {
      name: 'St Kitts and Nevis',
      code: 'KN',
    },
    {
      name: 'St Vincent and Grenadines',
      code: 'VC',
    },
    {
      name: 'Sudan',
      code: 'SD',
    },
    {
      name: 'Suriname',
      code: 'SR',
    },
    {
      name: 'Svalbard and Jan Mayen',
      code: 'SJ',
    },
    {
      name: 'Sweden',
      code: 'SE',
    },
    {
      name: 'Switzerland',
      code: 'CH',
    },
    {
      name: 'Syria',
      code: 'SY',
    },
    {
      name: 'São Tomé and Príncipe',
      code: 'ST',
    },
    {
      name: 'Taiwan',
      code: 'TW',
    },
    {
      name: 'Tajikistan',
      code: 'TJ',
    },
    {
      name: 'Tanzania',
      code: 'TZ',
    },
    {
      name: 'Thailand',
      code: 'TH',
    },
    {
      name: 'Togo',
      code: 'TG',
    },
    {
      name: 'Tokelau',
      code: 'TK',
    },
    {
      name: 'Tonga',
      code: 'TO',
    },
    {
      name: 'Trinidad and Tobago',
      code: 'TT',
    },
    {
      name: 'Tunisia',
      code: 'TN',
    },
    {
      name: 'Turkey',
      code: 'TR',
    },
    {
      name: 'Turkmenistan',
      code: 'TM',
    },
    {
      name: 'Turks and Caicos Islands',
      code: 'TC',
    },
    {
      name: 'Tuvalu',
      code: 'TV',
    },
    {
      name: 'U.S. Minor Outlying Islands',
      code: 'UM',
    },
    {
      name: 'U.S. Virgin Islands',
      code: 'VI',
    },
    {
      name: 'Uganda',
      code: 'UG',
    },
    {
      name: 'Ukraine',
      code: 'UA',
    },
    {
      name: 'United Arab Emirates',
      code: 'AE',
    },
    {
      name: 'United States',
      code: 'US',
    },
    {
      name: 'Uruguay',
      code: 'UY',
    },
    {
      name: 'Uzbekistan',
      code: 'UZ',
    },
    {
      name: 'Vanuatu',
      code: 'VU',
    },
    {
      name: 'Vatican City',
      code: 'VA',
    },
    {
      name: 'Venezuela',
      code: 'VE',
    },
    {
      name: 'Vietnam',
      code: 'VN',
    },
    {
      name: 'Wallis and Futuna',
      code: 'WF',
    },
    {
      name: 'Western Sahara',
      code: 'EH',
    },
    {
      name: 'Yemen',
      code: 'YE',
    },
    {
      name: 'Zambia',
      code: 'ZM',
    },
    {
      name: 'Zimbabwe',
      code: 'ZW',
    },
    {
      name: 'Åland',
      code: 'AX',
    },
  ],
  'es-es': [
    {
      name: 'Afganistán',
      code: 'AF',
    },
    {
      name: 'Albania',
      code: 'AL',
    },
    {
      name: 'Alemania',
      code: 'DE',
    },
    {
      name: 'Andorra',
      code: 'AD',
    },
    {
      name: 'Angola',
      code: 'AO',
    },
    {
      name: 'Anguila',
      code: 'AI',
    },
    {
      name: 'Antigua y Barbuda',
      code: 'AG',
    },
    {
      name: 'Antártida',
      code: 'AQ',
    },
    {
      name: 'Arabia Saudí',
      code: 'SA',
    },
    {
      name: 'Argelia',
      code: 'DZ',
    },
    {
      name: 'Argentina',
      code: 'AR',
    },
    {
      name: 'Armenia',
      code: 'AM',
    },
    {
      name: 'Aruba',
      code: 'AW',
    },
    {
      name: 'Australia',
      code: 'AU',
    },
    {
      name: 'Austria',
      code: 'AT',
    },
    {
      name: 'Azerbaiyán',
      code: 'AZ',
    },
    {
      name: 'Bahamas',
      code: 'BS',
    },
    {
      name: 'Bahréin',
      code: 'BH',
    },
    {
      name: 'Bangladesh',
      code: 'BD',
    },
    {
      name: 'Barbados',
      code: 'BB',
    },
    {
      name: 'Belice',
      code: 'BZ',
    },
    {
      name: 'Benín',
      code: 'BJ',
    },
    {
      name: 'Bermudas',
      code: 'BM',
    },
    {
      name: 'Bielorrusia',
      code: 'BY',
    },
    {
      name: 'Bolivia',
      code: 'BO',
    },
    {
      name: 'Bonaire',
      code: 'BQ',
    },
    {
      name: 'Bosnia-Herzegovina',
      code: 'BA',
    },
    {
      name: 'Botsuana',
      code: 'BW',
    },
    {
      name: 'Brasil',
      code: 'BR',
    },
    {
      name: 'Brunéi',
      code: 'BN',
    },
    {
      name: 'Bulgaria',
      code: 'BG',
    },
    {
      name: 'Burkina Faso',
      code: 'BF',
    },
    {
      name: 'Burundi',
      code: 'BI',
    },
    {
      name: 'Bután',
      code: 'BT',
    },
    {
      name: 'Bélgica',
      code: 'BE',
    },
    {
      name: 'Cabo Verde',
      code: 'CV',
    },
    {
      name: 'Camboya',
      code: 'KH',
    },
    {
      name: 'Camerún',
      code: 'CM',
    },
    {
      name: 'Canadá',
      code: 'CA',
    },
    {
      name: 'Chad',
      code: 'TD',
    },
    {
      name: 'Chile',
      code: 'CL',
    },
    {
      name: 'China',
      code: 'CN',
    },
    {
      name: 'Chipre',
      code: 'CY',
    },
    {
      name: 'Ciudad del Vaticano',
      code: 'VA',
    },
    {
      name: 'Colombia',
      code: 'CO',
    },
    {
      name: 'Comoras',
      code: 'KM',
    },
    {
      name: 'Congo Democrático',
      code: 'CD',
    },
    {
      name: 'Congo [República]',
      code: 'CG',
    },
    {
      name: 'Corea del Norte',
      code: 'KP',
    },
    {
      name: 'Corea del Sur',
      code: 'KR',
    },
    {
      name: 'Costa Rica',
      code: 'CR',
    },
    {
      name: 'Costa de Marfil',
      code: 'CI',
    },
    {
      name: 'Croacia',
      code: 'HR',
    },
    {
      name: 'Cuba',
      code: 'CU',
    },
    {
      name: 'Curazao',
      code: 'CW',
    },
    {
      name: 'Dinamarca',
      code: 'DK',
    },
    {
      name: 'Dominica',
      code: 'DM',
    },
    {
      name: 'Ecuador',
      code: 'EC',
    },
    {
      name: 'Egipto',
      code: 'EG',
    },
    {
      name: 'El Salvador',
      code: 'SV',
    },
    {
      name: 'Emiratos Árabes Unidos',
      code: 'AE',
    },
    {
      name: 'Eritrea',
      code: 'ER',
    },
    {
      name: 'Eslovaquia',
      code: 'SK',
    },
    {
      name: 'Eslovenia',
      code: 'SI',
    },
    {
      name: 'España',
      code: 'ES',
    },
    {
      name: 'Estados Unidos',
      code: 'US',
    },
    {
      name: 'Estonia',
      code: 'EE',
    },
    {
      name: 'Etiopía',
      code: 'ET',
    },
    {
      name: 'Filipinas',
      code: 'PH',
    },
    {
      name: 'Finlandia',
      code: 'FI',
    },
    {
      name: 'Fiyi',
      code: 'FJ',
    },
    {
      name: 'Francia',
      code: 'FR',
    },
    {
      name: 'Gabón',
      code: 'GA',
    },
    {
      name: 'Gambia',
      code: 'GM',
    },
    {
      name: 'Georgia',
      code: 'GE',
    },
    {
      name: 'Ghana',
      code: 'GH',
    },
    {
      name: 'Gibraltar',
      code: 'GI',
    },
    {
      name: 'Granada',
      code: 'GD',
    },
    {
      name: 'Grecia',
      code: 'GR',
    },
    {
      name: 'Groenlandia',
      code: 'GL',
    },
    {
      name: 'Guadalupe',
      code: 'GP',
    },
    {
      name: 'Guam',
      code: 'GU',
    },
    {
      name: 'Guatemala',
      code: 'GT',
    },
    {
      name: 'Guayana Francesa',
      code: 'GF',
    },
    {
      name: 'Guernsey',
      code: 'GG',
    },
    {
      name: 'Guinea',
      code: 'GN',
    },
    {
      name: 'Guinea Ecuatorial',
      code: 'GQ',
    },
    {
      name: 'Guinea-Bissáu',
      code: 'GW',
    },
    {
      name: 'Guyana',
      code: 'GY',
    },
    {
      name: 'Haití',
      code: 'HT',
    },
    {
      name: 'Holanda',
      code: 'NL',
    },
    {
      name: 'Honduras',
      code: 'HN',
    },
    {
      name: 'Hong Kong',
      code: 'HK',
    },
    {
      name: 'Hungría',
      code: 'HU',
    },
    {
      name: 'India',
      code: 'IN',
    },
    {
      name: 'Indonesia',
      code: 'ID',
    },
    {
      name: 'Irak',
      code: 'IQ',
    },
    {
      name: 'Irlanda',
      code: 'IE',
    },
    {
      name: 'Irán',
      code: 'IR',
    },
    {
      name: 'Isla Bouvet',
      code: 'BV',
    },
    {
      name: 'Isla Christmas',
      code: 'CX',
    },
    {
      name: 'Isla Niue',
      code: 'NU',
    },
    {
      name: 'Isla Norfolk',
      code: 'NF',
    },
    {
      name: 'Isla de Man',
      code: 'IM',
    },
    {
      name: 'Isla de San Martín',
      code: 'SX',
    },
    {
      name: 'Islandia',
      code: 'IS',
    },
    {
      name: 'Islas Caimán',
      code: 'KY',
    },
    {
      name: 'Islas Cocos',
      code: 'CC',
    },
    {
      name: 'Islas Cook',
      code: 'CK',
    },
    {
      name: 'Islas Feroe',
      code: 'FO',
    },
    {
      name: 'Islas Georgia del Sur y Sandwich del Sur',
      code: 'GS',
    },
    {
      name: 'Islas Heard y McDonald',
      code: 'HM',
    },
    {
      name: 'Islas Malvinas',
      code: 'FK',
    },
    {
      name: 'Islas Marianas del Norte',
      code: 'MP',
    },
    {
      name: 'Islas Marshall',
      code: 'MH',
    },
    {
      name: 'Islas Pitcairn',
      code: 'PN',
    },
    {
      name: 'Islas Salomón',
      code: 'SB',
    },
    {
      name: 'Islas Turcas y Caicos',
      code: 'TC',
    },
    {
      name: 'Islas Vírgenes',
      code: 'VG',
    },
    {
      name: 'Islas Vírgenes de los Estados Unidos',
      code: 'VI',
    },
    {
      name: 'Islas de Åland',
      code: 'AX',
    },
    {
      name: 'Islas menores alejadas de los Estados Unidos',
      code: 'UM',
    },
    {
      name: 'Israel',
      code: 'IL',
    },
    {
      name: 'Italia',
      code: 'IT',
    },
    {
      name: 'Jamaica',
      code: 'JM',
    },
    {
      name: 'Japón',
      code: 'JP',
    },
    {
      name: 'Jersey',
      code: 'JE',
    },
    {
      name: 'Jordania',
      code: 'JO',
    },
    {
      name: 'Kazajstán',
      code: 'KZ',
    },
    {
      name: 'Kenia',
      code: 'KE',
    },
    {
      name: 'Kirguistán',
      code: 'KG',
    },
    {
      name: 'Kiribati',
      code: 'KI',
    },
    {
      name: 'Kosovo',
      code: 'XK',
    },
    {
      name: 'Kuwait',
      code: 'KW',
    },
    {
      name: 'Laos',
      code: 'LA',
    },
    {
      name: 'Lesotho',
      code: 'LS',
    },
    {
      name: 'Letonia',
      code: 'LV',
    },
    {
      name: 'Liberia',
      code: 'LR',
    },
    {
      name: 'Libia',
      code: 'LY',
    },
    {
      name: 'Liechtenstein',
      code: 'LI',
    },
    {
      name: 'Lituania',
      code: 'LT',
    },
    {
      name: 'Luxemburgo',
      code: 'LU',
    },
    {
      name: 'Líbano',
      code: 'LB',
    },
    {
      name: 'Macao',
      code: 'MO',
    },
    {
      name: 'Macedonia, Antigua República Yugoslava',
      code: 'MK',
    },
    {
      name: 'Madagascar',
      code: 'MG',
    },
    {
      name: 'Malasia',
      code: 'MY',
    },
    {
      name: 'Malaui',
      code: 'MW',
    },
    {
      name: 'Maldivas',
      code: 'MV',
    },
    {
      name: 'Mali',
      code: 'ML',
    },
    {
      name: 'Malta',
      code: 'MT',
    },
    {
      name: 'Marruecos',
      code: 'MA',
    },
    {
      name: 'Martinica',
      code: 'MQ',
    },
    {
      name: 'Mauricio',
      code: 'MU',
    },
    {
      name: 'Mauritania',
      code: 'MR',
    },
    {
      name: 'Mayotte',
      code: 'YT',
    },
    {
      name: 'Micronesia',
      code: 'FM',
    },
    {
      name: 'Moldavia',
      code: 'MD',
    },
    {
      name: 'Mongolia',
      code: 'MN',
    },
    {
      name: 'Montenegro',
      code: 'ME',
    },
    {
      name: 'Montserrat',
      code: 'MS',
    },
    {
      name: 'Mozambique',
      code: 'MZ',
    },
    {
      name: 'Myanmar [Birmania]',
      code: 'MM',
    },
    {
      name: 'México',
      code: 'MX',
    },
    {
      name: 'Mónaco',
      code: 'MC',
    },
    {
      name: 'Namibia',
      code: 'NA',
    },
    {
      name: 'Nauru',
      code: 'NR',
    },
    {
      name: 'Nepal',
      code: 'NP',
    },
    {
      name: 'Nicaragua',
      code: 'NI',
    },
    {
      name: 'Nigeria',
      code: 'NG',
    },
    {
      name: 'Noruega',
      code: 'NO',
    },
    {
      name: 'Nueva Caledonia',
      code: 'NC',
    },
    {
      name: 'Nueva Zelanda',
      code: 'NZ',
    },
    {
      name: 'Níger',
      code: 'NE',
    },
    {
      name: 'Omán',
      code: 'OM',
    },
    {
      name: 'Pakistán',
      code: 'PK',
    },
    {
      name: 'Palaos',
      code: 'PW',
    },
    {
      name: 'Panamá',
      code: 'PA',
    },
    {
      name: 'Papúa-Nueva Guinea',
      code: 'PG',
    },
    {
      name: 'Paraguay',
      code: 'PY',
    },
    {
      name: 'Perú',
      code: 'PE',
    },
    {
      name: 'Polinesia Francesa',
      code: 'PF',
    },
    {
      name: 'Polonia',
      code: 'PL',
    },
    {
      name: 'Portugal',
      code: 'PT',
    },
    {
      name: 'Puerto Rico',
      code: 'PR',
    },
    {
      name: 'Qatar',
      code: 'QA',
    },
    {
      name: 'Reino Unido',
      code: 'GB',
    },
    {
      name: 'República Centroafricana',
      code: 'CF',
    },
    {
      name: 'República Checa',
      code: 'CZ',
    },
    {
      name: 'República de Santo Domingo',
      code: 'DO',
    },
    {
      name: 'República de Sudán del Sur',
      code: 'SS',
    },
    {
      name: 'República Árabe Saharaui Democrática',
      code: 'EH',
    },
    {
      name: 'Reunión',
      code: 'RE',
    },
    {
      name: 'Ruanda',
      code: 'RW',
    },
    {
      name: 'Rumanía',
      code: 'RO',
    },
    {
      name: 'Rusia',
      code: 'RU',
    },
    {
      name: 'Samoa',
      code: 'WS',
    },
    {
      name: 'Samoa Americana',
      code: 'AS',
    },
    {
      name: 'San Bartolomé',
      code: 'BL',
    },
    {
      name: 'San Cristóbal y Nieves',
      code: 'KN',
    },
    {
      name: 'San Marino',
      code: 'SM',
    },
    {
      name: 'San Martín',
      code: 'MF',
    },
    {
      name: 'San Pedro y Miquelón',
      code: 'PM',
    },
    {
      name: 'San Vicente y las Granadinas',
      code: 'VC',
    },
    {
      name: 'Santa Elena',
      code: 'SH',
    },
    {
      name: 'Santa Lucía',
      code: 'LC',
    },
    {
      name: 'Santo Tomé y Príncipe',
      code: 'ST',
    },
    {
      name: 'Senegal',
      code: 'SN',
    },
    {
      name: 'Serbia',
      code: 'RS',
    },
    {
      name: 'Seychelles',
      code: 'SC',
    },
    {
      name: 'Sierra Leona',
      code: 'SL',
    },
    {
      name: 'Singapur',
      code: 'SG',
    },
    {
      name: 'Siria',
      code: 'SY',
    },
    {
      name: 'Somalia',
      code: 'SO',
    },
    {
      name: 'Sri Lanka',
      code: 'LK',
    },
    {
      name: 'Suazilandia',
      code: 'SZ',
    },
    {
      name: 'Sudáfrica',
      code: 'ZA',
    },
    {
      name: 'Sudán',
      code: 'SD',
    },
    {
      name: 'Suecia',
      code: 'SE',
    },
    {
      name: 'Suiza',
      code: 'CH',
    },
    {
      name: 'Surinam',
      code: 'SR',
    },
    {
      name: 'Svalbard y Jan Mayen',
      code: 'SJ',
    },
    {
      name: 'Tailandia',
      code: 'TH',
    },
    {
      name: 'Taiwán',
      code: 'TW',
    },
    {
      name: 'Tanzania',
      code: 'TZ',
    },
    {
      name: 'Tayikistán',
      code: 'TJ',
    },
    {
      name: 'Territorio Británico del Océano Índico',
      code: 'IO',
    },
    {
      name: 'Territorios Australes Franceses',
      code: 'TF',
    },
    {
      name: 'Territorios Palestinos',
      code: 'PS',
    },
    {
      name: 'Timor Oriental',
      code: 'TL',
    },
    {
      name: 'Togo',
      code: 'TG',
    },
    {
      name: 'Tokelau',
      code: 'TK',
    },
    {
      name: 'Tonga',
      code: 'TO',
    },
    {
      name: 'Trinidad y Tobago',
      code: 'TT',
    },
    {
      name: 'Turkmenistán',
      code: 'TM',
    },
    {
      name: 'Turquía',
      code: 'TR',
    },
    {
      name: 'Tuvalu',
      code: 'TV',
    },
    {
      name: 'Túnez',
      code: 'TN',
    },
    {
      name: 'Ucrania',
      code: 'UA',
    },
    {
      name: 'Uganda',
      code: 'UG',
    },
    {
      name: 'Uruguay',
      code: 'UY',
    },
    {
      name: 'Uzbekistán',
      code: 'UZ',
    },
    {
      name: 'Vanuatu',
      code: 'VU',
    },
    {
      name: 'Venezuela',
      code: 'VE',
    },
    {
      name: 'Vietnam',
      code: 'VN',
    },
    {
      name: 'Wallis y Futuna',
      code: 'WF',
    },
    {
      name: 'Yemen',
      code: 'YE',
    },
    {
      name: 'Yibuti',
      code: 'DJ',
    },
    {
      name: 'Zambia',
      code: 'ZM',
    },
    {
      name: 'Zimbawe',
      code: 'ZW',
    },
  ],
  'fr-fr': [
    {
      name: 'Afghanistan',
      code: 'AF',
    },
    {
      name: 'Afrique du Sud',
      code: 'ZA',
    },
    {
      name: 'Albanie',
      code: 'AL',
    },
    {
      name: 'Algérie',
      code: 'DZ',
    },
    {
      name: 'Allemagne',
      code: 'DE',
    },
    {
      name: 'Andorre',
      code: 'AD',
    },
    {
      name: 'Angola',
      code: 'AO',
    },
    {
      name: 'Anguilla',
      code: 'AI',
    },
    {
      name: 'Antarctique',
      code: 'AQ',
    },
    {
      name: 'Antigua et Barbuda',
      code: 'AG',
    },
    {
      name: 'Arabie saoudite',
      code: 'SA',
    },
    {
      name: 'Argentine',
      code: 'AR',
    },
    {
      name: 'Arménie',
      code: 'AM',
    },
    {
      name: 'Aruba',
      code: 'AW',
    },
    {
      name: 'Australie',
      code: 'AU',
    },
    {
      name: 'Autriche',
      code: 'AT',
    },
    {
      name: 'Azerbaïdjan',
      code: 'AZ',
    },
    {
      name: 'Bahamas',
      code: 'BS',
    },
    {
      name: 'Bahreïn',
      code: 'BH',
    },
    {
      name: 'Bangladesh',
      code: 'BD',
    },
    {
      name: 'Barbade',
      code: 'BB',
    },
    {
      name: 'Belgique',
      code: 'BE',
    },
    {
      name: 'Belize',
      code: 'BZ',
    },
    {
      name: 'Bermudes',
      code: 'BM',
    },
    {
      name: 'Bhoutan',
      code: 'BT',
    },
    {
      name: 'Birmanie',
      code: 'MM',
    },
    {
      name: 'Biélorussie',
      code: 'BY',
    },
    {
      name: 'Bolivie',
      code: 'BO',
    },
    {
      name: 'Bonaire, Saint-Eustache et Saba',
      code: 'BQ',
    },
    {
      name: 'Bosnie-Herzégovine',
      code: 'BA',
    },
    {
      name: 'Botswana',
      code: 'BW',
    },
    {
      name: 'Brunéi Darussalam',
      code: 'BN',
    },
    {
      name: 'Brésil',
      code: 'BR',
    },
    {
      name: 'Bulgarie',
      code: 'BG',
    },
    {
      name: 'Burkina Faso',
      code: 'BF',
    },
    {
      name: 'Burundi',
      code: 'BI',
    },
    {
      name: 'Bénin',
      code: 'BJ',
    },
    {
      name: 'Cambodge',
      code: 'KH',
    },
    {
      name: 'Cameroun',
      code: 'CM',
    },
    {
      name: 'Canada',
      code: 'CA',
    },
    {
      name: 'Cap-Vert',
      code: 'CV',
    },
    {
      name: 'Centrafrique',
      code: 'CF',
    },
    {
      name: 'Chili',
      code: 'CL',
    },
    {
      name: 'Chine',
      code: 'CN',
    },
    {
      name: 'Chypre',
      code: 'CY',
    },
    {
      name: 'Colombie',
      code: 'CO',
    },
    {
      name: 'Comores',
      code: 'KM',
    },
    {
      name: 'Congo-Brazzaville',
      code: 'CG',
    },
    {
      name: 'Congo-Kinshasa',
      code: 'CD',
    },
    {
      name: 'Corée du Nord',
      code: 'KP',
    },
    {
      name: 'Corée du Sud',
      code: 'KR',
    },
    {
      name: 'Costa Rica',
      code: 'CR',
    },
    {
      name: 'Croatie',
      code: 'HR',
    },
    {
      name: 'Cuba',
      code: 'CU',
    },
    {
      name: 'Curaçao',
      code: 'CW',
    },
    {
      name: "Côte d'Ivoire",
      code: 'CI',
    },
    {
      name: 'Danemark',
      code: 'DK',
    },
    {
      name: 'Djibouti',
      code: 'DJ',
    },
    {
      name: 'Dominique',
      code: 'DM',
    },
    {
      name: 'Espagne',
      code: 'ES',
    },
    {
      name: 'Estonie',
      code: 'EE',
    },
    {
      name: 'Fidji',
      code: 'FJ',
    },
    {
      name: 'Finlande',
      code: 'FI',
    },
    {
      name: 'France',
      code: 'FR',
    },
    {
      name: 'Gabon',
      code: 'GA',
    },
    {
      name: 'Gambie',
      code: 'GM',
    },
    {
      name: 'Ghana',
      code: 'GH',
    },
    {
      name: 'Gibraltar',
      code: 'GI',
    },
    {
      name: 'Grenade',
      code: 'GD',
    },
    {
      name: 'Groenland',
      code: 'GL',
    },
    {
      name: 'Grèce',
      code: 'GR',
    },
    {
      name: 'Guadeloupe',
      code: 'GP',
    },
    {
      name: 'Guam',
      code: 'GU',
    },
    {
      name: 'Guatemala',
      code: 'GT',
    },
    {
      name: 'Guernesey',
      code: 'GG',
    },
    {
      name: 'Guinée',
      code: 'GN',
    },
    {
      name: 'Guinée équatoriale',
      code: 'GQ',
    },
    {
      name: 'Guinée-Bissau',
      code: 'GW',
    },
    {
      name: 'Guyana',
      code: 'GY',
    },
    {
      name: 'Guyane',
      code: 'GF',
    },
    {
      name: 'Géorgie',
      code: 'GE',
    },
    {
      name: 'Géorgie du Sud-et-les îles Sandwich du Sud',
      code: 'GS',
    },
    {
      name: 'Haïti',
      code: 'HT',
    },
    {
      name: 'Honduras',
      code: 'HN',
    },
    {
      name: 'Hong Kong',
      code: 'HK',
    },
    {
      name: 'Hongrie',
      code: 'HU',
    },
    {
      name: 'Inde',
      code: 'IN',
    },
    {
      name: 'Indonésie',
      code: 'ID',
    },
    {
      name: 'Irak',
      code: 'IQ',
    },
    {
      name: 'Iran',
      code: 'IR',
    },
    {
      name: 'Irlande',
      code: 'IE',
    },
    {
      name: 'Islande',
      code: 'IS',
    },
    {
      name: 'Israël',
      code: 'IL',
    },
    {
      name: 'Italie',
      code: 'IT',
    },
    {
      name: 'Jamaïque',
      code: 'JM',
    },
    {
      name: 'Japon',
      code: 'JP',
    },
    {
      name: 'Jersey',
      code: 'JE',
    },
    {
      name: 'Jordanie',
      code: 'JO',
    },
    {
      name: 'Kazakhstan',
      code: 'KZ',
    },
    {
      name: 'Kenya',
      code: 'KE',
    },
    {
      name: 'Kirghizistan',
      code: 'KG',
    },
    {
      name: 'Kiribati',
      code: 'KI',
    },
    {
      name: 'Kosovo',
      code: 'XK',
    },
    {
      name: 'Koweït',
      code: 'KW',
    },
    {
      name: 'La Réunion',
      code: 'RE',
    },
    {
      name: 'Laos',
      code: 'LA',
    },
    {
      name: 'Lesotho',
      code: 'LS',
    },
    {
      name: 'Lettonie',
      code: 'LV',
    },
    {
      name: 'Liban',
      code: 'LB',
    },
    {
      name: 'Liberia',
      code: 'LR',
    },
    {
      name: 'Libye',
      code: 'LY',
    },
    {
      name: 'Liechtenstein',
      code: 'LI',
    },
    {
      name: 'Lituanie',
      code: 'LT',
    },
    {
      name: 'Luxembourg',
      code: 'LU',
    },
    {
      name: 'Macao',
      code: 'MO',
    },
    {
      name: 'Macédoine du Nord',
      code: 'MK',
    },
    {
      name: 'Madagascar',
      code: 'MG',
    },
    {
      name: 'Malaisie',
      code: 'MY',
    },
    {
      name: 'Malawi',
      code: 'MW',
    },
    {
      name: 'Maldives',
      code: 'MV',
    },
    {
      name: 'Mali',
      code: 'ML',
    },
    {
      name: 'Malte',
      code: 'MT',
    },
    {
      name: 'Maroc',
      code: 'MA',
    },
    {
      name: 'Martinique',
      code: 'MQ',
    },
    {
      name: 'Mauritanie',
      code: 'MR',
    },
    {
      name: 'Mayotte',
      code: 'YT',
    },
    {
      name: 'Mexique',
      code: 'MX',
    },
    {
      name: 'Moldavie',
      code: 'MD',
    },
    {
      name: 'Monaco',
      code: 'MC',
    },
    {
      name: 'Mongolie',
      code: 'MN',
    },
    {
      name: 'Montserrat',
      code: 'MS',
    },
    {
      name: 'Monténégro',
      code: 'ME',
    },
    {
      name: 'Mozambique',
      code: 'MZ',
    },
    {
      name: 'Namibie',
      code: 'NA',
    },
    {
      name: 'Nauru',
      code: 'NR',
    },
    {
      name: 'Nicaragua',
      code: 'NI',
    },
    {
      name: 'Niger',
      code: 'NE',
    },
    {
      name: 'Nigeria',
      code: 'NG',
    },
    {
      name: 'Nioué',
      code: 'NU',
    },
    {
      name: 'Norvège',
      code: 'NO',
    },
    {
      name: 'Nouvelle-Calédonie',
      code: 'NC',
    },
    {
      name: 'Nouvelle-Zélande',
      code: 'NZ',
    },
    {
      name: 'Népal',
      code: 'NP',
    },
    {
      name: 'Oman',
      code: 'OM',
    },
    {
      name: 'Ouganda',
      code: 'UG',
    },
    {
      name: 'Ouzbékistan',
      code: 'UZ',
    },
    {
      name: 'Pakistan',
      code: 'PK',
    },
    {
      name: 'Palaos',
      code: 'PW',
    },
    {
      name: 'Panama',
      code: 'PA',
    },
    {
      name: 'Papouasie-Nouvelle Guinée',
      code: 'PG',
    },
    {
      name: 'Paraguay',
      code: 'PY',
    },
    {
      name: 'Pays-Bas',
      code: 'NL',
    },
    {
      name: 'Philippines',
      code: 'PH',
    },
    {
      name: 'Pologne',
      code: 'PL',
    },
    {
      name: 'Polynésie Française',
      code: 'PF',
    },
    {
      name: 'Porto Rico',
      code: 'PR',
    },
    {
      name: 'Portugal',
      code: 'PT',
    },
    {
      name: 'Pérou',
      code: 'PE',
    },
    {
      name: 'Qatar',
      code: 'QA',
    },
    {
      name: 'Roumanie',
      code: 'RO',
    },
    {
      name: 'Royaume-Uni',
      code: 'GB',
    },
    {
      name: 'Russie',
      code: 'RU',
    },
    {
      name: 'Rwanda',
      code: 'RW',
    },
    {
      name: 'République Dominicaine',
      code: 'DO',
    },
    {
      name: 'République tchèque',
      code: 'CZ',
    },
    {
      name: 'Sahara Occidental',
      code: 'EH',
    },
    {
      name: 'Saint-Barthélemy',
      code: 'BL',
    },
    {
      name: 'Saint-Christophe-et-Niévès',
      code: 'KN',
    },
    {
      name: 'Saint-Marin',
      code: 'SM',
    },
    {
      name: 'Saint-Martin',
      code: 'MF',
    },
    {
      name: 'Saint-Martin',
      code: 'SX',
    },
    {
      name: 'Saint-Pierre et Miquelon',
      code: 'PM',
    },
    {
      name: 'Saint-Vincent-et-les-Grenadines',
      code: 'VC',
    },
    {
      name: 'Sainte-Hélène',
      code: 'SH',
    },
    {
      name: 'Sainte-Lucie',
      code: 'LC',
    },
    {
      name: 'Salvador',
      code: 'SV',
    },
    {
      name: 'Samoa',
      code: 'WS',
    },
    {
      name: 'Samoa américaines',
      code: 'AS',
    },
    {
      name: 'Serbie',
      code: 'RS',
    },
    {
      name: 'Seychelles',
      code: 'SC',
    },
    {
      name: 'Sierra Leone',
      code: 'SL',
    },
    {
      name: 'Singapour',
      code: 'SG',
    },
    {
      name: 'Slovaquie',
      code: 'SK',
    },
    {
      name: 'Slovénie',
      code: 'SI',
    },
    {
      name: 'Somalie',
      code: 'SO',
    },
    {
      name: 'Soudan',
      code: 'SD',
    },
    {
      name: 'Sri Lanka',
      code: 'LK',
    },
    {
      name: 'Sud-Soudan',
      code: 'SS',
    },
    {
      name: 'Suisse',
      code: 'CH',
    },
    {
      name: 'Surinam',
      code: 'SR',
    },
    {
      name: 'Suède',
      code: 'SE',
    },
    {
      name: 'Svalbard et Jan Mayen',
      code: 'SJ',
    },
    {
      name: 'Swaziland',
      code: 'SZ',
    },
    {
      name: 'Syrie',
      code: 'SY',
    },
    {
      name: 'São Tomé-et-Príncipe',
      code: 'ST',
    },
    {
      name: 'Sénégal',
      code: 'SN',
    },
    {
      name: 'Tadjikistan',
      code: 'TJ',
    },
    {
      name: 'Tanzanie',
      code: 'TZ',
    },
    {
      name: 'Taïwan',
      code: 'TW',
    },
    {
      name: 'Tchad',
      code: 'TD',
    },
    {
      name: 'Terres australes françaises',
      code: 'TF',
    },
    {
      name: "Territoire britannique de l'océan Indien",
      code: 'IO',
    },
    {
      name: 'Territoire palestinien',
      code: 'PS',
    },
    {
      name: 'Thaïlande',
      code: 'TH',
    },
    {
      name: 'Timor Oriental',
      code: 'TL',
    },
    {
      name: 'Togo',
      code: 'TG',
    },
    {
      name: 'Tokelau',
      code: 'TK',
    },
    {
      name: 'Tonga',
      code: 'TO',
    },
    {
      name: 'Trinidad et Tobago',
      code: 'TT',
    },
    {
      name: 'Tunisie',
      code: 'TN',
    },
    {
      name: 'Turkménistan',
      code: 'TM',
    },
    {
      name: 'Turquie',
      code: 'TR',
    },
    {
      name: 'Tuvalu',
      code: 'TV',
    },
    {
      name: 'Ukraine',
      code: 'UA',
    },
    {
      name: 'Uruguay',
      code: 'UY',
    },
    {
      name: 'Vanuatu',
      code: 'VU',
    },
    {
      name: 'Vatican',
      code: 'VA',
    },
    {
      name: 'Vietnam',
      code: 'VN',
    },
    {
      name: 'Vénézuéla',
      code: 'VE',
    },
    {
      name: 'Wallis et Futuna',
      code: 'WF',
    },
    {
      name: 'Yémen',
      code: 'YE',
    },
    {
      name: 'Zambie',
      code: 'ZM',
    },
    {
      name: 'Zimbabwe',
      code: 'ZW',
    },
    {
      name: 'Égypte',
      code: 'EG',
    },
    {
      name: 'Émirats Arabes Unis',
      code: 'AE',
    },
    {
      name: 'Équateur',
      code: 'EC',
    },
    {
      name: 'Érythrée',
      code: 'ER',
    },
    {
      name: 'États fédérés de Micronésie',
      code: 'FM',
    },
    {
      name: 'États-Unis',
      code: 'US',
    },
    {
      name: 'Éthiopie',
      code: 'ET',
    },
    {
      name: 'Île Bouvet',
      code: 'BV',
    },
    {
      name: 'Île Christmas',
      code: 'CX',
    },
    {
      name: 'Île Heard et îles McDonald',
      code: 'HM',
    },
    {
      name: 'Île Maurice',
      code: 'MU',
    },
    {
      name: 'Île Norfolk',
      code: 'NF',
    },
    {
      name: 'Île de Man',
      code: 'IM',
    },
    {
      name: 'Îles Caïmans',
      code: 'KY',
    },
    {
      name: 'Îles Cocos',
      code: 'CC',
    },
    {
      name: 'Îles Cook',
      code: 'CK',
    },
    {
      name: 'Îles Féroé',
      code: 'FO',
    },
    {
      name: 'Îles Malouines',
      code: 'FK',
    },
    {
      name: 'Îles Mariannes du Nord',
      code: 'MP',
    },
    {
      name: 'Îles Marshall',
      code: 'MH',
    },
    {
      name: 'Îles Pitcairn',
      code: 'PN',
    },
    {
      name: 'Îles Salomon',
      code: 'SB',
    },
    {
      name: 'Îles Turques-et-Caïques',
      code: 'TC',
    },
    {
      name: 'Îles Vierges',
      code: 'VG',
    },
    {
      name: 'Îles Vierges des États-Unis',
      code: 'VI',
    },
    {
      name: 'Îles mineures éloignées des États-Unis',
      code: 'UM',
    },
    {
      name: 'Îles Åland',
      code: 'AX',
    },
  ],
  'it-it': [
    {
      name: 'Afghanistan',
      code: 'AF',
    },
    {
      name: 'Albania',
      code: 'AL',
    },
    {
      name: 'Algeria',
      code: 'DZ',
    },
    {
      name: 'Andorra',
      code: 'AD',
    },
    {
      name: 'Angola',
      code: 'AO',
    },
    {
      name: 'Anguilla',
      code: 'AI',
    },
    {
      name: 'Antartide',
      code: 'AQ',
    },
    {
      name: 'Antigua e Barbuda',
      code: 'AG',
    },
    {
      name: 'Arabia Saudita',
      code: 'SA',
    },
    {
      name: 'Argentina',
      code: 'AR',
    },
    {
      name: 'Armenia',
      code: 'AM',
    },
    {
      name: 'Aruba',
      code: 'AW',
    },
    {
      name: 'Australia',
      code: 'AU',
    },
    {
      name: 'Austria',
      code: 'AT',
    },
    {
      name: 'Azerbaigian',
      code: 'AZ',
    },
    {
      name: 'Bahamas',
      code: 'BS',
    },
    {
      name: 'Bahrain',
      code: 'BH',
    },
    {
      name: 'Bangladesh',
      code: 'BD',
    },
    {
      name: 'Barbados',
      code: 'BB',
    },
    {
      name: 'Belgio',
      code: 'BE',
    },
    {
      name: 'Belize',
      code: 'BZ',
    },
    {
      name: 'Benin',
      code: 'BJ',
    },
    {
      name: 'Bermuda',
      code: 'BM',
    },
    {
      name: 'Bhutan',
      code: 'BT',
    },
    {
      name: 'Bielorussia',
      code: 'BY',
    },
    {
      name: 'Birmania',
      code: 'MM',
    },
    {
      name: 'Bolivia',
      code: 'BO',
    },
    {
      name: 'Bosnia ed Erzegovina',
      code: 'BA',
    },
    {
      name: 'Botswana',
      code: 'BW',
    },
    {
      name: 'Brasile',
      code: 'BR',
    },
    {
      name: 'Brunei',
      code: 'BN',
    },
    {
      name: 'Bulgaria',
      code: 'BG',
    },
    {
      name: 'Burkina Faso',
      code: 'BF',
    },
    {
      name: 'Burundi',
      code: 'BI',
    },
    {
      name: 'Cambogia',
      code: 'KH',
    },
    {
      name: 'Camerun',
      code: 'CM',
    },
    {
      name: 'Canada',
      code: 'CA',
    },
    {
      name: 'Capo Verde',
      code: 'CV',
    },
    {
      name: 'Caraibi Olandesi',
      code: 'BQ',
    },
    {
      name: 'Cechia',
      code: 'CZ',
    },
    {
      name: 'Ciad',
      code: 'TD',
    },
    {
      name: 'Cile',
      code: 'CL',
    },
    {
      name: 'Cina',
      code: 'CN',
    },
    {
      name: 'Cipro',
      code: 'CY',
    },
    {
      name: 'Città del Vaticano',
      code: 'VA',
    },
    {
      name: 'Colombia',
      code: 'CO',
    },
    {
      name: 'Comore',
      code: 'KM',
    },
    {
      name: 'Congo',
      code: 'CG',
    },
    {
      name: 'Corea del Nord',
      code: 'KP',
    },
    {
      name: 'Corea del Sud',
      code: 'KR',
    },
    {
      name: 'Cossovo',
      code: 'XK',
    },
    {
      name: 'Costa Rica',
      code: 'CR',
    },
    {
      name: "Costa d'Avorio",
      code: 'CI',
    },
    {
      name: 'Croazia',
      code: 'HR',
    },
    {
      name: 'Cuba',
      code: 'CU',
    },
    {
      name: 'Curacao',
      code: 'CW',
    },
    {
      name: 'Danimarca',
      code: 'DK',
    },
    {
      name: 'Dominica',
      code: 'DM',
    },
    {
      name: 'Ecuador',
      code: 'EC',
    },
    {
      name: 'Egitto',
      code: 'EG',
    },
    {
      name: 'El Salvador',
      code: 'SV',
    },
    {
      name: 'Emirati Arabi Uniti',
      code: 'AE',
    },
    {
      name: 'Eritrea',
      code: 'ER',
    },
    {
      name: 'Estonia',
      code: 'EE',
    },
    {
      name: 'Etiopia',
      code: 'ET',
    },
    {
      name: 'Figi',
      code: 'FJ',
    },
    {
      name: 'Filippine',
      code: 'PH',
    },
    {
      name: 'Finlandia',
      code: 'FI',
    },
    {
      name: 'Francia',
      code: 'FR',
    },
    {
      name: 'Gabon',
      code: 'GA',
    },
    {
      name: 'Gambia',
      code: 'GM',
    },
    {
      name: 'Georgia',
      code: 'GE',
    },
    {
      name: 'Georgia del Sud e isole Sandwich meridionali',
      code: 'GS',
    },
    {
      name: 'Germania',
      code: 'DE',
    },
    {
      name: 'Ghana',
      code: 'GH',
    },
    {
      name: 'Giamaica',
      code: 'JM',
    },
    {
      name: 'Giappone',
      code: 'JP',
    },
    {
      name: 'Gibilterra',
      code: 'GI',
    },
    {
      name: 'Gibuti',
      code: 'DJ',
    },
    {
      name: 'Giordania',
      code: 'JO',
    },
    {
      name: 'Grecia',
      code: 'GR',
    },
    {
      name: 'Grenada',
      code: 'GD',
    },
    {
      name: 'Groenlandia',
      code: 'GL',
    },
    {
      name: 'Guadalupa',
      code: 'GP',
    },
    {
      name: 'Guam',
      code: 'GU',
    },
    {
      name: 'Guatemala',
      code: 'GT',
    },
    {
      name: 'Guernsey',
      code: 'GG',
    },
    {
      name: 'Guiana Francese',
      code: 'GF',
    },
    {
      name: 'Guinea',
      code: 'GN',
    },
    {
      name: 'Guinea Equatoriale',
      code: 'GQ',
    },
    {
      name: 'Guinea-Bissau',
      code: 'GW',
    },
    {
      name: 'Guyana',
      code: 'GY',
    },
    {
      name: 'Haiti',
      code: 'HT',
    },
    {
      name: 'Honduras',
      code: 'HN',
    },
    {
      name: 'Hong Kong',
      code: 'HK',
    },
    {
      name: 'India',
      code: 'IN',
    },
    {
      name: 'Indonesia',
      code: 'ID',
    },
    {
      name: 'Iran',
      code: 'IR',
    },
    {
      name: 'Iraq',
      code: 'IQ',
    },
    {
      name: 'Irlanda',
      code: 'IE',
    },
    {
      name: 'Islanda',
      code: 'IS',
    },
    {
      name: 'Isola Bouvet',
      code: 'BV',
    },
    {
      name: 'Isola Norfolk',
      code: 'NF',
    },
    {
      name: 'Isola di Christmas',
      code: 'CX',
    },
    {
      name: 'Isola di Man',
      code: 'IM',
    },
    {
      name: 'Isole Cayman',
      code: 'KY',
    },
    {
      name: 'Isole Cocos',
      code: 'CC',
    },
    {
      name: 'Isole Cook',
      code: 'CK',
    },
    {
      name: 'Isole Falkland',
      code: 'FK',
    },
    {
      name: 'Isole Faroe',
      code: 'FO',
    },
    {
      name: 'Isole Heard e McDonald',
      code: 'HM',
    },
    {
      name: 'Isole Marianne Settentrionali',
      code: 'MP',
    },
    {
      name: 'Isole Marshall',
      code: 'MH',
    },
    {
      name: 'Isole Salomone',
      code: 'SB',
    },
    {
      name: 'Isole Turks e Caicos',
      code: 'TC',
    },
    {
      name: 'Isole Vergini Americane',
      code: 'VI',
    },
    {
      name: 'Isole Vergini Britanniche',
      code: 'VG',
    },
    {
      name: 'Isole minori esterne degli Stati Uniti',
      code: 'UM',
    },
    {
      name: 'Isole Åland',
      code: 'AX',
    },
    {
      name: 'Israele',
      code: 'IL',
    },
    {
      name: 'Italia',
      code: 'IT',
    },
    {
      name: 'Jersey',
      code: 'JE',
    },
    {
      name: 'Kazakistan',
      code: 'KZ',
    },
    {
      name: 'Kenya',
      code: 'KE',
    },
    {
      name: 'Kirghizistan',
      code: 'KG',
    },
    {
      name: 'Kiribati',
      code: 'KI',
    },
    {
      name: 'Kuwait',
      code: 'KW',
    },
    {
      name: 'Laos',
      code: 'LA',
    },
    {
      name: 'Lesotho',
      code: 'LS',
    },
    {
      name: 'Lettonia',
      code: 'LV',
    },
    {
      name: 'Libano',
      code: 'LB',
    },
    {
      name: 'Liberia',
      code: 'LR',
    },
    {
      name: 'Libia',
      code: 'LY',
    },
    {
      name: 'Liechtenstein',
      code: 'LI',
    },
    {
      name: 'Lituania',
      code: 'LT',
    },
    {
      name: 'Lussemburgo',
      code: 'LU',
    },
    {
      name: 'Macao',
      code: 'MO',
    },
    {
      name: 'Macedonia del Nord',
      code: 'MK',
    },
    {
      name: 'Madagascar',
      code: 'MG',
    },
    {
      name: 'Malawi',
      code: 'MW',
    },
    {
      name: 'Maldive',
      code: 'MV',
    },
    {
      name: 'Malesia',
      code: 'MY',
    },
    {
      name: 'Mali',
      code: 'ML',
    },
    {
      name: 'Malta',
      code: 'MT',
    },
    {
      name: 'Marocco',
      code: 'MA',
    },
    {
      name: 'Martinica',
      code: 'MQ',
    },
    {
      name: 'Mauritania',
      code: 'MR',
    },
    {
      name: 'Mauritius',
      code: 'MU',
    },
    {
      name: 'Mayotte',
      code: 'YT',
    },
    {
      name: 'Messico',
      code: 'MX',
    },
    {
      name: 'Micronesia',
      code: 'FM',
    },
    {
      name: 'Moldavia',
      code: 'MD',
    },
    {
      name: 'Monaco',
      code: 'MC',
    },
    {
      name: 'Mongolia',
      code: 'MN',
    },
    {
      name: 'Montenegro',
      code: 'ME',
    },
    {
      name: 'Montserrat',
      code: 'MS',
    },
    {
      name: 'Mozambico',
      code: 'MZ',
    },
    {
      name: 'Namibia',
      code: 'NA',
    },
    {
      name: 'Nauru',
      code: 'NR',
    },
    {
      name: 'Nepal',
      code: 'NP',
    },
    {
      name: 'Nicaragua',
      code: 'NI',
    },
    {
      name: 'Niger',
      code: 'NE',
    },
    {
      name: 'Nigeria',
      code: 'NG',
    },
    {
      name: 'Niue',
      code: 'NU',
    },
    {
      name: 'Norvegia',
      code: 'NO',
    },
    {
      name: 'Nuova Caledonia',
      code: 'NC',
    },
    {
      name: 'Nuova Zelanda',
      code: 'NZ',
    },
    {
      name: 'Oman',
      code: 'OM',
    },
    {
      name: 'Paesi Bassi',
      code: 'NL',
    },
    {
      name: 'Pakistan',
      code: 'PK',
    },
    {
      name: 'Palau',
      code: 'PW',
    },
    {
      name: 'Palestina',
      code: 'PS',
    },
    {
      name: 'Panamá',
      code: 'PA',
    },
    {
      name: 'Papua Nuova Guinea',
      code: 'PG',
    },
    {
      name: 'Paraguay',
      code: 'PY',
    },
    {
      name: 'Perù',
      code: 'PE',
    },
    {
      name: 'Pitcairn',
      code: 'PN',
    },
    {
      name: 'Polinesia francese',
      code: 'PF',
    },
    {
      name: 'Polonia',
      code: 'PL',
    },
    {
      name: 'Portogallo',
      code: 'PT',
    },
    {
      name: 'Portorico',
      code: 'PR',
    },
    {
      name: 'Qatar',
      code: 'QA',
    },
    {
      name: 'Regno Unito',
      code: 'GB',
    },
    {
      name: 'Repubblica Centrafricana',
      code: 'CF',
    },
    {
      name: 'Repubblica Dominicana',
      code: 'DO',
    },
    {
      name: 'Romania',
      code: 'RO',
    },
    {
      name: 'Ruanda',
      code: 'RW',
    },
    {
      name: 'Russia',
      code: 'RU',
    },
    {
      name: 'Réunion',
      code: 'RE',
    },
    {
      name: 'Sahara Occidentale',
      code: 'EH',
    },
    {
      name: 'Saint Kitts e Nevis',
      code: 'KN',
    },
    {
      name: 'Saint Martin',
      code: 'MF',
    },
    {
      name: 'Saint Pierre e Miquelon',
      code: 'PM',
    },
    {
      name: 'Saint Vincent e Grenadine',
      code: 'VC',
    },
    {
      name: 'Samoa',
      code: 'WS',
    },
    {
      name: 'Samoa Americane',
      code: 'AS',
    },
    {
      name: 'San Bartolomeo',
      code: 'BL',
    },
    {
      name: 'San Marino',
      code: 'SM',
    },
    {
      name: 'Santa Lucia',
      code: 'LC',
    },
    {
      name: 'Sant’Elena',
      code: 'SH',
    },
    {
      name: 'Senegal',
      code: 'SN',
    },
    {
      name: 'Serbia',
      code: 'RS',
    },
    {
      name: 'Seychelles',
      code: 'SC',
    },
    {
      name: 'Sierra Leone',
      code: 'SL',
    },
    {
      name: 'Singapore',
      code: 'SG',
    },
    {
      name: 'Sint Maarten',
      code: 'SX',
    },
    {
      name: 'Siria',
      code: 'SY',
    },
    {
      name: 'Slovacchia',
      code: 'SK',
    },
    {
      name: 'Slovenia',
      code: 'SI',
    },
    {
      name: 'Somalia',
      code: 'SO',
    },
    {
      name: 'Spagna',
      code: 'ES',
    },
    {
      name: 'Sri Lanka',
      code: 'LK',
    },
    {
      name: 'Stati Uniti',
      code: 'US',
    },
    {
      name: 'Sudafrica',
      code: 'ZA',
    },
    {
      name: 'Sudan',
      code: 'SD',
    },
    {
      name: 'Sudan del Sud',
      code: 'SS',
    },
    {
      name: 'Suriname',
      code: 'SR',
    },
    {
      name: 'Svalbard e Jan Mayen',
      code: 'SJ',
    },
    {
      name: 'Svezia',
      code: 'SE',
    },
    {
      name: 'Svizzera',
      code: 'CH',
    },
    {
      name: 'Swaziland',
      code: 'SZ',
    },
    {
      name: 'São Tomé e Príncipe',
      code: 'ST',
    },
    {
      name: 'Tagikistan',
      code: 'TJ',
    },
    {
      name: 'Taiwan',
      code: 'TW',
    },
    {
      name: 'Tanzania',
      code: 'TZ',
    },
    {
      name: 'Terre australi e antartiche francesi',
      code: 'TF',
    },
    {
      name: 'Territorio Britannico dell’Oceano Indiano',
      code: 'IO',
    },
    {
      name: 'Thailandia',
      code: 'TH',
    },
    {
      name: 'Timor Est',
      code: 'TL',
    },
    {
      name: 'Togo',
      code: 'TG',
    },
    {
      name: 'Tokelau',
      code: 'TK',
    },
    {
      name: 'Tonga',
      code: 'TO',
    },
    {
      name: 'Trinidad e Tobago',
      code: 'TT',
    },
    {
      name: 'Tunisia',
      code: 'TN',
    },
    {
      name: 'Turchia',
      code: 'TR',
    },
    {
      name: 'Turkmenistan',
      code: 'TM',
    },
    {
      name: 'Tuvalu',
      code: 'TV',
    },
    {
      name: 'Ucraina',
      code: 'UA',
    },
    {
      name: 'Uganda',
      code: 'UG',
    },
    {
      name: 'Ungheria',
      code: 'HU',
    },
    {
      name: 'Uruguay',
      code: 'UY',
    },
    {
      name: 'Uzbekistan',
      code: 'UZ',
    },
    {
      name: 'Vanuatu',
      code: 'VU',
    },
    {
      name: 'Venezuela',
      code: 'VE',
    },
    {
      name: 'Vietnam',
      code: 'VN',
    },
    {
      name: 'Wallis e Futuna',
      code: 'WF',
    },
    {
      name: 'Yemen',
      code: 'YE',
    },
    {
      name: 'Zaire',
      code: 'CD',
    },
    {
      name: 'Zambia',
      code: 'ZM',
    },
    {
      name: 'Zimbabwe',
      code: 'ZW',
    },
  ],
  'ja-jp': [
    {
      name: 'アイスランド共和国',
      code: 'IS',
    },
    {
      name: 'アイルランド',
      code: 'IE',
    },
    {
      name: 'アゼルバイジャン共和国',
      code: 'AZ',
    },
    {
      name: 'アフガニスタン・イスラム国',
      code: 'AF',
    },
    {
      name: 'アメリカ合衆国',
      code: 'US',
    },
    {
      name: 'アラブ首長国連邦',
      code: 'AE',
    },
    {
      name: 'アルジェリア民主人民共和国',
      code: 'DZ',
    },
    {
      name: 'アルゼンチン共和国',
      code: 'AR',
    },
    {
      name: 'アルバニア共和国',
      code: 'AL',
    },
    {
      name: 'アルバ島',
      code: 'AW',
    },
    {
      name: 'アルメニア共和国',
      code: 'AM',
    },
    {
      name: 'アンギラ',
      code: 'AI',
    },
    {
      name: 'アンゴラ共和国',
      code: 'AO',
    },
    {
      name: 'アンティグアバブーダ',
      code: 'AG',
    },
    {
      name: 'アンドラ',
      code: 'AD',
    },
    {
      name: 'イエメン共和国',
      code: 'YE',
    },
    {
      name: 'イギリス',
      code: 'GB',
    },
    {
      name: 'イスラエル国',
      code: 'IL',
    },
    {
      name: 'イタリア共和国',
      code: 'IT',
    },
    {
      name: 'イラク共和国',
      code: 'IQ',
    },
    {
      name: 'イラン・イスラム共和国',
      code: 'IR',
    },
    {
      name: 'インド',
      code: 'IN',
    },
    {
      name: 'インドネシア共和国',
      code: 'ID',
    },
    {
      name: 'ウガンダ共和国',
      code: 'UG',
    },
    {
      name: 'ウクライナ共和国',
      code: 'UA',
    },
    {
      name: 'ウズベキスタン共和国',
      code: 'UZ',
    },
    {
      name: 'ウルグアイ共和国',
      code: 'UY',
    },
    {
      name: 'エクアドル共和国',
      code: 'EC',
    },
    {
      name: 'エジプト・アラブ共和国',
      code: 'EG',
    },
    {
      name: 'エストニア共和国',
      code: 'EE',
    },
    {
      name: 'エチオピア',
      code: 'ET',
    },
    {
      name: 'エリトリア',
      code: 'ER',
    },
    {
      name: 'エルサルバドル',
      code: 'SV',
    },
    {
      name: 'オマーン国',
      code: 'OM',
    },
    {
      name: 'オランダ王国',
      code: 'NL',
    },
    {
      name: 'オーストラリア',
      code: 'AU',
    },
    {
      name: 'オーストリア共和国',
      code: 'AT',
    },
    {
      name: 'オーランド諸島',
      code: 'AX',
    },
    {
      name: 'カザフスタン共和国',
      code: 'KZ',
    },
    {
      name: 'カタール',
      code: 'QA',
    },
    {
      name: 'カナダ',
      code: 'CA',
    },
    {
      name: 'カメルーン共和国',
      code: 'CM',
    },
    {
      name: 'カンボジア国',
      code: 'KH',
    },
    {
      name: 'カーボベルデ',
      code: 'CV',
    },
    {
      name: 'ガイアナ',
      code: 'GY',
    },
    {
      name: 'ガボン共和国',
      code: 'GA',
    },
    {
      name: 'ガンビア共和国',
      code: 'GM',
    },
    {
      name: 'ガーナ共和国',
      code: 'GH',
    },
    {
      name: 'ガーンジー',
      code: 'GG',
    },
    {
      name: 'キプロス共和国',
      code: 'CY',
    },
    {
      name: 'キュラソー',
      code: 'CW',
    },
    {
      name: 'キューバ共和国',
      code: 'CU',
    },
    {
      name: 'キリバス共和国',
      code: 'KI',
    },
    {
      name: 'キルギス共和国',
      code: 'KG',
    },
    {
      name: 'ギニアビサウ共和国',
      code: 'GW',
    },
    {
      name: 'ギニア共和国',
      code: 'GN',
    },
    {
      name: 'ギリシャ共和国',
      code: 'GR',
    },
    {
      name: 'クウェート',
      code: 'KW',
    },
    {
      name: 'クック諸島',
      code: 'CK',
    },
    {
      name: 'クリスマス島',
      code: 'CX',
    },
    {
      name: 'クロアチア共和国',
      code: 'HR',
    },
    {
      name: 'グアテマラ',
      code: 'GT',
    },
    {
      name: 'グアドループ',
      code: 'GP',
    },
    {
      name: 'グアム',
      code: 'GU',
    },
    {
      name: 'グリーンランド',
      code: 'GL',
    },
    {
      name: 'グルジア共和国',
      code: 'GE',
    },
    {
      name: 'グレナダ',
      code: 'GD',
    },
    {
      name: 'ケイマン諸島',
      code: 'KY',
    },
    {
      name: 'ケニア共和国',
      code: 'KE',
    },
    {
      name: 'ココス[キーリング]諸島',
      code: 'CC',
    },
    {
      name: 'コスタリカ',
      code: 'CR',
    },
    {
      name: 'コソボ',
      code: 'XK',
    },
    {
      name: 'コモロ・イスラム連邦共和国',
      code: 'KM',
    },
    {
      name: 'コロンビア共和国',
      code: 'CO',
    },
    {
      name: 'コンゴ共和国',
      code: 'CG',
    },
    {
      name: 'コンゴ民主共和国',
      code: 'CD',
    },
    {
      name: 'サウジアラビア王国',
      code: 'SA',
    },
    {
      name: 'サントメ プリンシペ',
      code: 'ST',
    },
    {
      name: 'サンピエール島・ミクロン島',
      code: 'PM',
    },
    {
      name: 'サンマリノ共和国',
      code: 'SM',
    },
    {
      name: 'サン・バルテルミー',
      code: 'BL',
    },
    {
      name: 'ザンビア共和国',
      code: 'ZM',
    },
    {
      name: 'シエラレオネ',
      code: 'SL',
    },
    {
      name: 'シリア・アラブ共和国',
      code: 'SY',
    },
    {
      name: 'シンガポール',
      code: 'SG',
    },
    {
      name: 'シント・マールテン',
      code: 'SX',
    },
    {
      name: 'ジブチ',
      code: 'DJ',
    },
    {
      name: 'ジブラルタル',
      code: 'GI',
    },
    {
      name: 'ジャマイカ',
      code: 'JM',
    },
    {
      name: 'ジャージー',
      code: 'JE',
    },
    {
      name: 'ジンバブエ共和国',
      code: 'ZW',
    },
    {
      name: 'スイス連邦',
      code: 'CH',
    },
    {
      name: 'スウェーデン王国',
      code: 'SE',
    },
    {
      name: 'スバールバル諸島・ヤンマイエン島',
      code: 'SJ',
    },
    {
      name: 'スペイン',
      code: 'ES',
    },
    {
      name: 'スリナム共和国',
      code: 'SR',
    },
    {
      name: 'スリランカ民主社会主義共和国',
      code: 'LK',
    },
    {
      name: 'スロバキア (スロバキア共和国)',
      code: 'SK',
    },
    {
      name: 'スロベニア',
      code: 'SI',
    },
    {
      name: 'スワジランド王国',
      code: 'SZ',
    },
    {
      name: 'スーダン共和国',
      code: 'SD',
    },
    {
      name: 'セネガル共和国',
      code: 'SN',
    },
    {
      name: 'セルビア',
      code: 'RS',
    },
    {
      name: 'セントクリストファー・ネビス',
      code: 'KN',
    },
    {
      name: 'セントビンセント・グレナディーン諸島',
      code: 'VC',
    },
    {
      name: 'セントヘレナ',
      code: 'SH',
    },
    {
      name: 'セントルシア',
      code: 'LC',
    },
    {
      name: 'セーシェル',
      code: 'SC',
    },
    {
      name: 'ソマリア',
      code: 'SO',
    },
    {
      name: 'ソロモン諸島',
      code: 'SB',
    },
    {
      name: 'タイ王国',
      code: 'TH',
    },
    {
      name: 'タジキスタン共和国',
      code: 'TJ',
    },
    {
      name: 'タンザニア連合共和国',
      code: 'TZ',
    },
    {
      name: 'タークス諸島・カイコス諸島',
      code: 'TC',
    },
    {
      name: 'チェコ共和国',
      code: 'CZ',
    },
    {
      name: 'チャド共和国',
      code: 'TD',
    },
    {
      name: 'チュニジア共和国',
      code: 'TN',
    },
    {
      name: 'チリ共和国',
      code: 'CL',
    },
    {
      name: 'ツバル',
      code: 'TV',
    },
    {
      name: 'デンマーク王国',
      code: 'DK',
    },
    {
      name: 'トケラウ諸島',
      code: 'TK',
    },
    {
      name: 'トリニダード・トバゴ共和国',
      code: 'TT',
    },
    {
      name: 'トルクメニスタン',
      code: 'TM',
    },
    {
      name: 'トルコ共和国',
      code: 'TR',
    },
    {
      name: 'トンガ王国',
      code: 'TO',
    },
    {
      name: 'トーゴ共和国',
      code: 'TG',
    },
    {
      name: 'ドイツ連邦共和国',
      code: 'DE',
    },
    {
      name: 'ドミニカ共和国',
      code: 'DO',
    },
    {
      name: 'ドミニカ国',
      code: 'DM',
    },
    {
      name: 'ナイジェリア連邦共和国',
      code: 'NG',
    },
    {
      name: 'ナウル',
      code: 'NR',
    },
    {
      name: 'ナミビア共和国',
      code: 'NA',
    },
    {
      name: 'ニウエ島',
      code: 'NU',
    },
    {
      name: 'ニカラグア共和国',
      code: 'NI',
    },
    {
      name: 'ニジェール共和国',
      code: 'NE',
    },
    {
      name: 'ニューカレドニア',
      code: 'NC',
    },
    {
      name: 'ニュージーランド',
      code: 'NZ',
    },
    {
      name: 'ネパール王国',
      code: 'NP',
    },
    {
      name: 'ノルウェー王国',
      code: 'NO',
    },
    {
      name: 'ノーフォーク島',
      code: 'NF',
    },
    {
      name: 'ハイチ共和国',
      code: 'HT',
    },
    {
      name: 'ハンガリー共和国',
      code: 'HU',
    },
    {
      name: 'ハード島・マクドナルド諸島',
      code: 'HM',
    },
    {
      name: 'バヌアツ共和国',
      code: 'VU',
    },
    {
      name: 'バハマ国',
      code: 'BS',
    },
    {
      name: 'バミューダ諸島',
      code: 'BM',
    },
    {
      name: 'バルバドス',
      code: 'BB',
    },
    {
      name: 'バングラディッシュ人民共和国',
      code: 'BD',
    },
    {
      name: 'バーレーン',
      code: 'BH',
    },
    {
      name: 'パキスタン・イスラム共和国',
      code: 'PK',
    },
    {
      name: 'パナマ',
      code: 'PA',
    },
    {
      name: 'パプア・ニューギニア',
      code: 'PG',
    },
    {
      name: 'パラオ',
      code: 'PW',
    },
    {
      name: 'パラグアイ共和国',
      code: 'PY',
    },
    {
      name: 'パレスチナ',
      code: 'PS',
    },
    {
      name: 'ピトケアン島',
      code: 'PN',
    },
    {
      name: 'フィジー',
      code: 'FJ',
    },
    {
      name: 'フィリピン共和国',
      code: 'PH',
    },
    {
      name: 'フィンランド共和国',
      code: 'FI',
    },
    {
      name: 'フェロー諸島',
      code: 'FO',
    },
    {
      name: 'フォークランド諸島',
      code: 'FK',
    },
    {
      name: 'フランス共和国',
      code: 'FR',
    },
    {
      name: 'ブラジル連邦共和国',
      code: 'BR',
    },
    {
      name: 'ブルガリア共和国',
      code: 'BG',
    },
    {
      name: 'ブルキナファソ',
      code: 'BF',
    },
    {
      name: 'ブルネイ',
      code: 'BN',
    },
    {
      name: 'ブルンジ共和国',
      code: 'BI',
    },
    {
      name: 'ブータン王国',
      code: 'BT',
    },
    {
      name: 'ブーベ島',
      code: 'BV',
    },
    {
      name: 'プエルトリコ',
      code: 'PR',
    },
    {
      name: 'ベトナム',
      code: 'VN',
    },
    {
      name: 'ベニン共和国',
      code: 'BJ',
    },
    {
      name: 'ベネズエラ共和国',
      code: 'VE',
    },
    {
      name: 'ベラルーシ共和国',
      code: 'BY',
    },
    {
      name: 'ベリーズ',
      code: 'BZ',
    },
    {
      name: 'ベルギー王国',
      code: 'BE',
    },
    {
      name: 'ペルー共和国',
      code: 'PE',
    },
    {
      name: 'ホンジュラス共和国',
      code: 'HN',
    },
    {
      name: 'ボスニア・ヘルツェゴビナ共和国',
      code: 'BA',
    },
    {
      name: 'ボツワナ共和国',
      code: 'BW',
    },
    {
      name: 'ボネール、シント・ユースタティウスおよびサバ',
      code: 'BQ',
    },
    {
      name: 'ボリビア共和国',
      code: 'BO',
    },
    {
      name: 'ポルトガル共和国',
      code: 'PT',
    },
    {
      name: 'ポーランド共和国',
      code: 'PL',
    },
    {
      name: 'マカオ',
      code: 'MO',
    },
    {
      name: 'マケドニア旧ユーゴスラビア共和国',
      code: 'MK',
    },
    {
      name: 'マダガスカル共和国',
      code: 'MG',
    },
    {
      name: 'マヨット島',
      code: 'YT',
    },
    {
      name: 'マラウイ共和国',
      code: 'MW',
    },
    {
      name: 'マリ共和国',
      code: 'ML',
    },
    {
      name: 'マルタ島',
      code: 'MT',
    },
    {
      name: 'マルティニーク島',
      code: 'MQ',
    },
    {
      name: 'マレーシア',
      code: 'MY',
    },
    {
      name: 'マン島',
      code: 'IM',
    },
    {
      name: 'マーシャル諸島共和国',
      code: 'MH',
    },
    {
      name: 'ミクロネシア連邦',
      code: 'FM',
    },
    {
      name: 'ミャンマー連邦',
      code: 'MM',
    },
    {
      name: 'メキシコ合衆国',
      code: 'MX',
    },
    {
      name: 'モザンビーク共和国',
      code: 'MZ',
    },
    {
      name: 'モナコ',
      code: 'MC',
    },
    {
      name: 'モルディブ',
      code: 'MV',
    },
    {
      name: 'モルドバ共和国',
      code: 'MD',
    },
    {
      name: 'モロッコ王国',
      code: 'MA',
    },
    {
      name: 'モンゴル国',
      code: 'MN',
    },
    {
      name: 'モンテネグロ',
      code: 'ME',
    },
    {
      name: 'モントセラト島',
      code: 'MS',
    },
    {
      name: 'モーリシャス',
      code: 'MU',
    },
    {
      name: 'モーリタニア・イスラム共和国',
      code: 'MR',
    },
    {
      name: 'ヨルダン・ハシミテ王国',
      code: 'JO',
    },
    {
      name: 'ラオス',
      code: 'LA',
    },
    {
      name: 'ラトビア共和国',
      code: 'LV',
    },
    {
      name: 'リトアニア共和国',
      code: 'LT',
    },
    {
      name: 'リヒテンシュタイン公国',
      code: 'LI',
    },
    {
      name: 'リベリア共和国',
      code: 'LR',
    },
    {
      name: 'ルクセンブルグ',
      code: 'LU',
    },
    {
      name: 'ルワンダ共和国',
      code: 'RW',
    },
    {
      name: 'ルーマニア',
      code: 'RO',
    },
    {
      name: 'レソト王国',
      code: 'LS',
    },
    {
      name: 'レバノン共和国',
      code: 'LB',
    },
    {
      name: 'レユニオン島',
      code: 'RE',
    },
    {
      name: 'ロシア',
      code: 'RU',
    },
    {
      name: 'ローマ法王庁 (バチカン市国)',
      code: 'VA',
    },
    {
      name: 'ワリー エ フトゥーナ諸島',
      code: 'WF',
    },
    {
      name: '中央アフリカ共和国',
      code: 'CF',
    },
    {
      name: '中華人民共和国',
      code: 'CN',
    },
    {
      name: '仏領ギアナ',
      code: 'GF',
    },
    {
      name: '仏領サン・マルタン',
      code: 'MF',
    },
    {
      name: '仏領ポリネシア',
      code: 'PF',
    },
    {
      name: '仏領極南諸島',
      code: 'TF',
    },
    {
      name: '北マリアナ諸島',
      code: 'MP',
    },
    {
      name: '南アフリカ共和国',
      code: 'ZA',
    },
    {
      name: '南ジョージア島・南サンドイッチ諸島',
      code: 'GS',
    },
    {
      name: '南スーダン',
      code: 'SS',
    },
    {
      name: '南極',
      code: 'AQ',
    },
    {
      name: '台灣',
      code: 'TW',
    },
    {
      name: '大韓民国',
      code: 'KR',
    },
    {
      name: '日本',
      code: 'JP',
    },
    {
      name: '東ティモール共和国',
      code: 'TL',
    },
    {
      name: '社会主義人民リビア・アラブ国',
      code: 'LY',
    },
    {
      name: '米領サモア',
      code: 'AS',
    },
    {
      name: '米領ヴァージン諸島',
      code: 'VI',
    },
    {
      name: '米領太平洋諸島',
      code: 'UM',
    },
    {
      name: '英領インド洋地域',
      code: 'IO',
    },
    {
      name: '英領ヴァージン諸島',
      code: 'VG',
    },
    {
      name: '西サハラ',
      code: 'EH',
    },
    {
      name: '西サモア',
      code: 'WS',
    },
    {
      name: '象牙海岸',
      code: 'CI',
    },
    {
      name: '赤道ギニア共和国',
      code: 'GQ',
    },
    {
      name: '韓国、朝鮮民主主義人民共和国',
      code: 'KP',
    },
    {
      name: '香港',
      code: 'HK',
    },
  ],
  'ko-kr': [
    {
      name: '가나',
      code: 'GH',
    },
    {
      name: '가봉',
      code: 'GA',
    },
    {
      name: '가이아나',
      code: 'GY',
    },
    {
      name: '감비아',
      code: 'GM',
    },
    {
      name: '건지',
      code: 'GG',
    },
    {
      name: '과들루프',
      code: 'GP',
    },
    {
      name: '과테말라',
      code: 'GT',
    },
    {
      name: '괌',
      code: 'GU',
    },
    {
      name: '그레나다',
      code: 'GD',
    },
    {
      name: '그리스',
      code: 'GR',
    },
    {
      name: '그린란드',
      code: 'GL',
    },
    {
      name: '기네비소',
      code: 'GW',
    },
    {
      name: '기니',
      code: 'GN',
    },
    {
      name: '나미비아',
      code: 'NA',
    },
    {
      name: '나우루',
      code: 'NR',
    },
    {
      name: '나이지리아',
      code: 'NG',
    },
    {
      name: '남극 대륙',
      code: 'AQ',
    },
    {
      name: '남수단',
      code: 'SS',
    },
    {
      name: '남아프리카 공화국',
      code: 'ZA',
    },
    {
      name: '네덜란드',
      code: 'NL',
    },
    {
      name: '네덜란드령 카리브',
      code: 'BQ',
    },
    {
      name: '네팔',
      code: 'NP',
    },
    {
      name: '노르웨이',
      code: 'NO',
    },
    {
      name: '노퍽섬',
      code: 'NF',
    },
    {
      name: '뉴질랜드',
      code: 'NZ',
    },
    {
      name: '뉴칼레도니아',
      code: 'NC',
    },
    {
      name: '니우에',
      code: 'NU',
    },
    {
      name: '니제르',
      code: 'NE',
    },
    {
      name: '니카라과',
      code: 'NI',
    },
    {
      name: '대만',
      code: 'TW',
    },
    {
      name: '대한민국',
      code: 'KR',
    },
    {
      name: '덴마크',
      code: 'DK',
    },
    {
      name: '도미니카 공화국',
      code: 'DO',
    },
    {
      name: '도미니카 연방',
      code: 'DM',
    },
    {
      name: '독일',
      code: 'DE',
    },
    {
      name: '동티모르',
      code: 'TL',
    },
    {
      name: '라오스',
      code: 'LA',
    },
    {
      name: '라이베리아',
      code: 'LR',
    },
    {
      name: '라트비아',
      code: 'LV',
    },
    {
      name: '러시아',
      code: 'RU',
    },
    {
      name: '레바논',
      code: 'LB',
    },
    {
      name: '레소토',
      code: 'LS',
    },
    {
      name: '루마니아',
      code: 'RO',
    },
    {
      name: '룩셈부르크',
      code: 'LU',
    },
    {
      name: '르완다',
      code: 'RW',
    },
    {
      name: '리비아',
      code: 'LY',
    },
    {
      name: '리유니온',
      code: 'RE',
    },
    {
      name: '리투아니아',
      code: 'LT',
    },
    {
      name: '리히텐슈타인',
      code: 'LI',
    },
    {
      name: '마다카스카르',
      code: 'MG',
    },
    {
      name: '마샬 군도',
      code: 'MH',
    },
    {
      name: '마요티',
      code: 'YT',
    },
    {
      name: '마카오',
      code: 'MO',
    },
    {
      name: '마케도니아 공화국',
      code: 'MK',
    },
    {
      name: '말라위',
      code: 'MW',
    },
    {
      name: '말레이지아',
      code: 'MY',
    },
    {
      name: '말리',
      code: 'ML',
    },
    {
      name: '말티니크',
      code: 'MQ',
    },
    {
      name: '맨섬',
      code: 'IM',
    },
    {
      name: '멕시코',
      code: 'MX',
    },
    {
      name: '모나코',
      code: 'MC',
    },
    {
      name: '모로코',
      code: 'MA',
    },
    {
      name: '모리셔스',
      code: 'MU',
    },
    {
      name: '모리타니',
      code: 'MR',
    },
    {
      name: '모잠비크',
      code: 'MZ',
    },
    {
      name: '몬테네그로',
      code: 'ME',
    },
    {
      name: '몬트세라트',
      code: 'MS',
    },
    {
      name: '몰도바',
      code: 'MD',
    },
    {
      name: '몰디브',
      code: 'MV',
    },
    {
      name: '몰타',
      code: 'MT',
    },
    {
      name: '몽골',
      code: 'MN',
    },
    {
      name: '미국',
      code: 'US',
    },
    {
      name: '미국령 군소 제도',
      code: 'UM',
    },
    {
      name: '미국령 버진 아일랜드',
      code: 'VI',
    },
    {
      name: '미얀마',
      code: 'MM',
    },
    {
      name: '미크로네시아',
      code: 'FM',
    },
    {
      name: '바누아투',
      code: 'VU',
    },
    {
      name: '바레인',
      code: 'BH',
    },
    {
      name: '바베이도스',
      code: 'BB',
    },
    {
      name: '바티칸시티',
      code: 'VA',
    },
    {
      name: '바하마',
      code: 'BS',
    },
    {
      name: '방글라데시',
      code: 'BD',
    },
    {
      name: '버뮤다',
      code: 'BM',
    },
    {
      name: '베네주엘라',
      code: 'VE',
    },
    {
      name: '베넹',
      code: 'BJ',
    },
    {
      name: '베트남',
      code: 'VN',
    },
    {
      name: '벨기에',
      code: 'BE',
    },
    {
      name: '벨라루스',
      code: 'BY',
    },
    {
      name: '벨리즈',
      code: 'BZ',
    },
    {
      name: '보스니아 헤르체고비나',
      code: 'BA',
    },
    {
      name: '보츠와나',
      code: 'BW',
    },
    {
      name: '볼리비아',
      code: 'BO',
    },
    {
      name: '부룬디',
      code: 'BI',
    },
    {
      name: '부르키나 파소',
      code: 'BF',
    },
    {
      name: '부베 섬',
      code: 'BV',
    },
    {
      name: '부탄',
      code: 'BT',
    },
    {
      name: '북마리아나제도',
      code: 'MP',
    },
    {
      name: '불가리아',
      code: 'BG',
    },
    {
      name: '브라질',
      code: 'BR',
    },
    {
      name: '브루나이',
      code: 'BN',
    },
    {
      name: '사모아',
      code: 'WS',
    },
    {
      name: '사우디 아라비아',
      code: 'SA',
    },
    {
      name: '사우스조지아 사우스샌드위치 제도',
      code: 'GS',
    },
    {
      name: '사이프러스',
      code: 'CY',
    },
    {
      name: '산마리노',
      code: 'SM',
    },
    {
      name: '상투메 프린시페',
      code: 'ST',
    },
    {
      name: '생 마르탱',
      code: 'MF',
    },
    {
      name: '생 바르텔르미',
      code: 'BL',
    },
    {
      name: '생피에르앤드미클롱',
      code: 'PM',
    },
    {
      name: '서사하라',
      code: 'EH',
    },
    {
      name: '세네갈',
      code: 'SN',
    },
    {
      name: '세르비아',
      code: 'RS',
    },
    {
      name: '세이쉘',
      code: 'SC',
    },
    {
      name: '세인트 루시아',
      code: 'LC',
    },
    {
      name: '세인트 빈센트 그레나딘',
      code: 'VC',
    },
    {
      name: '세인트 키츠 네비스',
      code: 'KN',
    },
    {
      name: '세인트헬레나',
      code: 'SH',
    },
    {
      name: '소말리아',
      code: 'SO',
    },
    {
      name: '솔로몬 제도',
      code: 'SB',
    },
    {
      name: '수단',
      code: 'SD',
    },
    {
      name: '수리남',
      code: 'SR',
    },
    {
      name: '스리랑카',
      code: 'LK',
    },
    {
      name: '스발바르',
      code: 'SJ',
    },
    {
      name: '스와질랜드',
      code: 'SZ',
    },
    {
      name: '스웨덴',
      code: 'SE',
    },
    {
      name: '스위스',
      code: 'CH',
    },
    {
      name: '스페인',
      code: 'ES',
    },
    {
      name: '슬로바키아',
      code: 'SK',
    },
    {
      name: '슬로베니아',
      code: 'SI',
    },
    {
      name: '시리아',
      code: 'SY',
    },
    {
      name: '시에라리온',
      code: 'SL',
    },
    {
      name: '신트마르턴',
      code: 'SX',
    },
    {
      name: '싱가포르',
      code: 'SG',
    },
    {
      name: '아랍에미리트 연합',
      code: 'AE',
    },
    {
      name: '아루바',
      code: 'AW',
    },
    {
      name: '아르메니아',
      code: 'AM',
    },
    {
      name: '아르헨티나',
      code: 'AR',
    },
    {
      name: '아메리칸 사모아',
      code: 'AS',
    },
    {
      name: '아이슬란드',
      code: 'IS',
    },
    {
      name: '아이티',
      code: 'HT',
    },
    {
      name: '아일랜드',
      code: 'IE',
    },
    {
      name: '아제르바이잔',
      code: 'AZ',
    },
    {
      name: '아프가니스탄',
      code: 'AF',
    },
    {
      name: '안도라',
      code: 'AD',
    },
    {
      name: '알바니아',
      code: 'AL',
    },
    {
      name: '알제리',
      code: 'DZ',
    },
    {
      name: '앙골라',
      code: 'AO',
    },
    {
      name: '앤티가 바부다',
      code: 'AG',
    },
    {
      name: '앵귈라',
      code: 'AI',
    },
    {
      name: '에리트리아',
      code: 'ER',
    },
    {
      name: '에스토니아',
      code: 'EE',
    },
    {
      name: '에콰도르',
      code: 'EC',
    },
    {
      name: '에티오피아',
      code: 'ET',
    },
    {
      name: '엘살바도르',
      code: 'SV',
    },
    {
      name: '영국',
      code: 'GB',
    },
    {
      name: '영국령 버진 아일랜드',
      code: 'VG',
    },
    {
      name: '영국령 인도양 지역',
      code: 'IO',
    },
    {
      name: '예멘',
      code: 'YE',
    },
    {
      name: '오만',
      code: 'OM',
    },
    {
      name: '오스트리아',
      code: 'AT',
    },
    {
      name: '온두라스',
      code: 'HN',
    },
    {
      name: '올란드 제도',
      code: 'AX',
    },
    {
      name: '왈리스-푸투나 제도',
      code: 'WF',
    },
    {
      name: '요르단',
      code: 'JO',
    },
    {
      name: '우간다',
      code: 'UG',
    },
    {
      name: '우루과이',
      code: 'UY',
    },
    {
      name: '우즈베키스탄',
      code: 'UZ',
    },
    {
      name: '우크라이나',
      code: 'UA',
    },
    {
      name: '이라크',
      code: 'IQ',
    },
    {
      name: '이란',
      code: 'IR',
    },
    {
      name: '이스라엘',
      code: 'IL',
    },
    {
      name: '이집트',
      code: 'EG',
    },
    {
      name: '이탈리아',
      code: 'IT',
    },
    {
      name: '인도',
      code: 'IN',
    },
    {
      name: '인도네시아',
      code: 'ID',
    },
    {
      name: '일본',
      code: 'JP',
    },
    {
      name: '자메이카',
      code: 'JM',
    },
    {
      name: '잠비아',
      code: 'ZM',
    },
    {
      name: '저지',
      code: 'JE',
    },
    {
      name: '적도 기니',
      code: 'GQ',
    },
    {
      name: '조선민주주의인민공화국',
      code: 'KP',
    },
    {
      name: '조지아',
      code: 'GE',
    },
    {
      name: '중앙 아프리카 공화국',
      code: 'CF',
    },
    {
      name: '중화인민공화국',
      code: 'CN',
    },
    {
      name: '지부티',
      code: 'DJ',
    },
    {
      name: '지브롤터',
      code: 'GI',
    },
    {
      name: '짐바브웨',
      code: 'ZW',
    },
    {
      name: '챠드',
      code: 'TD',
    },
    {
      name: '체코',
      code: 'CZ',
    },
    {
      name: '칠레',
      code: 'CL',
    },
    {
      name: '카메룬',
      code: 'CM',
    },
    {
      name: '카보베르데',
      code: 'CV',
    },
    {
      name: '카자흐스탄',
      code: 'KZ',
    },
    {
      name: '카타르',
      code: 'QA',
    },
    {
      name: '캄보디아',
      code: 'KH',
    },
    {
      name: '캐나다',
      code: 'CA',
    },
    {
      name: '케냐',
      code: 'KE',
    },
    {
      name: '케이맨제도',
      code: 'KY',
    },
    {
      name: '코모로스',
      code: 'KM',
    },
    {
      name: '코소보',
      code: 'XK',
    },
    {
      name: '코스타리카',
      code: 'CR',
    },
    {
      name: '코코스제도',
      code: 'CC',
    },
    {
      name: '코트디부아르',
      code: 'CI',
    },
    {
      name: '콜롬비아',
      code: 'CO',
    },
    {
      name: '콩고 공화국',
      code: 'CG',
    },
    {
      name: '콩고 민주 공화국',
      code: 'CD',
    },
    {
      name: '쿠바',
      code: 'CU',
    },
    {
      name: '쿠웨이트',
      code: 'KW',
    },
    {
      name: '쿡제도',
      code: 'CK',
    },
    {
      name: '퀴라소',
      code: 'CW',
    },
    {
      name: '크로아티아',
      code: 'HR',
    },
    {
      name: '크리스마스섬',
      code: 'CX',
    },
    {
      name: '키르기스스탄',
      code: 'KG',
    },
    {
      name: '키리바티',
      code: 'KI',
    },
    {
      name: '타지크스탄',
      code: 'TJ',
    },
    {
      name: '탄자니아',
      code: 'TZ',
    },
    {
      name: '태국',
      code: 'TH',
    },
    {
      name: '터크스 케이커스 제도',
      code: 'TC',
    },
    {
      name: '터키',
      code: 'TR',
    },
    {
      name: '토고',
      code: 'TG',
    },
    {
      name: '토켈라우',
      code: 'TK',
    },
    {
      name: '통가',
      code: 'TO',
    },
    {
      name: '투르크메니스탄',
      code: 'TM',
    },
    {
      name: '투발루',
      code: 'TV',
    },
    {
      name: '튀니지',
      code: 'TN',
    },
    {
      name: '트리니다드 토바고',
      code: 'TT',
    },
    {
      name: '파나마',
      code: 'PA',
    },
    {
      name: '파라과이',
      code: 'PY',
    },
    {
      name: '파키스탄',
      code: 'PK',
    },
    {
      name: '파푸아뉴기니',
      code: 'PG',
    },
    {
      name: '팔라우',
      code: 'PW',
    },
    {
      name: '팔레스타인 지구',
      code: 'PS',
    },
    {
      name: '페로제도',
      code: 'FO',
    },
    {
      name: '페루',
      code: 'PE',
    },
    {
      name: '포르투갈',
      code: 'PT',
    },
    {
      name: '포클랜드 제도',
      code: 'FK',
    },
    {
      name: '폴란드',
      code: 'PL',
    },
    {
      name: '푸에르토리코',
      code: 'PR',
    },
    {
      name: '프랑스',
      code: 'FR',
    },
    {
      name: '프랑스령 기아나',
      code: 'GF',
    },
    {
      name: '프랑스령 남부와 남극 지역',
      code: 'TF',
    },
    {
      name: '프랑스령 폴리네시아',
      code: 'PF',
    },
    {
      name: '피지',
      code: 'FJ',
    },
    {
      name: '핀란드',
      code: 'FI',
    },
    {
      name: '필리핀',
      code: 'PH',
    },
    {
      name: '핏케언섬',
      code: 'PN',
    },
    {
      name: '허드 맥도널드 제도',
      code: 'HM',
    },
    {
      name: '헝가리',
      code: 'HU',
    },
    {
      name: '호주',
      code: 'AU',
    },
    {
      name: '홍콩',
      code: 'HK',
    },
  ],
  'nl-nl': [
    {
      name: 'Afghanistan',
      code: 'AF',
    },
    {
      name: 'Albanië',
      code: 'AL',
    },
    {
      name: 'Algerije',
      code: 'DZ',
    },
    {
      name: 'Amerikaans Samoa',
      code: 'AS',
    },
    {
      name: 'Amerikaanse Maagdeneilanden',
      code: 'VI',
    },
    {
      name: 'Andorra',
      code: 'AD',
    },
    {
      name: 'Angola',
      code: 'AO',
    },
    {
      name: 'Anguilla',
      code: 'AI',
    },
    {
      name: 'Antarctica',
      code: 'AQ',
    },
    {
      name: 'Antigua en Barbuda',
      code: 'AG',
    },
    {
      name: 'Argentinië',
      code: 'AR',
    },
    {
      name: 'Armenië',
      code: 'AM',
    },
    {
      name: 'Aruba',
      code: 'AW',
    },
    {
      name: 'Australië',
      code: 'AU',
    },
    {
      name: 'Azerbeidzjan',
      code: 'AZ',
    },
    {
      name: 'BES-eilanden',
      code: 'BQ',
    },
    {
      name: 'Bahama’s',
      code: 'BS',
    },
    {
      name: 'Bahrein',
      code: 'BH',
    },
    {
      name: 'Bangladesh',
      code: 'BD',
    },
    {
      name: 'Barbados',
      code: 'BB',
    },
    {
      name: 'België',
      code: 'BE',
    },
    {
      name: 'Belize',
      code: 'BZ',
    },
    {
      name: 'Benin',
      code: 'BJ',
    },
    {
      name: 'Bermuda',
      code: 'BM',
    },
    {
      name: 'Bhutan',
      code: 'BT',
    },
    {
      name: 'Bolivia',
      code: 'BO',
    },
    {
      name: 'Bosnië-Herzegovina',
      code: 'BA',
    },
    {
      name: 'Botswana',
      code: 'BW',
    },
    {
      name: 'Bouveteiland',
      code: 'BV',
    },
    {
      name: 'Brazilië',
      code: 'BR',
    },
    {
      name: 'Britse Gebieden in de Indische Oceaan',
      code: 'IO',
    },
    {
      name: 'Britse Maagdeneilanden',
      code: 'VG',
    },
    {
      name: 'Brunei Darussalam',
      code: 'BN',
    },
    {
      name: 'Bulgarije',
      code: 'BG',
    },
    {
      name: 'Burkina Faso',
      code: 'BF',
    },
    {
      name: 'Burundi',
      code: 'BI',
    },
    {
      name: 'Cambodja',
      code: 'KH',
    },
    {
      name: 'Canada',
      code: 'CA',
    },
    {
      name: 'Caymaneilanden',
      code: 'KY',
    },
    {
      name: 'Centraal-Afrikaanse Republiek',
      code: 'CF',
    },
    {
      name: 'Chili',
      code: 'CL',
    },
    {
      name: 'Christmaseiland',
      code: 'CX',
    },
    {
      name: 'Cocoseilanden',
      code: 'CC',
    },
    {
      name: 'Colombia',
      code: 'CO',
    },
    {
      name: 'Comoren',
      code: 'KM',
    },
    {
      name: 'Congo [DRC]',
      code: 'CD',
    },
    {
      name: 'Congo [Republiek]',
      code: 'CG',
    },
    {
      name: 'Cookeilanden',
      code: 'CK',
    },
    {
      name: 'Costa Rica',
      code: 'CR',
    },
    {
      name: 'Cuba',
      code: 'CU',
    },
    {
      name: 'Curaçao',
      code: 'CW',
    },
    {
      name: 'Cyprus',
      code: 'CY',
    },
    {
      name: 'Denemarken',
      code: 'DK',
    },
    {
      name: 'Djibouti',
      code: 'DJ',
    },
    {
      name: 'Dominica',
      code: 'DM',
    },
    {
      name: 'Dominicaanse Republiek',
      code: 'DO',
    },
    {
      name: 'Duitsland',
      code: 'DE',
    },
    {
      name: 'Ecuador',
      code: 'EC',
    },
    {
      name: 'Egypte',
      code: 'EG',
    },
    {
      name: 'El Salvador',
      code: 'SV',
    },
    {
      name: 'Equatoriaal-Guinea',
      code: 'GQ',
    },
    {
      name: 'Eritrea',
      code: 'ER',
    },
    {
      name: 'Estland',
      code: 'EE',
    },
    {
      name: 'Ethiopië',
      code: 'ET',
    },
    {
      name: 'Faeröer',
      code: 'FO',
    },
    {
      name: 'Falklandeilanden',
      code: 'FK',
    },
    {
      name: 'Fiji',
      code: 'FJ',
    },
    {
      name: 'Filipijnen',
      code: 'PH',
    },
    {
      name: 'Finland',
      code: 'FI',
    },
    {
      name: 'Frankrijk',
      code: 'FR',
    },
    {
      name: 'Frans-Guyana',
      code: 'GF',
    },
    {
      name: 'Frans-Polynesië',
      code: 'PF',
    },
    {
      name: 'Franse Gebieden in de zuidelijke Indische Oceaan',
      code: 'TF',
    },
    {
      name: 'Gabon',
      code: 'GA',
    },
    {
      name: 'Gambia',
      code: 'GM',
    },
    {
      name: 'Georgië',
      code: 'GE',
    },
    {
      name: 'Ghana',
      code: 'GH',
    },
    {
      name: 'Gibraltar',
      code: 'GI',
    },
    {
      name: 'Grenada',
      code: 'GD',
    },
    {
      name: 'Griekenland',
      code: 'GR',
    },
    {
      name: 'Groen-Landt',
      code: 'GL',
    },
    {
      name: 'Guadeloupe',
      code: 'GP',
    },
    {
      name: 'Guam',
      code: 'GU',
    },
    {
      name: 'Guatemala',
      code: 'GT',
    },
    {
      name: 'Guernsey',
      code: 'GG',
    },
    {
      name: 'Guinee',
      code: 'GN',
    },
    {
      name: 'Guinee-Bissau',
      code: 'GW',
    },
    {
      name: 'Guyana',
      code: 'GY',
    },
    {
      name: 'Haïti',
      code: 'HT',
    },
    {
      name: 'Heard- en McDonaldeilanden',
      code: 'HM',
    },
    {
      name: 'Heilige stoel (Vaticaanstad)',
      code: 'VA',
    },
    {
      name: 'Honduras',
      code: 'HN',
    },
    {
      name: 'Hongarije',
      code: 'HU',
    },
    {
      name: 'Hongkong',
      code: 'HK',
    },
    {
      name: 'Ierland',
      code: 'IE',
    },
    {
      name: 'India',
      code: 'IN',
    },
    {
      name: 'Indonesië',
      code: 'ID',
    },
    {
      name: 'Irak',
      code: 'IQ',
    },
    {
      name: 'Iran',
      code: 'IR',
    },
    {
      name: 'Isle of Man',
      code: 'IM',
    },
    {
      name: 'Israël',
      code: 'IL',
    },
    {
      name: 'Italië',
      code: 'IT',
    },
    {
      name: 'Ivoorkust',
      code: 'CI',
    },
    {
      name: 'Jamaica',
      code: 'JM',
    },
    {
      name: 'Japan',
      code: 'JP',
    },
    {
      name: 'Jemen',
      code: 'YE',
    },
    {
      name: 'Jersey',
      code: 'JE',
    },
    {
      name: 'Jordanië',
      code: 'JO',
    },
    {
      name: 'Kaapverdië',
      code: 'CV',
    },
    {
      name: 'Kameroen',
      code: 'CM',
    },
    {
      name: 'Kazachstan',
      code: 'KZ',
    },
    {
      name: 'Kenia',
      code: 'KE',
    },
    {
      name: 'Kirgizstan',
      code: 'KG',
    },
    {
      name: 'Kiribati',
      code: 'KI',
    },
    {
      name: 'Kleine afgelegen eilanden van de Verenigde Staten',
      code: 'UM',
    },
    {
      name: 'Koeweit',
      code: 'KW',
    },
    {
      name: 'Korea, Democratische Volksrepubliek',
      code: 'KP',
    },
    {
      name: 'Kosovo',
      code: 'XK',
    },
    {
      name: 'Kroatië',
      code: 'HR',
    },
    {
      name: 'Laos',
      code: 'LA',
    },
    {
      name: 'Lesotho',
      code: 'LS',
    },
    {
      name: 'Letland',
      code: 'LV',
    },
    {
      name: 'Libanon',
      code: 'LB',
    },
    {
      name: 'Liberia',
      code: 'LR',
    },
    {
      name: 'Libische Arabische Republiek',
      code: 'LY',
    },
    {
      name: 'Liechtenstein',
      code: 'LI',
    },
    {
      name: 'Litouwen',
      code: 'LT',
    },
    {
      name: 'Luxemburg',
      code: 'LU',
    },
    {
      name: 'Macao',
      code: 'MO',
    },
    {
      name: 'Madagaskar',
      code: 'MG',
    },
    {
      name: 'Malawi',
      code: 'MW',
    },
    {
      name: 'Maldiven',
      code: 'MV',
    },
    {
      name: 'Maleisië',
      code: 'MY',
    },
    {
      name: 'Mali',
      code: 'ML',
    },
    {
      name: 'Malta',
      code: 'MT',
    },
    {
      name: 'Marokko',
      code: 'MA',
    },
    {
      name: 'Marshalleilanden',
      code: 'MH',
    },
    {
      name: 'Martinique',
      code: 'MQ',
    },
    {
      name: 'Mauritanië',
      code: 'MR',
    },
    {
      name: 'Mauritius',
      code: 'MU',
    },
    {
      name: 'Mayotte',
      code: 'YT',
    },
    {
      name: 'Mexico',
      code: 'MX',
    },
    {
      name: 'Micronesië',
      code: 'FM',
    },
    {
      name: 'Monaco',
      code: 'MC',
    },
    {
      name: 'Mongolië',
      code: 'MN',
    },
    {
      name: 'Montenegro',
      code: 'ME',
    },
    {
      name: 'Montserrat',
      code: 'MS',
    },
    {
      name: 'Mozambique',
      code: 'MZ',
    },
    {
      name: 'Myanmar',
      code: 'MM',
    },
    {
      name: 'Namibië',
      code: 'NA',
    },
    {
      name: 'Nauru',
      code: 'NR',
    },
    {
      name: 'Nederland',
      code: 'NL',
    },
    {
      name: 'Nepal',
      code: 'NP',
    },
    {
      name: 'Nicaragua',
      code: 'NI',
    },
    {
      name: 'Nieuw-Caledonië',
      code: 'NC',
    },
    {
      name: 'Nieuw-Zeeland',
      code: 'NZ',
    },
    {
      name: 'Niger',
      code: 'NE',
    },
    {
      name: 'Nigeria',
      code: 'NG',
    },
    {
      name: 'Niue',
      code: 'NU',
    },
    {
      name: 'Noordelijke Marianeneilanden',
      code: 'MP',
    },
    {
      name: 'Noorwegen',
      code: 'NO',
    },
    {
      name: 'Norfolkeiland',
      code: 'NF',
    },
    {
      name: 'Oeganda',
      code: 'UG',
    },
    {
      name: 'Oekraïne',
      code: 'UA',
    },
    {
      name: 'Oezbekistan',
      code: 'UZ',
    },
    {
      name: 'Oman',
      code: 'OM',
    },
    {
      name: 'Oost-Timor',
      code: 'TL',
    },
    {
      name: 'Oostenrijk',
      code: 'AT',
    },
    {
      name: 'Pakistan',
      code: 'PK',
    },
    {
      name: 'Palau',
      code: 'PW',
    },
    {
      name: 'Palestijnse gebieden',
      code: 'PS',
    },
    {
      name: 'Panama',
      code: 'PA',
    },
    {
      name: 'Papoea-Nieuw-Guinea',
      code: 'PG',
    },
    {
      name: 'Paraguay',
      code: 'PY',
    },
    {
      name: 'Peru',
      code: 'PE',
    },
    {
      name: 'Pitcairneilanden',
      code: 'PN',
    },
    {
      name: 'Polen',
      code: 'PL',
    },
    {
      name: 'Portugal',
      code: 'PT',
    },
    {
      name: 'Puerto Rico',
      code: 'PR',
    },
    {
      name: 'Qatar',
      code: 'QA',
    },
    {
      name: 'Republiek Korea',
      code: 'KR',
    },
    {
      name: 'Republiek Moldavië',
      code: 'MD',
    },
    {
      name: 'Roemenië',
      code: 'RO',
    },
    {
      name: 'Rusland',
      code: 'RU',
    },
    {
      name: 'Rwanda',
      code: 'RW',
    },
    {
      name: 'Réunion',
      code: 'RE',
    },
    {
      name: 'Saint Barthélemy',
      code: 'BL',
    },
    {
      name: 'Saint Pierre en Miquelon',
      code: 'PM',
    },
    {
      name: 'Salomonseilanden',
      code: 'SB',
    },
    {
      name: 'Samoa',
      code: 'WS',
    },
    {
      name: 'San Marino',
      code: 'SM',
    },
    {
      name: 'Saudi-Arabië',
      code: 'SA',
    },
    {
      name: 'Senegal',
      code: 'SN',
    },
    {
      name: 'Servië',
      code: 'RS',
    },
    {
      name: 'Seychellen',
      code: 'SC',
    },
    {
      name: 'Sierra Leone',
      code: 'SL',
    },
    {
      name: 'Singapore',
      code: 'SG',
    },
    {
      name: 'Sint Maarten',
      code: 'SX',
    },
    {
      name: 'Sint-Helena',
      code: 'SH',
    },
    {
      name: 'Sint-Maarten',
      code: 'MF',
    },
    {
      name: 'Slovenië',
      code: 'SI',
    },
    {
      name: 'Slowaakse Republiek',
      code: 'SK',
    },
    {
      name: 'Soedan',
      code: 'SD',
    },
    {
      name: 'Somalië',
      code: 'SO',
    },
    {
      name: 'Spanje',
      code: 'ES',
    },
    {
      name: 'Sri Lanka',
      code: 'LK',
    },
    {
      name: 'St. Kitts en Nevis',
      code: 'KN',
    },
    {
      name: 'St. Lucia',
      code: 'LC',
    },
    {
      name: 'St. Vincent en de Grenadines',
      code: 'VC',
    },
    {
      name: 'Suriname',
      code: 'SR',
    },
    {
      name: 'Svalbard en Jan Mayen',
      code: 'SJ',
    },
    {
      name: 'Swaziland',
      code: 'SZ',
    },
    {
      name: 'Syrië',
      code: 'SY',
    },
    {
      name: 'São Tomé and Príncipe',
      code: 'ST',
    },
    {
      name: 'Tadzjikistan',
      code: 'TJ',
    },
    {
      name: 'Taiwan',
      code: 'TW',
    },
    {
      name: 'Tanzania',
      code: 'TZ',
    },
    {
      name: 'Thailand',
      code: 'TH',
    },
    {
      name: 'Togo',
      code: 'TG',
    },
    {
      name: 'Tokelau',
      code: 'TK',
    },
    {
      name: 'Tonga',
      code: 'TO',
    },
    {
      name: 'Trinidad en Tobago',
      code: 'TT',
    },
    {
      name: 'Tsjaad',
      code: 'TD',
    },
    {
      name: 'Tsjechië',
      code: 'CZ',
    },
    {
      name: 'Tunesië',
      code: 'TN',
    },
    {
      name: 'Turkije',
      code: 'TR',
    },
    {
      name: 'Turkmenistan',
      code: 'TM',
    },
    {
      name: 'Turks- en Caicoseilanden',
      code: 'TC',
    },
    {
      name: 'Tuvalu',
      code: 'TV',
    },
    {
      name: 'Uruguay',
      code: 'UY',
    },
    {
      name: 'Vanuatu',
      code: 'VU',
    },
    {
      name: 'Venezuela',
      code: 'VE',
    },
    {
      name: 'Verenigd Koninkrijk',
      code: 'GB',
    },
    {
      name: 'Verenigde Arabische Emiraten',
      code: 'AE',
    },
    {
      name: 'Verenigde Staten',
      code: 'US',
    },
    {
      name: 'Vietnam',
      code: 'VN',
    },
    {
      name: 'Volksrepubliek China',
      code: 'CN',
    },
    {
      name: 'Voormalige Joegoslavische Republiek Macedonië',
      code: 'MK',
    },
    {
      name: 'Wallis en Futuna',
      code: 'WF',
    },
    {
      name: 'Westelijke Sahara',
      code: 'EH',
    },
    {
      name: 'Wit-Rusland',
      code: 'BY',
    },
    {
      name: 'Yslandt',
      code: 'IS',
    },
    {
      name: 'Zambia',
      code: 'ZM',
    },
    {
      name: 'Zimbabwe',
      code: 'ZW',
    },
    {
      name: 'Zuid-Afrika',
      code: 'ZA',
    },
    {
      name: 'Zuid-Georgië en Zuidelijke Sandwicheilanden',
      code: 'GS',
    },
    {
      name: 'Zuid-Soedan',
      code: 'SS',
    },
    {
      name: 'Zweden',
      code: 'SE',
    },
    {
      name: 'Zwitserland',
      code: 'CH',
    },
    {
      name: 'Ålandeilanden',
      code: 'AX',
    },
  ],
  'pl-pl': [
    {
      name: 'Afganistan',
      code: 'AF',
    },
    {
      name: 'Albania',
      code: 'AL',
    },
    {
      name: 'Algieria',
      code: 'DZ',
    },
    {
      name: 'Andora',
      code: 'AD',
    },
    {
      name: 'Angola',
      code: 'AO',
    },
    {
      name: 'Anguilla',
      code: 'AI',
    },
    {
      name: 'Antarktyka',
      code: 'AQ',
    },
    {
      name: 'Antigua i Barbuda',
      code: 'AG',
    },
    {
      name: 'Arabia Saudyjska',
      code: 'SA',
    },
    {
      name: 'Argentyna',
      code: 'AR',
    },
    {
      name: 'Armenia',
      code: 'AM',
    },
    {
      name: 'Aruba',
      code: 'AW',
    },
    {
      name: 'Australia',
      code: 'AU',
    },
    {
      name: 'Austria',
      code: 'AT',
    },
    {
      name: 'Azerbejdżan',
      code: 'AZ',
    },
    {
      name: 'Bahamy',
      code: 'BS',
    },
    {
      name: 'Bahrajn',
      code: 'BH',
    },
    {
      name: 'Bangladesz',
      code: 'BD',
    },
    {
      name: 'Barbados',
      code: 'BB',
    },
    {
      name: 'Belgia',
      code: 'BE',
    },
    {
      name: 'Belize',
      code: 'BZ',
    },
    {
      name: 'Benin',
      code: 'BJ',
    },
    {
      name: 'Bermudy',
      code: 'BM',
    },
    {
      name: 'Bhutan',
      code: 'BT',
    },
    {
      name: 'Białoruś',
      code: 'BY',
    },
    {
      name: 'Boliwia',
      code: 'BO',
    },
    {
      name: 'Bonaire, Sint Eustatius i Saba',
      code: 'BQ',
    },
    {
      name: 'Botswana',
      code: 'BW',
    },
    {
      name: 'Bośnia i Hercegowina',
      code: 'BA',
    },
    {
      name: 'Brazylia',
      code: 'BR',
    },
    {
      name: 'Brunei Darussalam',
      code: 'BN',
    },
    {
      name: 'Brytyjskie Wyspy Dziewicze',
      code: 'VG',
    },
    {
      name: 'Burkina Faso',
      code: 'BF',
    },
    {
      name: 'Burundi',
      code: 'BI',
    },
    {
      name: 'Bułgaria',
      code: 'BG',
    },
    {
      name: 'Była Jugosłowiańska Republika Macedonii',
      code: 'MK',
    },
    {
      name: 'Chile',
      code: 'CL',
    },
    {
      name: 'Chiny',
      code: 'CN',
    },
    {
      name: 'Chorwacja',
      code: 'HR',
    },
    {
      name: 'Curaçao',
      code: 'CW',
    },
    {
      name: 'Cypr',
      code: 'CY',
    },
    {
      name: 'Czad',
      code: 'TD',
    },
    {
      name: 'Czarnogóra',
      code: 'ME',
    },
    {
      name: 'Czechy',
      code: 'CZ',
    },
    {
      name: 'Dalekie Wyspy Mniejsze Stanów Zjednoczonych',
      code: 'UM',
    },
    {
      name: 'Dania',
      code: 'DK',
    },
    {
      name: 'Dominika',
      code: 'DM',
    },
    {
      name: 'Dżibuti',
      code: 'DJ',
    },
    {
      name: 'Egipt',
      code: 'EG',
    },
    {
      name: 'Ekwador',
      code: 'EC',
    },
    {
      name: 'Erytrea',
      code: 'ER',
    },
    {
      name: 'Estonia',
      code: 'EE',
    },
    {
      name: 'Etiopia',
      code: 'ET',
    },
    {
      name: 'Falklandy',
      code: 'FK',
    },
    {
      name: 'Federalne Stany Mikronezji',
      code: 'FM',
    },
    {
      name: 'Fidżi',
      code: 'FJ',
    },
    {
      name: 'Filipiny',
      code: 'PH',
    },
    {
      name: 'Finlandia',
      code: 'FI',
    },
    {
      name: 'Francja',
      code: 'FR',
    },
    {
      name: 'Francuskie Terytoria Południowe',
      code: 'TF',
    },
    {
      name: 'Gabon',
      code: 'GA',
    },
    {
      name: 'Gambia',
      code: 'GM',
    },
    {
      name: 'Georgia Południowa i Sandwich Południowy',
      code: 'GS',
    },
    {
      name: 'Ghana',
      code: 'GH',
    },
    {
      name: 'Gibraltar',
      code: 'GI',
    },
    {
      name: 'Grecja',
      code: 'GR',
    },
    {
      name: 'Grenada',
      code: 'GD',
    },
    {
      name: 'Grenlandia',
      code: 'GL',
    },
    {
      name: 'Gruzja',
      code: 'GE',
    },
    {
      name: 'Guam',
      code: 'GU',
    },
    {
      name: 'Gujana',
      code: 'GY',
    },
    {
      name: 'Gujana Francuska',
      code: 'GF',
    },
    {
      name: 'Gwadelupa',
      code: 'GP',
    },
    {
      name: 'Gwatemala',
      code: 'GT',
    },
    {
      name: 'Gwinea',
      code: 'GN',
    },
    {
      name: 'Gwinea Bissau',
      code: 'GW',
    },
    {
      name: 'Gwinea Równikowa',
      code: 'GQ',
    },
    {
      name: 'Haiti',
      code: 'HT',
    },
    {
      name: 'Hiszpania',
      code: 'ES',
    },
    {
      name: 'Holandia',
      code: 'NL',
    },
    {
      name: 'Honduras',
      code: 'HN',
    },
    {
      name: 'Hongkong',
      code: 'HK',
    },
    {
      name: 'Indie',
      code: 'IN',
    },
    {
      name: 'Indonezja',
      code: 'ID',
    },
    {
      name: 'Irak',
      code: 'IQ',
    },
    {
      name: 'Iran',
      code: 'IR',
    },
    {
      name: 'Irlandia',
      code: 'IE',
    },
    {
      name: 'Islandia',
      code: 'IS',
    },
    {
      name: 'Izrael',
      code: 'IL',
    },
    {
      name: 'Jamajka',
      code: 'JM',
    },
    {
      name: 'Japonia',
      code: 'JP',
    },
    {
      name: 'Jemen',
      code: 'YE',
    },
    {
      name: 'Jordania',
      code: 'JO',
    },
    {
      name: 'Kajmany',
      code: 'KY',
    },
    {
      name: 'Kambodża',
      code: 'KH',
    },
    {
      name: 'Kamerun',
      code: 'CM',
    },
    {
      name: 'Kanada',
      code: 'CA',
    },
    {
      name: 'Katar',
      code: 'QA',
    },
    {
      name: 'Kazachstan',
      code: 'KZ',
    },
    {
      name: 'Kenia',
      code: 'KE',
    },
    {
      name: 'Kirgistan',
      code: 'KG',
    },
    {
      name: 'Kiribati',
      code: 'KI',
    },
    {
      name: 'Kolumbia',
      code: 'CO',
    },
    {
      name: 'Komory',
      code: 'KM',
    },
    {
      name: 'Kongo, Republika Demokratyczna',
      code: 'CD',
    },
    {
      name: 'Koreańska Republika Ludowo-Demokratyczna',
      code: 'KP',
    },
    {
      name: 'Kosowo',
      code: 'XK',
    },
    {
      name: 'Kostaryka',
      code: 'CR',
    },
    {
      name: 'Kuba',
      code: 'CU',
    },
    {
      name: 'Kuwejt',
      code: 'KW',
    },
    {
      name: 'Laos',
      code: 'LA',
    },
    {
      name: 'Lesoto',
      code: 'LS',
    },
    {
      name: 'Liban',
      code: 'LB',
    },
    {
      name: 'Liberia',
      code: 'LR',
    },
    {
      name: 'Libijska',
      code: 'LY',
    },
    {
      name: 'Lichtenstein',
      code: 'LI',
    },
    {
      name: 'Litwa',
      code: 'LT',
    },
    {
      name: 'Luksemburg',
      code: 'LU',
    },
    {
      name: 'Madagaskar',
      code: 'MG',
    },
    {
      name: 'Majotta',
      code: 'YT',
    },
    {
      name: 'Makau',
      code: 'MO',
    },
    {
      name: 'Malawi',
      code: 'MW',
    },
    {
      name: 'Malediwy',
      code: 'MV',
    },
    {
      name: 'Malezja',
      code: 'MY',
    },
    {
      name: 'Mali',
      code: 'ML',
    },
    {
      name: 'Malta',
      code: 'MT',
    },
    {
      name: 'Mariany Północne',
      code: 'MP',
    },
    {
      name: 'Maroko',
      code: 'MA',
    },
    {
      name: 'Martynika',
      code: 'MQ',
    },
    {
      name: 'Mauretania',
      code: 'MR',
    },
    {
      name: 'Mauritius',
      code: 'MU',
    },
    {
      name: 'Meksyk',
      code: 'MX',
    },
    {
      name: 'Monako',
      code: 'MC',
    },
    {
      name: 'Mongolia',
      code: 'MN',
    },
    {
      name: 'Montserrat',
      code: 'MS',
    },
    {
      name: 'Mozambik',
      code: 'MZ',
    },
    {
      name: 'Mołdawia, Republika',
      code: 'MD',
    },
    {
      name: 'Myanmar',
      code: 'MM',
    },
    {
      name: 'Namibia',
      code: 'NA',
    },
    {
      name: 'Nauru',
      code: 'NR',
    },
    {
      name: 'Nepal',
      code: 'NP',
    },
    {
      name: 'Niemcy',
      code: 'DE',
    },
    {
      name: 'Niger',
      code: 'NE',
    },
    {
      name: 'Nigeria',
      code: 'NG',
    },
    {
      name: 'Nikaragua',
      code: 'NI',
    },
    {
      name: 'Niue',
      code: 'NU',
    },
    {
      name: 'Norfolk',
      code: 'NF',
    },
    {
      name: 'Norwegia',
      code: 'NO',
    },
    {
      name: 'Nowa Kaledonia',
      code: 'NC',
    },
    {
      name: 'Nowa Zelandia',
      code: 'NZ',
    },
    {
      name: 'Oman',
      code: 'OM',
    },
    {
      name: 'Pakistan',
      code: 'PK',
    },
    {
      name: 'Palau',
      code: 'PW',
    },
    {
      name: 'Panama',
      code: 'PA',
    },
    {
      name: 'Papua-Nowa Gwinea',
      code: 'PG',
    },
    {
      name: 'Paragwaj',
      code: 'PY',
    },
    {
      name: 'Peru',
      code: 'PE',
    },
    {
      name: 'Pitcairn',
      code: 'PN',
    },
    {
      name: 'Polinezja Francuska',
      code: 'PF',
    },
    {
      name: 'Polska',
      code: 'PL',
    },
    {
      name: 'Portoryko',
      code: 'PR',
    },
    {
      name: 'Portugalia',
      code: 'PT',
    },
    {
      name: 'Południowa Afryka',
      code: 'ZA',
    },
    {
      name: 'Republika Chińska',
      code: 'TW',
    },
    {
      name: 'Republika Dominikany',
      code: 'DO',
    },
    {
      name: 'Republika Konga',
      code: 'CG',
    },
    {
      name: 'Republika Korei',
      code: 'KR',
    },
    {
      name: 'Republika Zielonego Przylądka',
      code: 'CV',
    },
    {
      name: 'Republika Środkowoafrykańska',
      code: 'CF',
    },
    {
      name: 'Reunion',
      code: 'RE',
    },
    {
      name: 'Rosja',
      code: 'RU',
    },
    {
      name: 'Rumunia',
      code: 'RO',
    },
    {
      name: 'Rwanda',
      code: 'RW',
    },
    {
      name: 'Sahara Zachodnia',
      code: 'EH',
    },
    {
      name: 'Saint Barthélemy',
      code: 'BL',
    },
    {
      name: 'Saint Kitts i Nevis',
      code: 'KN',
    },
    {
      name: 'Saint Lucia',
      code: 'LC',
    },
    {
      name: 'Saint Vincent i Grenadyny',
      code: 'VC',
    },
    {
      name: 'Saint-Pierre i Miquelon',
      code: 'PM',
    },
    {
      name: 'Salwador',
      code: 'SV',
    },
    {
      name: 'Samoa',
      code: 'WS',
    },
    {
      name: 'Samoa Amerykańskie',
      code: 'AS',
    },
    {
      name: 'San Marino',
      code: 'SM',
    },
    {
      name: 'Senegal',
      code: 'SN',
    },
    {
      name: 'Serbia',
      code: 'RS',
    },
    {
      name: 'Seszele',
      code: 'SC',
    },
    {
      name: 'Sierra Leone',
      code: 'SL',
    },
    {
      name: 'Singapur',
      code: 'SG',
    },
    {
      name: 'Sint Maarten',
      code: 'MF',
    },
    {
      name: 'Sint Maarten',
      code: 'SX',
    },
    {
      name: 'Somalia',
      code: 'SO',
    },
    {
      name: 'Sri Lanka',
      code: 'LK',
    },
    {
      name: 'Stany Zjednoczone',
      code: 'US',
    },
    {
      name: 'Stolica Apostolska (Watykan)',
      code: 'VA',
    },
    {
      name: 'Suazi',
      code: 'SZ',
    },
    {
      name: 'Sudan',
      code: 'SD',
    },
    {
      name: 'Sudan Południowy',
      code: 'SS',
    },
    {
      name: 'Surinam',
      code: 'SR',
    },
    {
      name: 'Svalbard i Jan Mayen',
      code: 'SJ',
    },
    {
      name: 'Syria',
      code: 'SY',
    },
    {
      name: 'Szwajcaria',
      code: 'CH',
    },
    {
      name: 'Szwecja',
      code: 'SE',
    },
    {
      name: 'Słowacja',
      code: 'SK',
    },
    {
      name: 'Słowenia',
      code: 'SI',
    },
    {
      name: 'Tadżykistan',
      code: 'TJ',
    },
    {
      name: 'Tajlandia',
      code: 'TH',
    },
    {
      name: 'Tanzania',
      code: 'TZ',
    },
    {
      name: 'Terytoria Palestyńskie',
      code: 'PS',
    },
    {
      name: 'Terytorium Brytyjskie Oceanu Indyjskiego',
      code: 'IO',
    },
    {
      name: 'Timor Wschodni',
      code: 'TL',
    },
    {
      name: 'Togo',
      code: 'TG',
    },
    {
      name: 'Tokelau',
      code: 'TK',
    },
    {
      name: 'Tonga',
      code: 'TO',
    },
    {
      name: 'Trynidad i Tobago',
      code: 'TT',
    },
    {
      name: 'Tunezja',
      code: 'TN',
    },
    {
      name: 'Turcja',
      code: 'TR',
    },
    {
      name: 'Turkmenistan',
      code: 'TM',
    },
    {
      name: 'Turks i Caicos',
      code: 'TC',
    },
    {
      name: 'Tuvalu',
      code: 'TV',
    },
    {
      name: 'Uganda',
      code: 'UG',
    },
    {
      name: 'Ukraina',
      code: 'UA',
    },
    {
      name: 'Urugwaj',
      code: 'UY',
    },
    {
      name: 'Uzbekistan',
      code: 'UZ',
    },
    {
      name: 'Vanuatu',
      code: 'VU',
    },
    {
      name: 'Wenezuela',
      code: 'VE',
    },
    {
      name: 'Wietnam',
      code: 'VN',
    },
    {
      name: 'Wybrzeże Kości Słoniowej',
      code: 'CI',
    },
    {
      name: 'Wyspa Bouveta',
      code: 'BV',
    },
    {
      name: 'Wyspa Bożego Narodzenia',
      code: 'CX',
    },
    {
      name: 'Wyspa Guernsey',
      code: 'GG',
    },
    {
      name: 'Wyspa Jersey',
      code: 'JE',
    },
    {
      name: 'Wyspa Man',
      code: 'IM',
    },
    {
      name: 'Wyspa Świętej Heleny',
      code: 'SH',
    },
    {
      name: 'Wyspy Alandzkie',
      code: 'AX',
    },
    {
      name: 'Wyspy Cooka',
      code: 'CK',
    },
    {
      name: 'Wyspy Dziewicze Stanów Zjednoczonych',
      code: 'VI',
    },
    {
      name: 'Wyspy Heard i McDonalda',
      code: 'HM',
    },
    {
      name: 'Wyspy Kokosowe',
      code: 'CC',
    },
    {
      name: 'Wyspy Marshalla',
      code: 'MH',
    },
    {
      name: 'Wyspy Owcze',
      code: 'FO',
    },
    {
      name: 'Wyspy Salomona',
      code: 'SB',
    },
    {
      name: 'Wyspy Wallis i Futuna',
      code: 'WF',
    },
    {
      name: 'Wyspy Świętego Tomasza i Książęca',
      code: 'ST',
    },
    {
      name: 'Węgry',
      code: 'HU',
    },
    {
      name: 'Włochy',
      code: 'IT',
    },
    {
      name: 'Zambia',
      code: 'ZM',
    },
    {
      name: 'Zimbabwe',
      code: 'ZW',
    },
    {
      name: 'Zjednoczone Emiraty Arabskie',
      code: 'AE',
    },
    {
      name: 'Zjednoczone Królestwo Wielkiej Brytanii',
      code: 'GB',
    },
    {
      name: 'Łotwa',
      code: 'LV',
    },
  ],
  'tr-tr': [
    {
      name: 'ABD',
      code: 'US',
    },
    {
      name: 'ABD Virgin Adaları',
      code: 'VI',
    },
    {
      name: 'Afganistan',
      code: 'AF',
    },
    {
      name: 'Aland Adaları',
      code: 'AX',
    },
    {
      name: 'Almanya',
      code: 'DE',
    },
    {
      name: 'Amerika Birleşik Devletleri Küçük Dış Adaları',
      code: 'UM',
    },
    {
      name: 'Amerikan Samoası',
      code: 'AS',
    },
    {
      name: 'Andorra',
      code: 'AD',
    },
    {
      name: 'Angola',
      code: 'AO',
    },
    {
      name: 'Anguilla',
      code: 'AI',
    },
    {
      name: 'Antarktika',
      code: 'AQ',
    },
    {
      name: 'Antigua ve Barbuda',
      code: 'AG',
    },
    {
      name: 'Arjantin',
      code: 'AR',
    },
    {
      name: 'Arnavutluk Cumhuriyeti',
      code: 'AL',
    },
    {
      name: 'Aruba',
      code: 'AW',
    },
    {
      name: 'Avustralya',
      code: 'AU',
    },
    {
      name: 'Avusturya',
      code: 'AT',
    },
    {
      name: 'Azerbaycan',
      code: 'AZ',
    },
    {
      name: 'Bahamalar',
      code: 'BS',
    },
    {
      name: 'Bahreyn',
      code: 'BH',
    },
    {
      name: 'Bangladeş',
      code: 'BD',
    },
    {
      name: 'Barbados',
      code: 'BB',
    },
    {
      name: 'Batı Sahara',
      code: 'EH',
    },
    {
      name: 'Belize',
      code: 'BZ',
    },
    {
      name: 'Belçika',
      code: 'BE',
    },
    {
      name: 'Benin',
      code: 'BJ',
    },
    {
      name: 'Bermuda',
      code: 'BM',
    },
    {
      name: 'Beyaz Rusya',
      code: 'BY',
    },
    {
      name: 'Birleşik Arap Emirlikleri',
      code: 'AE',
    },
    {
      name: 'Birmanya',
      code: 'MM',
    },
    {
      name: 'Bolivya',
      code: 'BO',
    },
    {
      name: 'Bonaire, Sint Eustatius, and Saba',
      code: 'BQ',
    },
    {
      name: 'Bosna ve Hersek',
      code: 'BA',
    },
    {
      name: 'Botswana',
      code: 'BW',
    },
    {
      name: 'Bouvet Adası',
      code: 'BV',
    },
    {
      name: 'Brezilya',
      code: 'BR',
    },
    {
      name: 'Brunei',
      code: 'BN',
    },
    {
      name: 'Bulgaristan',
      code: 'BG',
    },
    {
      name: 'Burkina Faso',
      code: 'BF',
    },
    {
      name: 'Burundi',
      code: 'BI',
    },
    {
      name: 'Butan',
      code: 'BT',
    },
    {
      name: 'Cayman Adaları',
      code: 'KY',
    },
    {
      name: 'Cebelitarık',
      code: 'GI',
    },
    {
      name: 'Cezayir',
      code: 'DZ',
    },
    {
      name: 'Christmas Adası',
      code: 'CX',
    },
    {
      name: 'Cibuti',
      code: 'DJ',
    },
    {
      name: 'Cocos Adaları',
      code: 'CC',
    },
    {
      name: 'Cook Adaları',
      code: 'CK',
    },
    {
      name: 'Curaçao',
      code: 'CW',
    },
    {
      name: 'Danimarka',
      code: 'DK',
    },
    {
      name: 'Dominik Cumhuriyeti',
      code: 'DO',
    },
    {
      name: 'Dominika',
      code: 'DM',
    },
    {
      name: 'Doğu Timor',
      code: 'TL',
    },
    {
      name: 'Ekvador',
      code: 'EC',
    },
    {
      name: 'Ekvator Ginesi',
      code: 'GQ',
    },
    {
      name: 'El Salvador',
      code: 'SV',
    },
    {
      name: 'Endonezya',
      code: 'ID',
    },
    {
      name: 'Eritre',
      code: 'ER',
    },
    {
      name: 'Ermenistan',
      code: 'AM',
    },
    {
      name: 'Estonya',
      code: 'EE',
    },
    {
      name: 'Etiyopya',
      code: 'ET',
    },
    {
      name: 'Falkland Adaları',
      code: 'FK',
    },
    {
      name: 'Faroe Adaları',
      code: 'FO',
    },
    {
      name: 'Fas',
      code: 'MA',
    },
    {
      name: 'Fiji',
      code: 'FJ',
    },
    {
      name: 'Fildişi Sahilleri',
      code: 'CI',
    },
    {
      name: 'Filipinler Cumhuriyeti',
      code: 'PH',
    },
    {
      name: 'Filistin Bölgesi',
      code: 'PS',
    },
    {
      name: 'Finlandiya',
      code: 'FI',
    },
    {
      name: 'Fransa',
      code: 'FR',
    },
    {
      name: 'Fransız Guyanası',
      code: 'GF',
    },
    {
      name: 'Fransız Güney Bölgeleri',
      code: 'TF',
    },
    {
      name: 'Fransız Polinezyası',
      code: 'PF',
    },
    {
      name: 'Gabon',
      code: 'GA',
    },
    {
      name: 'Gambiya',
      code: 'GM',
    },
    {
      name: 'Gana',
      code: 'GH',
    },
    {
      name: 'Gine',
      code: 'GN',
    },
    {
      name: 'Gine-Biso',
      code: 'GW',
    },
    {
      name: 'Grenada',
      code: 'GD',
    },
    {
      name: 'Grönland',
      code: 'GL',
    },
    {
      name: 'Guadalupe',
      code: 'GP',
    },
    {
      name: 'Guam',
      code: 'GU',
    },
    {
      name: 'Guatemala',
      code: 'GT',
    },
    {
      name: 'Guernsey',
      code: 'GG',
    },
    {
      name: 'Guyana',
      code: 'GY',
    },
    {
      name: 'Güney Afrika Cumhuriyeti',
      code: 'ZA',
    },
    {
      name: 'Güney Georgia ve Güney Sandwich Adaları',
      code: 'GS',
    },
    {
      name: 'Gürcistan',
      code: 'GE',
    },
    {
      name: 'Haiti',
      code: 'HT',
    },
    {
      name: 'Heard Adası ve McDonald Adaları',
      code: 'HM',
    },
    {
      name: 'Hindistan',
      code: 'IN',
    },
    {
      name: 'Hollanda',
      code: 'NL',
    },
    {
      name: 'Honduras',
      code: 'HN',
    },
    {
      name: 'Hong Kong',
      code: 'HK',
    },
    {
      name: 'Hırvatistan',
      code: 'HR',
    },
    {
      name: 'Irak',
      code: 'IQ',
    },
    {
      name: 'Jamaika',
      code: 'JM',
    },
    {
      name: 'Japonya',
      code: 'JP',
    },
    {
      name: 'Jersey',
      code: 'JE',
    },
    {
      name: 'Kamboçya',
      code: 'KH',
    },
    {
      name: 'Kamerun',
      code: 'CM',
    },
    {
      name: 'Kanada',
      code: 'CA',
    },
    {
      name: 'Karadağ',
      code: 'ME',
    },
    {
      name: 'Katar',
      code: 'QA',
    },
    {
      name: 'Kazakistan',
      code: 'KZ',
    },
    {
      name: 'Kenya',
      code: 'KE',
    },
    {
      name: 'Kiribati',
      code: 'KI',
    },
    {
      name: 'Kolombiya',
      code: 'CO',
    },
    {
      name: 'Komoros',
      code: 'KM',
    },
    {
      name: 'Kongo - Brazzaville',
      code: 'CG',
    },
    {
      name: 'Kongo Cumhuriyeti',
      code: 'CD',
    },
    {
      name: 'Kore Cumhuriyeti',
      code: 'KR',
    },
    {
      name: 'Kosova',
      code: 'XK',
    },
    {
      name: 'Kosta Rika',
      code: 'CR',
    },
    {
      name: 'Kuveyt',
      code: 'KW',
    },
    {
      name: 'Kuzey Kore',
      code: 'KP',
    },
    {
      name: 'Kuzey Makedonya',
      code: 'MK',
    },
    {
      name: 'Kuzey Mariana Adaları',
      code: 'MP',
    },
    {
      name: 'Küba',
      code: 'CU',
    },
    {
      name: 'Kıbrıs Cumhuriyeti',
      code: 'CY',
    },
    {
      name: 'Kırgızistan',
      code: 'KG',
    },
    {
      name: 'Laos',
      code: 'LA',
    },
    {
      name: 'Lesoto',
      code: 'LS',
    },
    {
      name: 'Letonya',
      code: 'LV',
    },
    {
      name: 'Liberya',
      code: 'LR',
    },
    {
      name: 'Libya',
      code: 'LY',
    },
    {
      name: 'Lihtenştayn',
      code: 'LI',
    },
    {
      name: 'Litvanya',
      code: 'LT',
    },
    {
      name: 'Lübnan',
      code: 'LB',
    },
    {
      name: 'Lüksemburg',
      code: 'LU',
    },
    {
      name: 'Macaristan',
      code: 'HU',
    },
    {
      name: 'Madagaskar',
      code: 'MG',
    },
    {
      name: 'Makao',
      code: 'MO',
    },
    {
      name: 'Malavi',
      code: 'MW',
    },
    {
      name: 'Maldivler',
      code: 'MV',
    },
    {
      name: 'Malezya',
      code: 'MY',
    },
    {
      name: 'Mali',
      code: 'ML',
    },
    {
      name: 'Malta',
      code: 'MT',
    },
    {
      name: 'Man Adası',
      code: 'IM',
    },
    {
      name: 'Marshall Adaları',
      code: 'MH',
    },
    {
      name: 'Martinik',
      code: 'MQ',
    },
    {
      name: 'Mauritius',
      code: 'MU',
    },
    {
      name: 'Mayotte',
      code: 'YT',
    },
    {
      name: 'Meksika',
      code: 'MX',
    },
    {
      name: 'Mikronezya Federal Eyaletleri',
      code: 'FM',
    },
    {
      name: 'Moldovya',
      code: 'MD',
    },
    {
      name: 'Monako',
      code: 'MC',
    },
    {
      name: 'Montserrat',
      code: 'MS',
    },
    {
      name: 'Moritanya',
      code: 'MR',
    },
    {
      name: 'Mozambik',
      code: 'MZ',
    },
    {
      name: 'Moğolistan',
      code: 'MN',
    },
    {
      name: 'Mısır',
      code: 'EG',
    },
    {
      name: 'Namibya',
      code: 'NA',
    },
    {
      name: 'Nauru Adası',
      code: 'NR',
    },
    {
      name: 'Nepal',
      code: 'NP',
    },
    {
      name: 'Nijer',
      code: 'NE',
    },
    {
      name: 'Nijerya',
      code: 'NG',
    },
    {
      name: 'Nikaragua',
      code: 'NI',
    },
    {
      name: 'Niue Adaları',
      code: 'NU',
    },
    {
      name: 'Norfolk Adası',
      code: 'NF',
    },
    {
      name: 'Norveç',
      code: 'NO',
    },
    {
      name: 'Orta Afrika Cumhuriyeti',
      code: 'CF',
    },
    {
      name: 'Pakistan',
      code: 'PK',
    },
    {
      name: 'Palau',
      code: 'PW',
    },
    {
      name: 'Panama',
      code: 'PA',
    },
    {
      name: 'Papua Yeni Gine',
      code: 'PG',
    },
    {
      name: 'Paraguay',
      code: 'PY',
    },
    {
      name: 'Peru',
      code: 'PE',
    },
    {
      name: 'Pitcairn',
      code: 'PN',
    },
    {
      name: 'Polonya',
      code: 'PL',
    },
    {
      name: 'Portekiz',
      code: 'PT',
    },
    {
      name: 'Porto Riko',
      code: 'PR',
    },
    {
      name: 'Reunion',
      code: 'RE',
    },
    {
      name: 'Romanya',
      code: 'RO',
    },
    {
      name: 'Ruanda',
      code: 'RW',
    },
    {
      name: 'Rusya',
      code: 'RU',
    },
    {
      name: 'Saint Barthelemy',
      code: 'BL',
    },
    {
      name: 'Saint Helena',
      code: 'SH',
    },
    {
      name: 'Saint Kitts ve Nevis',
      code: 'KN',
    },
    {
      name: 'Saint Lucia',
      code: 'LC',
    },
    {
      name: 'Saint Martin',
      code: 'MF',
    },
    {
      name: 'Saint Pierre ve Miquelon',
      code: 'PM',
    },
    {
      name: 'Saint Vincent ve Grenadines',
      code: 'VC',
    },
    {
      name: 'Samoa',
      code: 'WS',
    },
    {
      name: 'San Marino',
      code: 'SM',
    },
    {
      name: 'Senegal',
      code: 'SN',
    },
    {
      name: 'Seychelles',
      code: 'SC',
    },
    {
      name: 'Sierra Leone',
      code: 'SL',
    },
    {
      name: 'Singapur',
      code: 'SG',
    },
    {
      name: 'Sint Maarten',
      code: 'SX',
    },
    {
      name: 'Slovakya',
      code: 'SK',
    },
    {
      name: 'Slovenya',
      code: 'SI',
    },
    {
      name: 'Solomon Adaları',
      code: 'SB',
    },
    {
      name: 'Somali',
      code: 'SO',
    },
    {
      name: 'South Sudan',
      code: 'SS',
    },
    {
      name: 'Sri Lanka',
      code: 'LK',
    },
    {
      name: 'Sudan',
      code: 'SD',
    },
    {
      name: 'Surinam',
      code: 'SR',
    },
    {
      name: 'Suriye',
      code: 'SY',
    },
    {
      name: 'Suudi Arabistan',
      code: 'SA',
    },
    {
      name: 'Svalbard ve Jan Mayen Adaları',
      code: 'SJ',
    },
    {
      name: 'Swaziland',
      code: 'SZ',
    },
    {
      name: 'São Tomé and Príncipe',
      code: 'ST',
    },
    {
      name: 'Sırbistan',
      code: 'RS',
    },
    {
      name: 'Tacikistan',
      code: 'TJ',
    },
    {
      name: 'Tanzanya',
      code: 'TZ',
    },
    {
      name: 'Tayland',
      code: 'TH',
    },
    {
      name: 'Togo',
      code: 'TG',
    },
    {
      name: 'Tokelau',
      code: 'TK',
    },
    {
      name: 'Tonga',
      code: 'TO',
    },
    {
      name: 'Trinidad ve Tobago',
      code: 'TT',
    },
    {
      name: 'Tunus',
      code: 'TN',
    },
    {
      name: 'Turks ve Caicos Adaları',
      code: 'TC',
    },
    {
      name: 'Tuvalu',
      code: 'TV',
    },
    {
      name: 'Türkiye',
      code: 'TR',
    },
    {
      name: 'Türkmenistan',
      code: 'TM',
    },
    {
      name: 'Uganda',
      code: 'UG',
    },
    {
      name: 'Ukrayna',
      code: 'UA',
    },
    {
      name: 'Umman',
      code: 'OM',
    },
    {
      name: 'Uruguay',
      code: 'UY',
    },
    {
      name: 'Vanuatu Cumhuriyeti',
      code: 'VU',
    },
    {
      name: 'Vatikan',
      code: 'VA',
    },
    {
      name: 'Venezuela',
      code: 'VE',
    },
    {
      name: 'Vietnam',
      code: 'VN',
    },
    {
      name: 'Wallis ve Futuna',
      code: 'WF',
    },
    {
      name: 'Yemen',
      code: 'YE',
    },
    {
      name: 'Yeni Kaledonya',
      code: 'NC',
    },
    {
      name: 'Yeni Zelanda',
      code: 'NZ',
    },
    {
      name: 'Yeşilburun',
      code: 'CV',
    },
    {
      name: 'Yunanistan',
      code: 'GR',
    },
    {
      name: 'Zambiya',
      code: 'ZM',
    },
    {
      name: 'Zimbabve',
      code: 'ZW',
    },
    {
      name: 'Çad',
      code: 'TD',
    },
    {
      name: 'Çekya',
      code: 'CZ',
    },
    {
      name: 'Çin Cumhuriyeti',
      code: 'TW',
    },
    {
      name: 'Çin Halk Cumhuriyeti',
      code: 'CN',
    },
    {
      name: 'Özbekistan',
      code: 'UZ',
    },
    {
      name: 'Ürdün',
      code: 'JO',
    },
    {
      name: 'İngiliz Hint Okyanusu Bölgesi',
      code: 'IO',
    },
    {
      name: 'İngiliz Virgin Adaları',
      code: 'VG',
    },
    {
      name: 'İngiltere',
      code: 'GB',
    },
    {
      name: 'İran',
      code: 'IR',
    },
    {
      name: 'İrlanda',
      code: 'IE',
    },
    {
      name: 'İspanya',
      code: 'ES',
    },
    {
      name: 'İsrail',
      code: 'IL',
    },
    {
      name: 'İsveç',
      code: 'SE',
    },
    {
      name: 'İsviçre',
      code: 'CH',
    },
    {
      name: 'İtalya',
      code: 'IT',
    },
    {
      name: 'İzlanda',
      code: 'IS',
    },
    {
      name: 'Şili',
      code: 'CL',
    },
  ],
  'zh-cn': [
    {
      name: '不丹',
      code: 'BT',
    },
    {
      name: '东帝汶',
      code: 'TL',
    },
    {
      name: '中国',
      code: 'CN',
    },
    {
      name: '中非共和國',
      code: 'CF',
    },
    {
      name: '丹麦',
      code: 'DK',
    },
    {
      name: '乌克兰',
      code: 'UA',
    },
    {
      name: '乌兹别克斯坦',
      code: 'UZ',
    },
    {
      name: '乌干达',
      code: 'UG',
    },
    {
      name: '乌拉圭',
      code: 'UY',
    },
    {
      name: '乍得',
      code: 'TD',
    },
    {
      name: '也门',
      code: 'YE',
    },
    {
      name: '亞美尼亞',
      code: 'AM',
    },
    {
      name: '以色列',
      code: 'IL',
    },
    {
      name: '伊拉克',
      code: 'IQ',
    },
    {
      name: '伊朗',
      code: 'IR',
    },
    {
      name: '伯利兹',
      code: 'BZ',
    },
    {
      name: '佛得角',
      code: 'CV',
    },
    {
      name: '俄罗斯',
      code: 'RU',
    },
    {
      name: '保加利亚',
      code: 'BG',
    },
    {
      name: '克罗地亚',
      code: 'HR',
    },
    {
      name: '关岛',
      code: 'GU',
    },
    {
      name: '冈比亚共和国',
      code: 'GM',
    },
    {
      name: '冰岛',
      code: 'IS',
    },
    {
      name: '几内亚',
      code: 'GN',
    },
    {
      name: '列支敦斯登',
      code: 'LI',
    },
    {
      name: '刚果共和国',
      code: 'CG',
    },
    {
      name: '利比亚',
      code: 'LY',
    },
    {
      name: '利比里亚',
      code: 'LR',
    },
    {
      name: '加拿大',
      code: 'CA',
    },
    {
      name: '加纳',
      code: 'GH',
    },
    {
      name: '加蓬',
      code: 'GA',
    },
    {
      name: '匈牙利',
      code: 'HU',
    },
    {
      name: '北马里亚纳群岛',
      code: 'MP',
    },
    {
      name: '南乔治亚岛和南桑威奇群岛',
      code: 'GS',
    },
    {
      name: '南极洲',
      code: 'AQ',
    },
    {
      name: '南苏丹',
      code: 'SS',
    },
    {
      name: '南非',
      code: 'ZA',
    },
    {
      name: '博奈尔岛、圣尤斯达蒂斯和萨巴',
      code: 'BQ',
    },
    {
      name: '卡塔尔',
      code: 'QA',
    },
    {
      name: '卢旺达',
      code: 'RW',
    },
    {
      name: '卢森堡',
      code: 'LU',
    },
    {
      name: '印度',
      code: 'IN',
    },
    {
      name: '印度尼西亚',
      code: 'ID',
    },
    {
      name: '危地马拉',
      code: 'GT',
    },
    {
      name: '厄瓜多尔',
      code: 'EC',
    },
    {
      name: '厄立特里亚',
      code: 'ER',
    },
    {
      name: '叙利亚',
      code: 'SY',
    },
    {
      name: '古巴',
      code: 'CU',
    },
    {
      name: '台灣',
      code: 'TW',
    },
    {
      name: '吉尔吉斯斯坦',
      code: 'KG',
    },
    {
      name: '吉布提',
      code: 'DJ',
    },
    {
      name: '哈萨克斯坦',
      code: 'KZ',
    },
    {
      name: '哥伦比亚共和国',
      code: 'CO',
    },
    {
      name: '哥斯达黎加',
      code: 'CR',
    },
    {
      name: '喀麦隆',
      code: 'CM',
    },
    {
      name: '圖瓦盧',
      code: 'TV',
    },
    {
      name: '土库曼斯坦',
      code: 'TM',
    },
    {
      name: '土耳其',
      code: 'TR',
    },
    {
      name: '圣卢西亚',
      code: 'LC',
    },
    {
      name: '圣多美普林西比',
      code: 'ST',
    },
    {
      name: '圣巴泰勒米',
      code: 'BL',
    },
    {
      name: '圣文森特和格林纳丁斯',
      code: 'VC',
    },
    {
      name: '圣皮埃尔和密克隆',
      code: 'PM',
    },
    {
      name: '圣诞岛',
      code: 'CX',
    },
    {
      name: '圣赫勒拿',
      code: 'SH',
    },
    {
      name: '圣马丁',
      code: 'MF',
    },
    {
      name: '圣马丁岛',
      code: 'SX',
    },
    {
      name: '圣马力诺',
      code: 'SM',
    },
    {
      name: '圭亚那',
      code: 'GY',
    },
    {
      name: '坦桑尼亚',
      code: 'TZ',
    },
    {
      name: '埃及',
      code: 'EG',
    },
    {
      name: '埃塞俄比亚',
      code: 'ET',
    },
    {
      name: '基里巴斯',
      code: 'KI',
    },
    {
      name: '塔吉克斯坦',
      code: 'TJ',
    },
    {
      name: '塞内加尔',
      code: 'SN',
    },
    {
      name: '塞尔维亚',
      code: 'RS',
    },
    {
      name: '塞拉利昂',
      code: 'SL',
    },
    {
      name: '塞舌尔',
      code: 'SC',
    },
    {
      name: '墨西哥',
      code: 'MX',
    },
    {
      name: '多哥',
      code: 'TG',
    },
    {
      name: '多明尼加',
      code: 'DO',
    },
    {
      name: '多米尼克',
      code: 'DM',
    },
    {
      name: '奥兰群岛',
      code: 'AX',
    },
    {
      name: '奥地利',
      code: 'AT',
    },
    {
      name: '委内瑞拉',
      code: 'VE',
    },
    {
      name: '孟加拉国',
      code: 'BD',
    },
    {
      name: '安哥拉',
      code: 'AO',
    },
    {
      name: '安圭拉',
      code: 'AI',
    },
    {
      name: '安提瓜和巴布达',
      code: 'AG',
    },
    {
      name: '安道爾',
      code: 'AD',
    },
    {
      name: '密克罗尼西亚联邦',
      code: 'FM',
    },
    {
      name: '尼加拉瓜',
      code: 'NI',
    },
    {
      name: '尼日利亚',
      code: 'NG',
    },
    {
      name: '尼日尔',
      code: 'NE',
    },
    {
      name: '尼泊尔',
      code: 'NP',
    },
    {
      name: '巴勒斯坦',
      code: 'PS',
    },
    {
      name: '巴哈马',
      code: 'BS',
    },
    {
      name: '巴基斯坦',
      code: 'PK',
    },
    {
      name: '巴巴多斯',
      code: 'BB',
    },
    {
      name: '巴布亚新几内亚',
      code: 'PG',
    },
    {
      name: '巴拉圭',
      code: 'PY',
    },
    {
      name: '巴拿马',
      code: 'PA',
    },
    {
      name: '巴林',
      code: 'BH',
    },
    {
      name: '巴西',
      code: 'BR',
    },
    {
      name: '布基纳法索',
      code: 'BF',
    },
    {
      name: '布韦岛',
      code: 'BV',
    },
    {
      name: '希腊',
      code: 'GR',
    },
    {
      name: '帕劳',
      code: 'PW',
    },
    {
      name: '幾內亞比索',
      code: 'GW',
    },
    {
      name: '库克群岛',
      code: 'CK',
    },
    {
      name: '库拉索',
      code: 'CW',
    },
    {
      name: '开曼群岛',
      code: 'KY',
    },
    {
      name: '德意志联邦共和国',
      code: 'DE',
    },
    {
      name: '意大利',
      code: 'IT',
    },
    {
      name: '所罗门群岛',
      code: 'SB',
    },
    {
      name: '扎伊尔',
      code: 'CD',
    },
    {
      name: '托克劳',
      code: 'TK',
    },
    {
      name: '拉脱维亚',
      code: 'LV',
    },
    {
      name: '挪威',
      code: 'NO',
    },
    {
      name: '捷克',
      code: 'CZ',
    },
    {
      name: '摩尔多瓦',
      code: 'MD',
    },
    {
      name: '摩洛哥',
      code: 'MA',
    },
    {
      name: '摩纳哥',
      code: 'MC',
    },
    {
      name: '教廷',
      code: 'VA',
    },
    {
      name: '文莱',
      code: 'BN',
    },
    {
      name: '斐濟',
      code: 'FJ',
    },
    {
      name: '斯威士兰',
      code: 'SZ',
    },
    {
      name: '斯洛伐克',
      code: 'SK',
    },
    {
      name: '斯洛文尼亚',
      code: 'SI',
    },
    {
      name: '斯瓦尔巴和扬马延',
      code: 'SJ',
    },
    {
      name: '斯里兰卡',
      code: 'LK',
    },
    {
      name: '新加坡',
      code: 'SG',
    },
    {
      name: '新喀里多尼亚',
      code: 'NC',
    },
    {
      name: '新西兰',
      code: 'NZ',
    },
    {
      name: '日本',
      code: 'JP',
    },
    {
      name: '智利',
      code: 'CL',
    },
    {
      name: '曼島',
      code: 'IM',
    },
    {
      name: '朝鲜民主主义人民共和国',
      code: 'KP',
    },
    {
      name: '柬埔寨',
      code: 'KH',
    },
    {
      name: '格恩西岛',
      code: 'GG',
    },
    {
      name: '格林纳达',
      code: 'GD',
    },
    {
      name: '格陵兰',
      code: 'GL',
    },
    {
      name: '格鲁吉亚',
      code: 'GE',
    },
    {
      name: '比利时',
      code: 'BE',
    },
    {
      name: '毛里塔尼亚',
      code: 'MR',
    },
    {
      name: '毛里求斯',
      code: 'MU',
    },
    {
      name: '汤加',
      code: 'TO',
    },
    {
      name: '沙特阿拉伯',
      code: 'SA',
    },
    {
      name: '法国',
      code: 'FR',
    },
    {
      name: '法属南部和南极领地',
      code: 'TF',
    },
    {
      name: '法属圭亚那',
      code: 'GF',
    },
    {
      name: '法属波利尼西亚',
      code: 'PF',
    },
    {
      name: '法罗群岛',
      code: 'FO',
    },
    {
      name: '波兰',
      code: 'PL',
    },
    {
      name: '波多黎各',
      code: 'PR',
    },
    {
      name: '波斯尼亚和黑塞哥维那',
      code: 'BA',
    },
    {
      name: '波札那',
      code: 'BW',
    },
    {
      name: '泰国',
      code: 'TH',
    },
    {
      name: '泽西岛',
      code: 'JE',
    },
    {
      name: '津巴布韦',
      code: 'ZW',
    },
    {
      name: '洪都拉斯',
      code: 'HN',
    },
    {
      name: '海地',
      code: 'HT',
    },
    {
      name: '澳大利亞',
      code: 'AU',
    },
    {
      name: '澳门',
      code: 'MO',
    },
    {
      name: '爱尔兰共和国',
      code: 'IE',
    },
    {
      name: '爱沙尼亚',
      code: 'EE',
    },
    {
      name: '牙买加',
      code: 'JM',
    },
    {
      name: '特克斯和凯科斯群岛',
      code: 'TC',
    },
    {
      name: '特立尼达和多巴哥',
      code: 'TT',
    },
    {
      name: '玻利维亚',
      code: 'BO',
    },
    {
      name: '瑞典',
      code: 'SE',
    },
    {
      name: '瑞士',
      code: 'CH',
    },
    {
      name: '瓜德罗普岛',
      code: 'GP',
    },
    {
      name: '瓦利斯和富图纳',
      code: 'WF',
    },
    {
      name: '瓦努阿图',
      code: 'VU',
    },
    {
      name: '留尼汪',
      code: 'RE',
    },
    {
      name: '白俄罗斯',
      code: 'BY',
    },
    {
      name: '百慕大',
      code: 'BM',
    },
    {
      name: '皮特凯恩群岛',
      code: 'PN',
    },
    {
      name: '直布羅陀',
      code: 'GI',
    },
    {
      name: '福克兰群岛',
      code: 'FK',
    },
    {
      name: '科威特',
      code: 'KW',
    },
    {
      name: '科科斯[基林]群岛',
      code: 'CC',
    },
    {
      name: '科索沃',
      code: 'XK',
    },
    {
      name: '秘鲁',
      code: 'PE',
    },
    {
      name: '突尼西亞',
      code: 'TN',
    },
    {
      name: '立陶宛',
      code: 'LT',
    },
    {
      name: '索马里',
      code: 'SO',
    },
    {
      name: '约旦',
      code: 'JO',
    },
    {
      name: '纳米比亚',
      code: 'NA',
    },
    {
      name: '纽埃',
      code: 'NU',
    },
    {
      name: '缅甸',
      code: 'MM',
    },
    {
      name: '羅馬尼亞',
      code: 'RO',
    },
    {
      name: '美国',
      code: 'US',
    },
    {
      name: '美国外围小岛屿',
      code: 'UM',
    },
    {
      name: '美属维尔京群岛',
      code: 'VI',
    },
    {
      name: '美属萨摩亚',
      code: 'AS',
    },
    {
      name: '老挝',
      code: 'LA',
    },
    {
      name: '聖克里斯多福與尼維斯',
      code: 'KN',
    },
    {
      name: '肯尼亚',
      code: 'KE',
    },
    {
      name: '芬兰',
      code: 'FI',
    },
    {
      name: '苏丹共和国',
      code: 'SD',
    },
    {
      name: '英国',
      code: 'GB',
    },
    {
      name: '英属印度洋领地',
      code: 'IO',
    },
    {
      name: '英屬維爾京群島',
      code: 'VG',
    },
    {
      name: '荷兰',
      code: 'NL',
    },
    {
      name: '莫桑比克',
      code: 'MZ',
    },
    {
      name: '莱索托',
      code: 'LS',
    },
    {
      name: '菲律宾',
      code: 'PH',
    },
    {
      name: '萨尔瓦多',
      code: 'SV',
    },
    {
      name: '萨摩亚',
      code: 'WS',
    },
    {
      name: '葛摩',
      code: 'KM',
    },
    {
      name: '葡萄牙',
      code: 'PT',
    },
    {
      name: '蒙古国',
      code: 'MN',
    },
    {
      name: '蒙特內哥羅',
      code: 'ME',
    },
    {
      name: '蒙特塞拉特',
      code: 'MS',
    },
    {
      name: '蒲隆地',
      code: 'BI',
    },
    {
      name: '蘇利南',
      code: 'SR',
    },
    {
      name: '西撒哈拉',
      code: 'EH',
    },
    {
      name: '西班牙',
      code: 'ES',
    },
    {
      name: '諾魯',
      code: 'NR',
    },
    {
      name: '诺福克岛',
      code: 'NF',
    },
    {
      name: '象牙海岸',
      code: 'CI',
    },
    {
      name: '賽普勒斯',
      code: 'CY',
    },
    {
      name: '贝宁',
      code: 'BJ',
    },
    {
      name: '赞比亚',
      code: 'ZM',
    },
    {
      name: '赤道几内亚',
      code: 'GQ',
    },
    {
      name: '赫德岛和麦克唐纳群岛',
      code: 'HM',
    },
    {
      name: '越南',
      code: 'VN',
    },
    {
      name: '阿塞拜疆',
      code: 'AZ',
    },
    {
      name: '阿富汗',
      code: 'AF',
    },
    {
      name: '阿尔及利亚',
      code: 'DZ',
    },
    {
      name: '阿尔巴尼亚',
      code: 'AL',
    },
    {
      name: '阿曼',
      code: 'OM',
    },
    {
      name: '阿根廷',
      code: 'AR',
    },
    {
      name: '阿联酋',
      code: 'AE',
    },
    {
      name: '阿鲁巴',
      code: 'AW',
    },
    {
      name: '韩国',
      code: 'KR',
    },
    {
      name: '香港',
      code: 'HK',
    },
    {
      name: '馬其頓共和國',
      code: 'MK',
    },
    {
      name: '马尔代夫',
      code: 'MV',
    },
    {
      name: '马拉维',
      code: 'MW',
    },
    {
      name: '马提尼克',
      code: 'MQ',
    },
    {
      name: '马来西亚',
      code: 'MY',
    },
    {
      name: '马约特',
      code: 'YT',
    },
    {
      name: '马绍尔群岛',
      code: 'MH',
    },
    {
      name: '马耳他',
      code: 'MT',
    },
    {
      name: '马达加斯加',
      code: 'MG',
    },
    {
      name: '马里共和国',
      code: 'ML',
    },
    {
      name: '黎巴嫩',
      code: 'LB',
    },
  ],
})
