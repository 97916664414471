<template>
  <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="computedDate"
        :label="question.label"
        :error-messages="valueErrors"
        color="input-color"
        background-color="input-background"
        readonly
        filled
        flat
        class="tw-mt-2"
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      ref="picker"
      :value="value"
      :max="maxDate"
      min="1900-01-01"
      no-title
      @input="onInput"
      color="input-color"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { formatDate } from '@/utils/date.util'
import { validationMixin } from 'vuelidate'
import { requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'InputDatePicker',
  props: {
    question: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
    validating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mixins: [validationMixin],
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    maxDate() {
      return new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 10)
    },
    computedDate() {
      return this.value ? formatDate(this.value, 'L', this.$i18n.locale) : ''
    },
    valueErrors() {
      const errors = []
      if (!this.validating) return errors
      !this.$v.value.required && errors.push(this.$t('error.required'))
      return errors
    },
  },
  methods: {
    onInput(e) {
      this.menu = false
      this.$emit('change', { value: e, id: this.question.id })
    },
  },
  validations() {
    return {
      value: {
        required: requiredIf(() => {
          return this.question.required
        }),
      },
    }
  },
}
</script>
