<template>
  <v-checkbox
    :input-value="value"
    color="input-color"
    :dark="isDark"
    :error-messages="valueErrors"
    class="tw-pt-0"
    @change="onChange"
  >
    <template v-slot:label>
      <div v-html="field.label"></div>
    </template>
  </v-checkbox>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { sameAs } from 'vuelidate/lib/validators'

export default {
  name: 'IdentityRules',
  props: {
    field: {
      type: Object,
      required: true,
    },
    isDark: {
      type: Boolean,
      required: true,
    },
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    validating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mixins: [validationMixin],
  mounted() {
    document.querySelectorAll('a').forEach(link => {
      link.addEventListener('click', e => {
        e.stopPropagation()
      })
    })
  },
  computed: {
    valueErrors() {
      const errors = []
      if (!this.validating) return errors
      !this.$v.value.sameAs && errors.push(this.$t('error.required'))
      return errors
    },
  },
  methods: {
    onChange(e) {
      this.$emit('change', e)
    },
  },
  validations() {
    return {
      value: {
        sameAs: sameAs(() => {
          return this.field.required
        }),
      },
    }
  },
}
</script>
