<template>
  <v-textarea
    :value="textarea"
    color="input-color"
    background-color="input-background"
    filled
    flat
    auto-grow
    no-resize
    :error-messages="valueErrors"
    :label="question.label"
    :hint="question.min ? $t('label.min', { count: question.min }) : null"
    :persistent-hint="question.min ? true : false"
    :counter="question.limitation || question.min ? true : false"
    :minlength="question.min ? question.min : null"
    :maxlength="question.limitation ? question.limitation : null"
    @input="onInput"
  ></v-textarea>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { requiredIf, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'InputTextarea',
  props: {
    question: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
    validating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mixins: [validationMixin],
  created() {
    this.textarea = this.value
  },
  computed: {
    valueErrors() {
      const errors = []
      if (!this.validating) return errors
      !this.$v.value.minLength &&
        errors.push(
          this.$t('error.minLength', {
            count: this.$v.value.$params.minLength.min,
          })
        )
      !this.$v.value.required && errors.push(this.$t('error.required'))
      return errors
    },
  },
  data: () => ({
    textarea: '',
  }),
  methods: {
    onInput(e) {
      this.textarea = e
      this.$emit('change', { value: this.textarea, id: this.question.id })
    },
  },
  validations() {
    return {
      value: {
        required: requiredIf(() => {
          return this.question.required
        }),
        minLength: minLength(this.question.min ? this.question.min : 0),
      },
    }
  },
}
</script>
