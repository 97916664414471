<template>
  <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="computedDate"
        :label="field.label"
        readonly
        color="input-color"
        background-color="input-background"
        filled
        flat
        :error-messages="valueErrors"
        v-bind="attrs"
        v-on="on"
        class="tw-mt-2"
      ></v-text-field>
    </template>
    <v-date-picker :value="value" no-title @input="onInput($event, field.key)" color="input-color"></v-date-picker>
  </v-menu>
</template>

<script>
import { formatDate } from '@/utils/date.util'
import { validationMixin } from 'vuelidate'
import { requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'IdentityBirthday',
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
    validating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mixins: [validationMixin],
  data: () => ({
    menu: false,
  }),
  computed: {
    computedDate() {
      return this.value ? formatDate(this.value, 'L', this.$i18n.locale) : ''
    },
    valueErrors() {
      const errors = []
      if (!this.validating) return errors
      !this.$v.value.required && errors.push(this.$t('error.required'))
      return errors
    },
  },
  methods: {
    onInput(e) {
      this.menu = false
      this.$emit('change', e)
    },
  },
  validations() {
    return {
      value: {
        required: requiredIf(() => {
          return this.field.required
        }),
      },
    }
  },
}
</script>
