export const states = Object.freeze({
  US: [
    {
      name: 'Alabama',
      code: 'AL',
    },
    {
      name: 'Alaska',
      code: 'AK',
    },
    {
      name: 'Arizona',
      code: 'AZ',
    },
    {
      name: 'Arkansas',
      code: 'AR',
    },
    {
      name: 'California',
      code: 'CA',
    },
    {
      name: 'Colorado',
      code: 'CO',
    },
    {
      name: 'Connecticut',
      code: 'CT',
    },
    {
      name: 'Delaware',
      code: 'DE',
    },
    {
      name: 'District of Columbia',
      code: 'DC',
    },
    {
      name: 'Florida',
      code: 'FL',
    },
    {
      name: 'Georgia',
      code: 'GA',
    },
    {
      name: 'Hawaii',
      code: 'HI',
    },
    {
      name: 'Idaho',
      code: 'ID',
    },
    {
      name: 'Illinois',
      code: 'IL',
    },
    {
      name: 'Indiana',
      code: 'IN',
    },
    {
      name: 'Iowa',
      code: 'IA',
    },
    {
      name: 'Kansas',
      code: 'KS',
    },
    {
      name: 'Kentucky',
      code: 'KY',
    },
    {
      name: 'Louisiana',
      code: 'LA',
    },
    {
      name: 'Maine',
      code: 'ME',
    },
    {
      name: 'Montana',
      code: 'MT',
    },
    {
      name: 'Nebraska',
      code: 'NE',
    },
    {
      name: 'Nevada',
      code: 'NV',
    },
    {
      name: 'New Hampshire',
      code: 'NH',
    },
    {
      name: 'New Jersey',
      code: 'NJ',
    },
    {
      name: 'New Mexico',
      code: 'NM',
    },
    {
      name: 'New York',
      code: 'NY',
    },
    {
      name: 'North Carolina',
      code: 'NC',
    },
    {
      name: 'North Dakota',
      code: 'ND',
    },
    {
      name: 'Ohio',
      code: 'OH',
    },
    {
      name: 'Oklahoma',
      code: 'OK',
    },
    {
      name: 'Oregon',
      code: 'OR',
    },
    {
      name: 'Maryland',
      code: 'MD',
    },
    {
      name: 'Massachusetts',
      code: 'MA',
    },
    {
      name: 'Michigan',
      code: 'MI',
    },
    {
      name: 'Minnesota',
      code: 'MN',
    },
    {
      name: 'Mississippi',
      code: 'MS',
    },
    {
      name: 'Missouri',
      code: 'MO',
    },
    {
      name: 'Pennsylvania',
      code: 'PA',
    },
    {
      name: 'Rhode Island',
      code: 'RI',
    },
    {
      name: 'South Carolina',
      code: 'SC',
    },
    {
      name: 'South Dakota',
      code: 'SD',
    },
    {
      name: 'Tennessee',
      code: 'TN',
    },
    {
      name: 'Texas',
      code: 'TX',
    },
    {
      name: 'Utah',
      code: 'UT',
    },
    {
      name: 'Vermont',
      code: 'VT',
    },
    {
      name: 'Virginia',
      code: 'VA',
    },
    {
      name: 'Washington',
      code: 'WA',
    },
    {
      name: 'West Virginia',
      code: 'WV',
    },
    {
      name: 'Wisconsin',
      code: 'WI',
    },
    {
      name: 'Wyoming',
      code: 'WY',
    },
  ],
  CA: [
    {
      name: 'Alberta',
      code: '01',
    },
    {
      name: 'British Columbia',
      code: '02',
    },
    {
      name: 'Manitoba',
      code: '03',
    },
    {
      name: 'New Brunswick',
      code: '04',
    },
    {
      name: 'Newfoundland and Labrador',
      code: '05',
    },
    {
      name: 'Northwest Territories',
      code: '13',
    },
    {
      name: 'Nova Scotia',
      code: '07',
    },
    {
      name: 'Nunavut',
      code: '14',
    },
    {
      name: 'Ontario',
      code: '08',
    },
    {
      name: 'Prince Edward Island',
      code: '09',
    },
    {
      name: 'Quebec',
      code: '10',
    },
    {
      name: 'Saskatchewan',
      code: '11',
    },
    {
      name: 'Yukon',
      code: '12',
    },
  ],
})
