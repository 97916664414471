<template>
  <div>
    <v-checkbox
      :input-value="value"
      :error-messages="valueErrors"
      :hide-details="idx !== question.answers.length - 1"
      multiple
      color="input-color"
      :label="answer.label"
      :value="answer.value"
      :dark="isDark"
      v-for="(answer, idx) in question.answers"
      class="tw-pt-0 tw-mt-2"
      :key="`answer-${idx}`"
      @change="onChange"
    ></v-checkbox>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'InputCheckbox',
  props: {
    question: {
      type: Object,
      required: true,
    },
    isDark: {
      type: Boolean,
      required: true,
    },
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
    validating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mixins: [validationMixin],
  computed: {
    valueErrors() {
      const errors = []
      if (!this.validating) return errors
      !this.$v.value.required && errors.push(this.$t('error.required'))
      return errors
    },
  },
  methods: {
    onChange(e) {
      this.$emit('change', { value: e, id: this.question.id })
    },
  },
  validations() {
    return {
      value: {
        required: requiredIf(() => {
          return this.question.required
        }),
      },
    }
  },
}
</script>
