<template>
  <v-text-field
    :value="email.email"
    :label="field.label"
    type="email"
    color="input-color"
    background-color="input-background"
    filled
    flat
    :error-messages="valueErrors"
    @input="onInput"
    @blur="onBlur"
  >
    <template v-slot:append>
      <v-progress-circular
        v-if="verifying"
        size="24"
        width="3"
        color="grey darken-1"
        indeterminate
      ></v-progress-circular>
      <v-icon
        v-else-if="!verifying && (email.validity === 'valid' || email.validity === 'unknown')"
        color="light-green darken-3"
        @click.stop
      >
        mdi-check-circle-outline
      </v-icon>
      <v-tooltip bottom v-else-if="!verifying && email.validity === 'invalid'">
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="red darken-4" v-bind="attrs" v-on="on"> mdi-alert-circle-outline </v-icon>
        </template>
        <span>{{ $t('label.wrongEmail') }}</span>
      </v-tooltip>
    </template>
  </v-text-field>
</template>

<script>
import { checkApiEmail } from '@/services/global.service'
import { validationMixin } from 'vuelidate'
import { requiredIf, email } from 'vuelidate/lib/validators'

export default {
  name: 'IdentityEmail',
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: false,
      default: null,
    },
    validating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mixins: [validationMixin],
  data: () => ({
    oldValue: '',
    email: null,
    verifying: false,
  }),
  created() {
    this.email = Object.assign({}, this.email, this.value)
  },
  computed: {
    valueErrors() {
      const errors = []
      if (!this.validating) return errors
      !this.$v.email.email.required && errors.push(this.$t('error.required'))
      !this.$v.email.email.email && errors.push(this.$t('error.email'))
      this.email.validity === 'invalid' && errors.push(this.$t('error.email'))
      return errors
    },
  },
  methods: {
    onInput(e) {
      this.oldValue = ''
      this.email = {
        ...this.email,
        email: e,
        validity: '',
        mx: null,
      }
      this.$emit('reset')
    },
    async onBlur(e) {
      if (this.oldValue !== e.target.value) {
        this.verifying = true
        let data = null
        try {
          data = await checkApiEmail(e.target.value)
        } catch {
          data = {}
        }
        const emptyObject = Object.keys(data).length === 0
        if (emptyObject || data.verify) {
          this.email = {
            ...this.email,
            validity: emptyObject ? 'unknown' : data.verify.result,
            mx: data.gmx,
          }
        }
        this.oldValue = `${e.target.value}`
        this.verifying = false
      }
      if (this.email.validity === 'valid' || this.email.validity === 'unknown') {
        this.$emit('change', { ...this.email, email: e.target.value })
      }
    },
  },
  validations() {
    return {
      email: {
        email: {
          required: requiredIf(() => {
            return this.field.required
          }),
          email,
        },
      },
    }
  },
}
</script>
